import { type ReactNode, type ReactElement } from 'react'

interface TableActionCellProps {
  children?: ReactNode
}

export default function TableActionCell (props: TableActionCellProps): ReactElement {
  const { children } = props
  return (
    <td className="relative py-4 pl-3 pr-4 text-sm text-right whitespace-nowrap sm:pr-0">
      {children}
    </td>
  )
}
