import type { ISeasonAthleteWithAthlete, ISeasonAthleteWithAthleteInput } from '../../../api/api'
import { apiSlice } from './apiSlice'

export const adminSeasonAthletesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAdminSeasonAthletes: builder.query<ISeasonAthleteWithAthlete[], { seasonId: string, tenantId: string }>({
      query: (id: { seasonId: string, tenantId: string }) => `/admin/seasons/${id.seasonId}/athletes?tenantId=${id.tenantId}`,

      providesTags: (result = [], _error, _arg) =>
        result != null
          ? [...result.map(({ id }) => ({ type: 'AdminSeasonAthlete' as const, id })), 'AdminSeasonAthlete']
          : ['AdminSeasonAthlete']
    }),
    getAdminSeasonAthlete: builder.query<ISeasonAthleteWithAthlete | undefined, { seasonId: string, athleteId: string, tenantId: string }>({
      query: (id: { seasonId: string, athleteId: string, tenantId: string }) => `/admin/seasons/${id.seasonId}/athletes/${id.athleteId}?tenantId=${id.tenantId}`,
      providesTags: (result, _error, _arg) => [{ type: 'AdminSeasonAthlete', id: result?.id }]
    }),
    addAdminSeasonAthlete: builder.mutation<ISeasonAthleteWithAthlete, ISeasonAthleteWithAthleteInput & { seasonId: string, tenantId: string }>({
      query: (newAthlete: ISeasonAthleteWithAthleteInput & { seasonId: string, tenantId: string }) => ({
        url: `/admin/seasons/${newAthlete.seasonId}/athletes`,
        method: 'POST',
        body: newAthlete
      }),
      invalidatesTags: (_result, _error, _arg) => ['AdminSeasonAthlete']
    }),
    editAdminSeasonAthlete: builder.mutation({
      query: (athlete: Partial<ISeasonAthleteWithAthlete> & { id: string, seasonId: string, tenantId: string }) => ({
        url: `/admin/seasons/${athlete.seasonId}/athletes/${athlete.id}`,
        method: 'PUT',
        body: athlete
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'AdminSeasonAthlete', id: arg.id }]
    })
  })
})

export const {
  useGetAdminSeasonAthletesQuery,
  useGetAdminSeasonAthleteQuery,
  useAddAdminSeasonAthleteMutation,
  useEditAdminSeasonAthleteMutation
} = adminSeasonAthletesApiSlice
