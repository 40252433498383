import { useState, type ReactElement, useEffect, useContext, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { GlobalDialogContext, Breadcrumbs, Button, CheckBox, Form, FormButtons, ImageFileDropInput, LoadingComponent, Mask, TextBox, ComboBox } from '@andyneville/tailwind-react'
import { HomeIcon, MinusIcon, PlusIcon, Square3Stack3DIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useGetAdminAccountQuery, useEditAdminAccountMutation, useGetAdminAccountTenantsQuery, useEditAdminAccountTenantMutation, useAddAdminAccountTenantMutation, useDeleteAdminAccountTenantMutation, useGetAdminAccountLoginsQuery, useGenerateAdminAccountCodeMutation } from '../../reducers/apiSlice-admin-accounts'
import { TenantStatus, type IAccount, type IAccountTenant, TenantRole, type ITenantWithAccountTenant, AccountRole } from '../../../../api/api'
import TableHeader from '../../components/TableHeader'
import SortableTableHeader from '../../components/SortableTableHeader'
import TableCell from '../../components/TableCell'
import TableActionCell from '../../components/TableActionCell'
import { DateTime } from 'luxon'
import AddTenantDialog from '../../components/AddTenantDialog'
import { rootLog } from '../../logging'
import { useSelector } from 'react-redux'
import { type RootState } from '../../store'
import { useGetAdminTenantsQuery } from '../../reducers/apiSlice-admin-tenants'
import { type ComboOption } from '@andyneville/tailwind-react/ComboBox'

const log = rootLog.child({ module: 'UserDetail' })

export default function AdminUserDetail (): ReactElement {
  const { userId } = useParams()
  const { roles: myRoles } = useSelector((state: RootState) => state.auth)
  const {
    data: sourceAccount,
    isLoading: loadingAccount
    // isSuccess,
    // isError,
    // error
  } = useGetAdminAccountQuery(userId ?? '', { skip: userId == null || userId.length === 0 })
  const {
    data: tenants = [],
    isLoading: loadingTenants
    // isSuccess,
    // isError,
    // error
  } = useGetAdminAccountTenantsQuery(userId ?? '', { skip: userId == null || userId.length === 0 })
  const {
    data: logins = [],
    isLoading: loadingLogins
    // isSuccess,
    // isError,
    // error
  } = useGetAdminAccountLoginsQuery(userId ?? '', { skip: userId == null || userId.length === 0 })
  const {
    data: allTenants = []
    // isLoading: loadingAllAccounts,
    // isSuccess,
    // isError,
    // error
  } = useGetAdminTenantsQuery()
  const [updateAccount, { isLoading: updateLoading }] = useEditAdminAccountMutation()
  const [updateAccountTenant, { isLoading: updateLoadingTA }] = useEditAdminAccountTenantMutation()
  const [addAccountTenant, { isLoading: addLoadingTA }] = useAddAdminAccountTenantMutation()
  const [deleteAccountTenant, { isLoading: deleteLoadingTA }] = useDeleteAdminAccountTenantMutation()
  const [generateAdminAccountCode] = useGenerateAdminAccountCodeMutation()
  const [account, setAccount] = useState<IAccount>({} as unknown as IAccount)
  const [updates, setUpdates] = useState<IAccount>({} as unknown as IAccount)
  const [isDirty, setIsDirty] = useState(false)
  const [newTenant, setNewTenant] = useState<ComboOption | null | undefined>(null)
  const globalDialog = useContext(GlobalDialogContext)
  const [showAddTenant, setShowAddTenant] = useState(false)

  const tenantOptions = useMemo(() => {
    const availableTenants = allTenants.filter((tenant) => !tenants.some((t) => t.id === tenant.id))
    return availableTenants.map((tenant) => { return { id: tenant.id, name: tenant.name } })
  }, [allTenants, tenants])

  useEffect(() => {
    if (sourceAccount != null) {
      setAccount(sourceAccount)
      setIsDirty(false)
    }
  }, [sourceAccount])

  const setValue = (value: Partial<IAccount>): void => {
    setAccount(Object.assign({}, account, value))
    setUpdates(Object.assign({}, updates, value))
    setIsDirty(true)
  }

  const setRole = (value: boolean, role: AccountRole): void => {
    const roles = account.roles ?? []
    if (roles.includes(role) && !value) {
      setValue({ roles: roles.filter((r) => r !== role) })
    } else {
      setValue({ roles: [...roles, role] })
    }
  }

  const setAccountTenantRole = (accountTenant: IAccountTenant, roleName: TenantRole, value: boolean): void => {
    const roles = accountTenant.roles ?? []
    if (roles.includes(roleName) && !value) {
      void updateAccountTenant({ accountId: account.id, tenantId: accountTenant.tenantId, roles: roles.filter((role) => role !== roleName) })
    } else if (!roles.includes(roleName) && value) {
      void updateAccountTenant({ accountId: account.id, tenantId: accountTenant.tenantId, roles: [...roles, roleName] })
    }
  }

  const doGetCode = (): void => {
    async function getCode (): Promise<void> {
      console.log('get code', account.id)
      const code = await generateAdminAccountCode({ accountId: account.id }).unwrap()
      globalDialog.showInfo('Login Code', `The login code for ${account.name} (${account.email}) is ${code.code} - this code is only good for 24 hours`)
    }
    void getCode()
  }

  const doSave = async (): Promise<void> => {
    try {
      await updateAccount({ ...updates, id: account.id }).unwrap()
      setIsDirty(false)
    } catch (e) {
      log.error('Error updating account', e)
      setIsDirty(true)
    }
  }

  const addOrganization = (): void => {
    if (newTenant?.id != null) {
      void addAccountTenant({ accountId: account.id, tenantId: newTenant.id })
      setNewTenant(null)
    }
  }

  const deleteOrganization = (tenant: ITenantWithAccountTenant): void => {
    globalDialog.showWarning('Remove from Organization', `Are you sure you want to remove ${account.name} from the ${tenant.name} organization?`, 'Remove', () => {
      void deleteAccountTenant({ accountId: account.id, tenantId: tenant.id })
    })
  }

  return (
    <>
      <Breadcrumbs backRoute={'/admin/users'} routes={[{ route: '/', Icon: HomeIcon }, { route: '/admin/users', name: 'Users' }, { name: account?.email ?? 'User Details' }]} />
      <AddTenantDialog accountId={account.id} open={showAddTenant} onClose={() => { setShowAddTenant(false) }} />
      <div className='sm:mt-5'></div>
      <LoadingComponent isLoading={loadingAccount}>
        <Mask show={updateLoading} message='Updating...'>
          <Form>
            <ImageFileDropInput round value={account.picture} className='!ml-0' columns={1} />
            <TextBox id='name' label='Name' columns={2} value={account.name} onChange={(value) => { setValue({ name: value }) }} />
            <TextBox id='first-name' label='First Name' columns={2} value={account.firstName} onChange={(value) => { setValue({ firstName: value }) }} />
            <TextBox id='last-name' label='Last Name' columns={2} value={account.lastName} onChange={(value) => { setValue({ lastName: value }) }} />
            {myRoles.includes(AccountRole.SuperAdministrator) &&
              <TextBox id='email' label='Email' columns={2} value={account.email} />
            }
            {myRoles.includes(AccountRole.SuperAdministrator) &&
              <CheckBox id='active' label='Active' columns={1} value={account.active} onChange={(value) => { setValue({ active: value }) }} />
            }
            {myRoles.includes(AccountRole.SuperAdministrator) &&
              <CheckBox id='admin' label='Admin' columns={1} value={account.roles?.includes(AccountRole.Administrator)} onChange={(value) => { setRole(value, AccountRole.Administrator) }} />
            }
            {myRoles.includes(AccountRole.SuperAdministrator) &&
              <CheckBox id='super' label='Super' columns={1} value={account.roles?.includes(AccountRole.SuperAdministrator)} onChange={(value) => { setRole(value, AccountRole.SuperAdministrator) }} />
            }
            <FormButtons>
              <Button label='Generate Login Code' onClick={() => { doGetCode() }} />
              <Button label='Save' primary disabled={!isDirty} onClick={() => { void doSave() }} />
              <Button label='Create New Org' onClick={() => { setShowAddTenant(true) }} />
            </FormButtons>
          </Form>
        </Mask>
      </LoadingComponent>
      <LoadingComponent isLoading={loadingTenants} >
        <Mask show={updateLoading || addLoadingTA || updateLoadingTA || deleteLoadingTA} message='Updating...'>
          <h4>Organizations</h4>
          <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
            <thead>
              <tr>
                <TableHeader name='Image' srOnly />
                <SortableTableHeader name='Name' sorted={1} />
                <SortableTableHeader name='Roles' />
                <SortableTableHeader name='Status' />
                <SortableTableHeader name='Plan' />
                <SortableTableHeader name='Expiration' />
                <TableHeader name='Edit' srOnly />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {(tenants ?? []).map((tenant) => (
                <tr key={tenant.id} className={tenant.status === TenantStatus.Expired ? 'line-through' : ''}>
                  <TableCell>
                    {tenant.logo == null
                      ? (
                        <Square3Stack3DIcon className="w-6 h-6 rounded-full" aria-hidden="true" />
                        )
                      : (
                        <img className="w-6 h-6 rounded-full" src={tenant.logo} alt="" />
                        )
                    }
                  </TableCell>
                  <TableCell emphasize>
                    <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenant.id}`}>{tenant.name}</Link>
                  </TableCell>
                  <TableCell>{(tenant.accountTenant?.roles ?? []).join(', ')}</TableCell>
                  <TableCell>{tenant.status}</TableCell>
                  <TableCell>{tenant.plan}</TableCell>
                  <TableCell>{tenant.expiration != null ? DateTime.fromJSDate(new Date(tenant.expiration)).toLocaleString(DateTime.DATETIME_SHORT) : ''}</TableCell>
                  <TableActionCell>
                    <div className='flex items-center gap-x-2'>
                      {tenant.accountTenant?.roles?.includes(TenantRole.Owner) === true
                        ? <Button Icon={MinusIcon} small label='Owner' primary onClick={() => { setAccountTenantRole(tenant.accountTenant as unknown as IAccountTenant, TenantRole.Owner, false) }} />
                        : <Button Icon={PlusIcon} small label='Owner' primary onClick={() => { setAccountTenantRole(tenant.accountTenant as unknown as IAccountTenant, TenantRole.Owner, true) }} />
                      }
                      {tenant.accountTenant?.roles?.includes(TenantRole.Manager) === true
                        ? <Button Icon={MinusIcon} small label='Manager' primary onClick={() => { setAccountTenantRole(tenant.accountTenant as unknown as IAccountTenant, TenantRole.Manager, false) }} />
                        : <Button Icon={PlusIcon} small label='Manager' primary onClick={() => { setAccountTenantRole(tenant.accountTenant as unknown as IAccountTenant, TenantRole.Manager, true) }} />
                      }
                      <Button Icon={TrashIcon} transparent onClick={() => { deleteOrganization(tenant) }} />
                    </div>
                  </TableActionCell>
                </tr>
              ))}
            </tbody>
          </table>
          <Form>
            <ComboBox id='name' label='Add Organization' columns={4} options={tenantOptions} value={newTenant} onChange={setNewTenant} />
            <Button className='mt-8' label='Add Org' primary disabled={newTenant?.id == null} onClick={addOrganization} />
          </Form>
        </Mask>
      </LoadingComponent>
      <LoadingComponent isLoading={loadingLogins} >
        <h4 className='mt-8'>Logins</h4>
        <table className="divide-y divide-gray-300 dark:divide-gray-500">
          <thead>
            <tr>
              <SortableTableHeader name='Id' sorted={1} />
              <SortableTableHeader name='Provider' />
              <SortableTableHeader name='Email' />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {(logins ?? []).map((login, idx) => (
              <tr key={idx}>
                <TableCell>{login.id}</TableCell>
                <TableCell>{login.provider}</TableCell>
                <TableCell>{login.email}</TableCell>
              </tr>
            ))}
          </tbody>
        </table>
      </LoadingComponent>
    </>
  )
}
