import { type SetStateAction, type ReactElement, type Dispatch, useState } from 'react'
import RightSlideout from '../layout/RightSlideout'
import Rating from './Rating'

import RatingTotal from './RatingTotal'
import { TryoutType, type ISeasonAthleteWithAthlete } from '../../../api/api'
import { useGetTryoutsQuery } from '../reducers/apiSlice-tryouts'
import { useSelector } from 'react-redux'
import { type RootState } from '../store'
import unknownUser from '../assets/user.png'
import VideoPreview from './VideoPreview'
import PlayVideoDialog from './PlayVideoDialog'

type BoundState = [boolean, Dispatch<SetStateAction<boolean>>]

export interface AthleteSlideoutProps {
  athlete?: ISeasonAthleteWithAthlete
  sidebarOpenBinding: BoundState
}

export default function AthleteSlideout (props: AthleteSlideoutProps): ReactElement {
  const { athlete, sidebarOpenBinding } = props
  const { currentSeasonId } = useSelector((state: RootState) => state.season)
  const {
    data: tryouts
  } = useGetTryoutsQuery({ seasonId: currentSeasonId ?? '', athleteId: athlete?.id ?? '' }, { skip: currentSeasonId == null || athlete == null })
  const [sidebarOpen, setSidebarOpen] = sidebarOpenBinding
  // const [playJumpVideo, setPlayJumpVideo] = useState(false)

  const [showVideoDialog, setShowVideoDialog] = useState(false)
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const [videoTitle, setVideoTitle] = useState('Tryout Video')

  const showVideo = (title?: string, videoUrl?: string, thumbnailUrl?: string): void => {
    setVideoTitle(title ?? 'Tryout Video')
    setVideoUrl(videoUrl ?? '')
    setThumbnailUrl(thumbnailUrl ?? '')
    setShowVideoDialog(true)
  }

  return (
    <>
      <RightSlideout open={sidebarOpen} close={() => { setSidebarOpen(false) }}>
        <PlayVideoDialog autoPlay title={videoTitle} open={showVideoDialog} onClose={() => { setShowVideoDialog(false) }} thumbnailUrl={thumbnailUrl} videoUrl={videoUrl ?? ''} onEnded={() => { setShowVideoDialog(false) }} />

        {athlete != null &&
          <>
            <div className='grid w-full grid-cols-2 py-2 sm:py-3 justify-items-center'>
              <div className='block w-full'>
                <h3>{athlete.firstName} {athlete.lastName}</h3>
              <h4 className='pt-4 mb-4 text-lg font-normal text-gray-700 dark:text-dark-300'>Birth Year: <span className='ml-2 font-bold text-brand-600 dark:text-brand-500'>{athlete.birthYear}</span></h4>
                <p className='text-lg text-gray-700 dark:text-dark-300'>Age Eligibility:</p>
              <p className='pt-0 text-lg font-bold text-gray-700 dark:text-dark-300'>{athlete.ageGroups?.join(', ')}</p>
              {athlete.positions != null && athlete.positions.length > 0 &&
                <>
                <p className='pt-2 text-lg text-gray-700 dark:text-dark-300'>
                  Positions:
                  <span className='ml-2 text-lg font-bold text-gray-700 dark:text-dark-300'>{athlete.positions?.join(', ')}</span>
                </p>

                </>
              }
              {athlete.levels != null && athlete.levels.length > 0 &&
                <>
                <p className='pt-2 text-lg text-gray-700 dark:text-dark-300'>
                  Levels:
                  <span className='ml-2 text-lg font-bold text-gray-700 dark:text-dark-300'>{athlete.levels?.join(', ')}</span>
                </p>
                </>
              }
            </div>
            <div>
              <img className='rounded-full w-[150px]' src={athlete.headshotUrl ?? unknownUser} alt='Profile' />
              <div className='flex flex-row items-center gap-8'>
                <h4 className='pt-4 mb-4 text-lg text-gray-700 dark:text-dark-300'>Total Score</h4>
                <RatingTotal small value={athlete.totalRating} />
              </div>
            </div>
            </div>
            {tryouts != null && tryouts.length > 0 && tryouts.map((tryout) => (
              <div key={tryout.id} className='grid w-full grid-cols-1 py-1 border-t border-gray-200 dark:border-dark-600 sm:py-1 justify-items-center'>
                <div className='flex flex-row flex-wrap items-center gap-8'>
                <h4 className='pt-4 mb-4 text-lg text-gray-700 dark:text-dark-300'>{tryout.type}{(tryout.type === TryoutType.Jump) ? '' : ' ' + tryout.level}</h4>
                <Rating small value={tryout.rating} />
                </div>
                {tryout.recordings != null && tryout.recordings.length > 0 &&
                  <div className='flex flex-row flex-wrap items-center gap-2'>
                    {(tryout.recordings ?? []).map((recording) => (
                      <VideoPreview key={recording.id} className='w-16 h-32 mr-4' thumbUrl={recording.thumbnailUrl} videoUrl={recording.videoUrl ?? ''} onClick={() => { showVideo(athlete.firstName + ' ' + athlete.lastName, recording.videoUrl, recording.thumbnailUrl) }} />
                    ))}
                  </div>
                }
                {tryout.notes != null && tryout.notes.length > 0 &&
                  <div className='text-lg text-gray-700 dark:text-dark-300'>Notes: <span className='ml-2'>{tryout.notes}</span></div>
                }
              </div>
            ))}
          </>
        }
      </RightSlideout>
  </>

  )
}
