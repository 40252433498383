import type { IContinuationResult, ITeam, ITeamInput } from '../../../api/api'
import { apiSlice } from './apiSlice'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'teamsApiSlice' })

// RTK internal
interface PatchCollection {
  undo: () => void
}

export const teamsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTeams: builder.query<ITeam[], { seasonId: string }>({
      query: (id: { seasonId: string }) => `/seasons/${id.seasonId}/teams`,
      transformResponse: (responseData: IContinuationResult<ITeam>) => {
        return responseData?.items ?? []
      },

      providesTags: (result = [], _error, _arg) =>
        result != null
          ? [...result.map(({ id }) => ({ type: 'Team' as const, id })), 'Team']
          : ['Team']
    }),
    getTeam: builder.query<ITeam | undefined, { seasonId: string, teamId: string }>({
      query: (id: { seasonId: string, teamId: string }) => `/seasons/${id.seasonId}/teams/${id.teamId}`,
      providesTags: (result, _error, _arg) => [{ type: 'Team', id: result?.id }]
    }),
    addTeam: builder.mutation<ITeam, ITeamInput & { seasonId: string }>({
      query: (team: ITeamInput & { seasonId: string }) => ({
        url: `/seasons/${team.seasonId}/teams`,
        method: 'POST',
        body: team
      }),
      invalidatesTags: (_result, _error, _arg) => ['Team']
    }),
    editTeam: builder.mutation({
      query: (team: Partial<ITeamInput> & { teamId: string, seasonId: string }) => ({
        url: `/seasons/${team.seasonId}/teams/${team.teamId}`,
        method: 'PUT',
        body: team
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'Team', id: result?.id }]
    }),
    deleteTeam: builder.mutation({
      query: (id: { seasonId: string, teamId: string }) => ({
        url: `/seasons/${id.seasonId}/teams/${id.teamId}`,
        method: 'DELETE'
      }),
      async onQueryStarted (id: { seasonId: string, teamId: string }, { dispatch, queryFulfilled }) {
        let patchResult: PatchCollection | undefined
        log.debug('deleteTeam - posting')
        try {
          patchResult = dispatch(
            teamsApiSlice.util.updateQueryData('getTeams', { seasonId: id.seasonId }, (draft) => {
              if (draft == null) {
                log.debug('deleteTeam - draft is null')
                return
              }
              log.debug(`deleteTeam - draft: ${draft.length}`)
              // remove the team from the list
              draft.splice(draft.findIndex((item) => item.id === id.teamId), 1)
              // draft = draft.filter((item) => item.id !== id.teamId)
              log.debug('deleteTeam - drafted temp tag', draft.length)
            })
          )
          log.debug('deleteTeam - posting')
          await queryFulfilled
          log.debug('deleteTeam - posted')
        } catch (err) {
          log.debug('deleteTeam - exception', err)
          if (patchResult != null) {
            patchResult.undo()
          }

          dispatch(teamsApiSlice.util.invalidateTags(['Team']))
        }
      }
      // invalidatesTags: (_result, _error, arg) => [{ type: 'Team', id: arg.teamId }]
    })
  })
})

export const {
  useGetTeamsQuery,
  useGetTeamQuery,
  useAddTeamMutation,
  useEditTeamMutation,
  useDeleteTeamMutation
} = teamsApiSlice
