import { Button, Dialog, Form, type IconType, TextBox, GlobalDialogContext } from '@andyneville/tailwind-react'
import { CalendarIcon, ChevronDoubleRightIcon, NoSymbolIcon, PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useState, type ReactElement, useContext } from 'react'
import { type ISeasonUpdate, type ISeasonInput, type ISeason } from '../../../api/api'
import { useAddSeasonMutation, useDeleteSeasonMutation, useEditSeasonMutation } from '../reducers/apiSlice-seasons'
import { set as setCurrentSeason } from '../reducers/seasonReducer'
import { useDispatch, useSelector } from 'react-redux'
import { TenantSeasonContext } from './TenantSeasonProvider'
import { rootLog } from '../logging'
import { type RootState } from '../store'
import { EditSeasonDialog } from './EditSeasonDialog'

const log = rootLog.child({ module: 'SeasonDialog' })

export type CallbackFn = () => void
/*
function stopPropagationIcon (fn?: CallbackFn) {
  return (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
    if (fn != null) {
      fn()
    }
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
}

function stopPropagationButton(fn?: CallbackFn) {
  log.debug('stopPropagationButton')
  return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    log.debug('stopPropagationButton fn called')
    if (fn != null) {
      fn()
    }
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
} */

export interface UnboundSeasonDialogProps {
  seasons?: ISeason[]
  open: boolean
  canAddSeasons?: boolean
  onClose: () => void
  onDeleteSeason?: (seasonId: string) => void
  onChooseSeason?: (season: ISeason) => void
  onAddSeason?: (season: ISeasonInput) => void
  onEditSeason?: (seasonId: string, name: string) => void
}
// onClick={(event): void => { if (onDelete != null) { onDelete() }; event.stopPropagation(); event.nativeEvent.stopImmediatePropagation() }} />
function SeasonButton (props: { name: string, Icon: IconType, onClick: () => void, showDelete?: boolean, onDelete?: () => void, onEdit?: () => void, disabled?: boolean }): ReactElement {
  const { name, Icon, onClick, showDelete = false, onDelete, onEdit, disabled = false } = props
  if (disabled) {
    return (
      <a className='flex flex-row items-center w-full px-4 py-2 text-gray-400 border border-gray-400 rounded-lg cursor-not-allowed dark:text-dark-400 bg-gray-50 dark:border-dark-400 dark:bg-dark-600/30'>
        <Icon className='w-6 h-6' />
        <h3 className='ml-2 text-lg text-gray-400 dark:text-dark-400'>{name}</h3>
      </a>
    )
  }
  return (
    <a className='flex flex-row items-center w-full px-4 py-2 border rounded-lg cursor-pointer border-brand-600 bg-brand-50 dark:border-brand-400 dark:bg-brand-600/30 hover:bg-brand-200 hover:dark:bg-brand-600/50' onClick={onClick}>
      <Icon className='w-6 h-6' />
      <h3 className='ml-2 mr-2 text-lg'>{name}</h3>
      {showDelete &&
        <PencilIcon className='w-6 h-6 ml-auto text-brand-600' onClick={(event): void => { if (onEdit != null) { onEdit() } event.stopPropagation(); event.nativeEvent.stopImmediatePropagation() }} />
      }
      {showDelete &&
        <TrashIcon className='w-6 h-6 ml-auto text-red-600' onClick={(event): void => { if (onDelete != null) { onDelete() } event.stopPropagation(); event.nativeEvent.stopImmediatePropagation() }} />
      }
    </a>
  )
}

export function UnboundSeasonDialog (props: UnboundSeasonDialogProps): ReactElement {
  const { open, onClose, seasons = [], onChooseSeason, onAddSeason, onDeleteSeason, onEditSeason, canAddSeasons = true } = props
  const [showNewSeason, setShowNewSeason] = useState(false)
  const [seasonName, setSeasonName] = useState('')
  // const [dialogMessage, setDialogMessage] = useState('')
  // const [showConfirmWarning, setShowConfirmWarning] = useState(false)
  // const [confirmCallback, setConfirmCallback] = useState<CallbackFn | undefined>()

  const [showEditSeason, setShowEditSeason] = useState(false)
  const [editSeason, setEditSeason] = useState<ISeason | null>(null)

  const globalDialog = useContext(GlobalDialogContext)

  const doNewSeason = (): void => {
    onAddSeason?.({ name: seasonName })
  }

  const doEditSeason = (seasonId: string, name: string): void => {
    setShowEditSeason(false)
    onEditSeason?.(seasonId, name)
  }

  const onDelete = (season: ISeasonUpdate): void => {
    log.debug('onDelete')
    globalDialog.showWarning('Confirm Delete Season', `Are you sure you want to delete the season "${season.name}"? This cannot be undone!`, 'Delete', () => {
      log.debug('do delete!')
      if (onDeleteSeason != null) {
        onDeleteSeason(season.id)
      }
    }, () => {
      log.debug('cancel delete')
    })
  }

  const doChooseSeason = (season: ISeason): void => {
    if (onChooseSeason != null) {
      onChooseSeason(season)
    }
  }

  return (
    <>
      <Dialog title={showNewSeason ? 'Create New Season' : 'Choose Season'} Icon={CalendarIcon} open={open} onClose={onClose} buttons={
        <>
          {showNewSeason
            ? <><Button label='Add' primary onClick={doNewSeason} disabled={seasonName.length === 0} /><Button label='Cancel' onClick={() => { setShowNewSeason(false) }} /></>
            : <Button label='Close' onClick={onClose} />
          }
        </>
      }>
        {editSeason != null &&
          <EditSeasonDialog
            open={showEditSeason}
            onClose={() => { setShowEditSeason(false) }}
            onEditSeason={doEditSeason}
            season={editSeason}
          />
        }
        {showNewSeason
          ? (
            <>
              <Form>
                <TextBox columns={12} label='Season Name' value={seasonName} onChange={setSeasonName} />
              </Form>
            </>
            )
          : (
            <div className='grid gap-2 py-2'>
              {seasons.map((season) => (
                <SeasonButton key={season.id} name={season.name} Icon={ChevronDoubleRightIcon} showDelete onDelete={() => { onDelete(season) }} onEdit={() => { setEditSeason(season); setShowEditSeason(true) }} onClick={() => { doChooseSeason(season) }} />
              ))}
              {seasons.length > 0 &&
                <div className='pt-4' />
              }
              <SeasonButton name={canAddSeasons ? 'Create New Season' : 'Max Seasons on Plan'} disabled={!canAddSeasons} Icon={canAddSeasons ? PlusCircleIcon : NoSymbolIcon} onClick={() => { setShowNewSeason(true) }} />
            </div>
            )
        }

        </Dialog>
      </>
  )
}

export interface SeasonDialogProps {
  open: boolean
  onClose: () => void
}

export default function SeasonDialog (props: UnboundSeasonDialogProps): ReactElement {
  const { currentPlanInfo } = useSelector((state: RootState) => state.auth)
  const { open, onClose } = props
  const { seasons = [] } = useContext(TenantSeasonContext)
  // const {
  //   data: seasons = [],
  //   isLoading,
  //   isError,
  //   error
  // } = useGetSeasonsQuery(undefined, { refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const [addSeason] = useAddSeasonMutation()
  const [editSeason] = useEditSeasonMutation()
  const [deleteSeason] = useDeleteSeasonMutation()
  const dispatch = useDispatch()

  const onChooseSeason = (season: ISeason): void => {
    log.debug('setting current season to ', season)
    dispatch(setCurrentSeason(season))
    onClose()
  }

  const onEditSeason = (seasonId: string, name: string): void => {
    log.debug('editing season ', seasonId, name)
    void editSeason({ id: seasonId, name })
  }

  const onDeleteSeason = (seasonId: string): void => {
    log.debug('deleting season ', seasonId)
    void deleteSeason({ seasonId })
  }

  const onAddSeason = async (season: ISeasonInput): Promise<void> => {
    try {
      log.debug('creating new season', season)
      const newSeason = await addSeason(season).unwrap()
      if (newSeason != null) {
        log.debug('setting current (new) season to ', newSeason.id)
        dispatch(setCurrentSeason(newSeason))
        onClose()
      }
    } catch (error) {
      log.error(error)
    }
  }
  if (!open) {
    return <></>
  }

  const canAddSeasons = currentPlanInfo?.maxSeasons == null || seasons.length < currentPlanInfo.maxSeasons

  return (
    <UnboundSeasonDialog open={open} onClose={onClose} canAddSeasons={canAddSeasons} onEditSeason={onEditSeason} onDeleteSeason={onDeleteSeason} seasons={seasons} onChooseSeason={onChooseSeason} onAddSeason={(season) => { void onAddSeason(season) }} />
  )
}
