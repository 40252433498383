// import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks'
import type { IContinuationResult, ITryout, ITryoutInput, ITryoutRecording, ITryoutMedia, ITryoutMediaInput } from '../../../api/api'
import { apiRootUrl, apiSlice } from './apiSlice'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'tryoutsApiSlice' })

// RTK internal
interface PatchCollection {
  undo: () => void
}

export const tryoutsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTryouts: builder.query<ITryout[], { seasonId: string, athleteId: string }>({
      query: (id: { seasonId: string, athleteId: string }) => `/seasons/${id.seasonId}/athletes/${id.athleteId}/tryouts`,
      transformResponse: (responseData: IContinuationResult<ITryout>) => {
        return responseData?.items ?? []
      },

      providesTags: (result = [], _error, _arg) => [
        'Tryout',
        ...result.map(({ id }) => { log.debug('getTryouts-providesTags', result, id); return ({ type: 'Tryout' as const, id }) })
        // ...result.map(({ id }) => ({ type: 'Tryout' as const, id }))
      ]
    }),
    getTryout: builder.query<ITryout | undefined, { seasonId: string, athleteId: string, tryoutId: string }>({
      query: (id: { seasonId: string, athleteId: string, tryoutId: string }) => `/seasons/${id.seasonId}/athletes/${id.athleteId}/tryouts/${id.tryoutId}`,
      providesTags: (result, _error, _arg) => {
        log.debug('getTryout-providesTags', result); return [
          { type: 'Tryout', id: result?.id }
        // ...(result?.recordings ?? []).map(({ id }) => ({ type: 'Recording' as const, id }))
        ]
      }
    }),
    addTryout: builder.mutation<ITryout, ITryoutInput & { seasonId: string, athleteId: string }>({
      query: (newTryout: ITryoutInput & { seasonId: string, athleteId: string }) => ({
        url: `/seasons/${newTryout.seasonId}/athletes/${newTryout.athleteId}/tryouts`,
        method: 'POST',
        body: newTryout
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'Tryout', id: result?.id }, 'Tryout']
    }),
    editTryout: builder.mutation({
      query: (tryout: Partial<ITryout> & { id: string, seasonId: string, athleteId: string }) => ({
        url: `/seasons/${tryout.seasonId}/athletes/${tryout.athleteId}/tryouts/${tryout.id}`,
        method: 'PUT',
        body: tryout
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'Tryout', id: result?.id }, 'Tryout']
    }),
    addTryoutVideo: builder.mutation<ITryoutRecording, ITryoutMediaInput & { seasonId: string, athleteId: string, tryoutId: string, recording: Blob, thumbnailUrl?: string }>({
      query: (newTryout: ITryoutMediaInput & { seasonId: string, athleteId: string, tryoutId: string, recording: Blob, thumbnailUrl?: string }) => ({
        url: `/seasons/${newTryout.seasonId}/athletes/${newTryout.athleteId}/tryouts/${newTryout.tryoutId}/tryoutVideo`,
        method: 'POST',
        body: { mimeType: newTryout.mimeType }
      }),
      async onQueryStarted (newTryout: ITryoutMediaInput & { seasonId: string, athleteId: string, tryoutId: string, recording: Blob, thumbnailUrl?: string }, { dispatch, queryFulfilled }) {
        let patchResult: PatchCollection | undefined
        try {
          log.debug('addTryoutVideo - posting /tryoutVideo')
          const result = await queryFulfilled
          const tryoutVideoResult = result.data as unknown as ITryoutMedia // actually returns this but we're faking a local version
          log.debug('addTryoutVideo - /tryoutVideo id', tryoutVideoResult.id)
          // const recordingCopy = new Blob([newTryout.recording], { type: newTryout.mimeType })
          const newRecordingUrl = URL.createObjectURL(newTryout.recording)
          log.debug('addTryoutVideo - meta', result.meta)
          const authHeader = (result?.meta as { request?: Request })?.request?.headers.get('Authorization')
          log.debug('addTryoutVideo - meta', authHeader)
          const temporaryRecording = {
            id: tryoutVideoResult.id,
            thumbnailUrl: newTryout.thumbnailUrl,
            videoUrl: newRecordingUrl
          }
          log.debug('addTryoutVideo - temporaryRecording', temporaryRecording)
          // ;(meta as unknown as { tempRecording: ITryoutRecording }).tempRecording = temporaryRecording
          const patchResult = dispatch(
            tryoutsApiSlice.util.updateQueryData('getTryouts', { seasonId: newTryout.seasonId, athleteId: newTryout.athleteId }, (draft) => {
              log.debug('patch-1')
              if (draft == null) {
                log.debug('addTryoutVideo - draft is null')
                return
              }
              const tryout = draft.find(t => t.id === newTryout.tryoutId)
              if (tryout == null) {
                log.debug('addTryoutVideo - tryout is null')
                return
              }
              if (tryout.recordings == null) {
                tryout.recordings = []
              }
              tryout.recordings.push(temporaryRecording)
              //              const result = Object.assign(draft, { recordings })
              log.debug('addTryoutVideo - drafted temp tag', result, draft)
            })
          )
          log.debug('addTryoutVideo - uploading file', tryoutVideoResult.uploadUrl, newTryout.recording.size)
          const uploadResult = await fetch(tryoutVideoResult.uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': tryoutVideoResult.mimeType
              // 'Content-Length': newTryout.recording.size.toString(),
            },
            body: newTryout.recording
          })
          if (uploadResult.status !== 200) {
            log.debug('addTryoutVideo - upload failed', uploadResult)
            if (patchResult != null) {
              patchResult.undo()
              return
            }
          }
          let retryCount = 0
          const now = Date.now()
          const checkUpload = async (): Promise<void> => {
            if (retryCount++ > 10) {
              log.debug('addTryoutVideo - upload failed after 10 retries')
              clearInterval(uploadCheckInterval)
              if (patchResult != null) {
                patchResult.undo()
              }
              return
            }
            log.debug('addTryoutVideo - upload check', retryCount, Date.now() - now)
            const result = await fetch(`${apiRootUrl}/seasons/${newTryout.seasonId}/athletes/${newTryout.athleteId}/tryouts/${newTryout.tryoutId}`, {
              method: 'GET',
              headers: {
                Authorization: authHeader ?? ''
              } satisfies HeadersInit
            })
            if (result.status !== 200) {
              log.debug('addTryoutVideo - got result', result.status, result.statusText)
              return
            }
            const data = await result.json() as ITryout
            log.debug('addTryoutVideo - got data', data)
            const recording = (data.recordings ?? []).find(r => r.id === tryoutVideoResult.id)
            if (recording == null) {
              log.debug('addTryoutVideo - recording not found')
              return
            }
            if (recording.thumbnailUrl == null || recording.videoUrl == null) {
              log.debug('addTryoutVideo - recording not complete', recording)
              return
            }
            clearInterval(uploadCheckInterval)
            log.debug('addTryoutVideo - upload complete')
            dispatch(
              tryoutsApiSlice.util.updateQueryData('getTryouts', { seasonId: newTryout.seasonId, athleteId: newTryout.athleteId }, (draft) => {
                log.debug('patch-2')
                if (draft == null) {
                  log.debug('addTryoutVideo2 complete - draft is null')
                  return
                }
                const tryout = draft.find(t => t.id === newTryout.tryoutId)
                if (tryout == null) {
                  log.debug('addTryoutVideo2 - tryout is null')
                  return
                }
                if (tryout.recordings == null) {
                  log.debug('addTryoutVideo2 - tryout is null')
                  return
                }
                const oldRecording = tryout.recordings.find(r => r.id === recording.id)
                if (oldRecording == null) {
                  log.debug('addTryoutVideo2 - oldRecording is null')
                  return
                }
                oldRecording.thumbnailUrl = recording.thumbnailUrl
                oldRecording.videoUrl = recording.videoUrl
                log.debug('addTryoutVideo2 done')
              })
            )/*
              tryoutsApiSlice.util.updateQueryData('getTryout', { seasonId: newTryout.seasonId, athleteId: newTryout.athleteId, tryoutId: newTryout.tryoutId }, (draft) => {
                if (draft == null) {
                  log.debug('addTryoutVideo complete - draft is null')
                  return
                }
                const result = Object.assign(draft, data)
                log.debug('addTryoutVideo complete - updated final tag', result)
              })
            ) */
          }
          const uploadCheckInterval = setInterval(() => {
            void checkUpload()
          }, 5000)
        } catch (err) {
          log.debug('addTryoutVideo - exception', err)
          if (patchResult != null) {
            patchResult.undo()
          }

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      }/* ,
      transformResponse: (responseData: ITryoutRecording, meta: unknown) => {
        const temporaryRecording = (meta as unknown as { tempRecording: ITryoutRecording }).tempRecording
        log.debug('addTryoutVideo - transformResponse', responseData, temporaryRecording)
        return temporaryRecording
      }, */

    }),
    addTryoutVideoSync: builder.mutation<ITryoutRecording & { mimeType: string, uploadUrl: string }, ITryoutMediaInput & { seasonId: string, athleteId: string, tryoutId: string, recording: Blob, thumbnailUrl?: string }>({
      query: (newTryout: ITryoutMediaInput & { seasonId: string, athleteId: string, tryoutId: string, recording: Blob, thumbnailUrl?: string }) => ({
        url: `/seasons/${newTryout.seasonId}/athletes/${newTryout.athleteId}/tryouts/${newTryout.tryoutId}/tryoutVideo`,
        method: 'POST',
        body: { mimeType: newTryout.mimeType }
      }),
      async onQueryStarted (newTryout: ITryoutMediaInput & { seasonId: string, athleteId: string, tryoutId: string, recording: Blob, thumbnailUrl?: string }, { dispatch, queryFulfilled }) {
        try {
          log.debug('addTryoutVideo - posting /tryoutVideo')
          const result = await queryFulfilled
          const tryoutVideoResult = result.data as unknown as ITryoutMedia // actually returns this but we're faking a local version
          log.debug('addTryoutVideo - /tryoutVideo id', tryoutVideoResult.id)
          // const recordingCopy = new Blob([newTryout.recording], { type: newTryout.mimeType })
          log.debug('addTryoutVideo - meta', result.meta)
          const authHeader = (result?.meta as { request?: Request })?.request?.headers.get('Authorization')
          log.debug('addTryoutVideo - meta', authHeader)
          const newRecordingUrl = URL.createObjectURL(newTryout.recording)
          const temporaryRecording = {
            id: tryoutVideoResult.id,
            thumbnailUrl: newTryout.thumbnailUrl,
            videoUrl: newRecordingUrl
          }
          log.debug('addTryoutVideo - temporaryRecording', temporaryRecording)

          dispatch(
            tryoutsApiSlice.util.updateQueryData('getTryouts', { seasonId: newTryout.seasonId, athleteId: newTryout.athleteId }, (draft) => {
              log.debug('patch-1')
              if (draft == null) {
                log.debug('addTryoutVideo - draft is null')
                return
              }
              const tryout = draft.find(t => t.id === newTryout.tryoutId)
              if (tryout == null) {
                log.debug('addTryoutVideo - tryout is null')
                return
              }
              if (tryout.recordings == null) {
                tryout.recordings = []
              }
              tryout.recordings.push(temporaryRecording)
              //              const result = Object.assign(draft, { recordings })
              log.debug('addTryoutVideo - drafted temp tag', result, draft)
            })
          )
          let retryCount = 0
          const now = Date.now()
          const checkUpload = async (): Promise<void> => {
            if (retryCount++ > 10) {
              log.debug('addTryoutVideo - upload failed after 10 retries')
              clearInterval(uploadCheckInterval)
              return
            }
            log.debug('addTryoutVideo - upload check', retryCount, Date.now() - now)
            const result = await fetch(`${apiRootUrl}/seasons/${newTryout.seasonId}/athletes/${newTryout.athleteId}/tryouts/${newTryout.tryoutId}`, {
              method: 'GET',
              headers: {
                Authorization: authHeader ?? ''
              } satisfies HeadersInit
            })
            if (result.status !== 200) {
              log.debug('addTryoutVideo - got result', result.status, result.statusText)
              return
            }
            const data = await result.json() as ITryout
            log.debug('addTryoutVideo - got data', data)
            const recording = (data.recordings ?? []).find(r => r.id === tryoutVideoResult.id)
            if (recording == null) {
              log.debug('addTryoutVideo - recording not found')
              return
            }
            if (recording.thumbnailUrl == null || recording.videoUrl == null) {
              log.debug('addTryoutVideo - recording not complete', recording)
              return
            }
            clearInterval(uploadCheckInterval)
            log.debug('addTryoutVideo - upload complete')
            dispatch(
              tryoutsApiSlice.util.updateQueryData('getTryouts', { seasonId: newTryout.seasonId, athleteId: newTryout.athleteId }, (draft) => {
                log.debug('patch-2')
                if (draft == null) {
                  log.debug('addTryoutVideo2 complete - draft is null')
                  return
                }
                const tryout = draft.find(t => t.id === newTryout.tryoutId)
                if (tryout == null) {
                  log.debug('addTryoutVideo2 - tryout is null')
                  return
                }
                if (tryout.recordings == null) {
                  log.debug('addTryoutVideo2 - tryout is null')
                  return
                }
                const oldRecording = tryout.recordings.find(r => r.id === recording.id)
                if (oldRecording == null) {
                  log.debug('addTryoutVideo2 - oldRecording is null')
                  return
                }
                oldRecording.thumbnailUrl = recording.thumbnailUrl
                oldRecording.videoUrl = recording.videoUrl
                log.debug('addTryoutVideo2 done')
              })
            )/*
              tryoutsApiSlice.util.updateQueryData('getTryout', { seasonId: newTryout.seasonId, athleteId: newTryout.athleteId, tryoutId: newTryout.tryoutId }, (draft) => {
                if (draft == null) {
                  log.debug('addTryoutVideo complete - draft is null')
                  return
                }
                const result = Object.assign(draft, data)
                log.debug('addTryoutVideo complete - updated final tag', result)
              })
            ) */
          }
          const uploadCheckInterval = setInterval(() => {
            void checkUpload()
          }, 5000)
        } catch (err) {
          log.debug('addTryoutVideo - exception', err)

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      }/* ,
      transformResponse: (responseData: ITryoutRecording, meta: unknown) => {
        const temporaryRecording = (meta as unknown as { tempRecording: ITryoutRecording }).tempRecording
        log.debug('addTryoutVideo - transformResponse', responseData, temporaryRecording)
        return temporaryRecording
      }, */

    }),
    deleteTryout: builder.mutation({
      query: (id: { seasonId: string, athleteId: string, tryoutId: string }) => ({
        url: `/seasons/${id.seasonId}/athletes/${id.athleteId}/tryouts/${id.tryoutId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Tryout', id: arg.tryoutId }]
    })
  })
})

export async function uploadVideoFile (uploadUrl: string, mimeType: string, recording: Blob): Promise<boolean> {
  // ;(meta as unknown as { tempRecording: ITryoutRecording }).tempRecording = temporaryRecording
  log.debug('addTryoutVideo - uploading file', uploadUrl, recording.size)
  try {
    const uploadResult = await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': mimeType
        // 'Content-Length': newTryout.recording.size.toString(),
      },
      body: recording
    })
    if (uploadResult.status !== 200) {
      log.debug('addTryoutVideo - upload failed', uploadResult)
      return false
    }
    log.debug('addTryoutVideo - uploaded', uploadResult)
    return true
  } catch (err) {
    log.debug('addTryoutVideo - upload exception', err)
    return false
  }
}

export const {
  useGetTryoutsQuery,
  useGetTryoutQuery,
  useAddTryoutMutation,
  useEditTryoutMutation,
  useAddTryoutVideoMutation,
  useAddTryoutVideoSyncMutation,
  useDeleteTryoutMutation
} = tryoutsApiSlice
