import pino from '@andyneville/pino'
import { db } from './db'
// import pino from 'pino'
// import loglevel, { type RootLogger } from 'loglevel'

// https://getpino.io/#/docs/api?id=interpolationvalues

// debugger
let _isRecording = false

export function isRecording (): boolean {
  return _isRecording
}

export async function haveLogs (): Promise<boolean> {
  return await db.logs.count() > 0
}

const maxRecordingTime = 1000 * 60 * 10 // 10 minutes

let timer: number | undefined
export async function startRecording (): Promise<void> {
  console.log('startRecording')
  await db.logs.clear()
  await db.settings.put({ id: 'recordLogs', boolean: true })
  await db.settings.put({ id: 'recordLogExpiration', number: Date.now() + maxRecordingTime })
  timer = setTimeout(() => {
    console.log('stopRecording after expiration')
    void stopRecording()
    timer = undefined
  }, maxRecordingTime) as unknown as number
  _isRecording = true
}

export async function stopRecording (): Promise<void> {
  console.log('stopRecording')
  if (timer != null) {
    clearTimeout(timer)
    timer = undefined
  }
  _isRecording = false
  await db.settings.put({ id: 'recordLogs', boolean: false })
  await db.settings.put({ id: 'recordLogExpiration', number: 0 })
}

export async function clearRecording (): Promise<void> {
  console.log('clearRecording')
  if (timer != null) {
    clearTimeout(timer)
    timer = undefined
  }
  _isRecording = false
  await db.logs.clear()
  await db.settings.put({ id: 'recordLogs', boolean: false })
  await db.settings.put({ id: 'recordLogExpiration', number: 0 })
}
export async function getLogs (): Promise<Blob> {
  const logEntries = (await db.logs.toArray()).map((entry) => entry.log + '\n')
  return new Blob(logEntries, { type: 'text/plain' })
}

async function initializeLogging (): Promise<void> {
  try {
    const recordLogs = await db.settings.get({ id: 'recordLogs' })
    if (recordLogs?.boolean === true) {
      const recordLogExpiration = await db.settings.get({ id: 'recordLogExpiration' })
      if (recordLogExpiration?.number != null && recordLogExpiration.number > Date.now()) {
        console.log('initializeLogging: resumeRecording for ', Math.round((recordLogExpiration.number - Date.now()) / 6000) / 10, 'minutes')
        _isRecording = true
        timer = setTimeout(() => {
          console.log('stopRecording after expiration')
          void stopRecording()
          timer = undefined
        }, recordLogExpiration.number - Date.now()) as unknown as number
      }
    }
  } catch { }
}
void initializeLogging()

let _buffer: string[] = []
let _bufferTimer: number | undefined
const bufferInterval = 1000
function writeLog (logEvent: string): void {
  _buffer.push(logEvent)
  if (_bufferTimer == null) {
    _bufferTimer = setTimeout(() => {
      _bufferTimer = undefined
      const entries = _buffer
      _buffer = []
      void db.logs.bulkAdd(entries.map((log) => ({ log })))
    }, bufferInterval) as unknown as number
  }
}

export const rootLog = pino({
  name: 'CheerSync',
  level: 'debug',
  browser: {
    asObject: true,
    write: (logEvent) => {
      if ((logEvent as { msg?: string })?.msg != null) {
        console.log((logEvent as { msg?: string }).msg)
      }
      if (_isRecording) {
        writeLog(JSON.stringify(logEvent))
      }
    },
    formatHook: function (args: [msg: string, ...args: unknown[]]): [msg: string, ...args: unknown[]] {
      // console.log('formatHook', args)
      const argsCopy = args.slice() as string[]
      if (args.length > 2 && typeof args[1] === 'string' && !args[1].includes('%')) {
        for (let i = 2; i < args.length; i++) {
          if (typeof args[i] === 'object') {
            argsCopy[1] = `${argsCopy[1]} %j`
            continue
          } else if (typeof args[i] === 'number') {
            argsCopy[1] = `${argsCopy[1]} %d`
            continue
          } else if (typeof args[i] === 'string') {
            argsCopy[1] = `${argsCopy[1]} %s`
            continue
          } else {
            argsCopy[1] = `${argsCopy[1]} %s`
          }
        }
      }
      // console.log('formatHook', argsCopy)
      return argsCopy as [msg: string, ...args: unknown[]]
    }
  }
})

// const root = loglevel.noConflict() as RootLogger
// root.setLevel('DEBUG', false)
// root.rebuild()

// export const rootLog = {
//   child: (options: { module: string }) => {
//     return root.getLogger(options.module)
//   }
// }

// let _isRecording = false
// let _buffer: string[] = []

// export function startRecording (): void {
//   _isRecording = true
//   _buffer = []
// }

// const originalFactory = loglevel.methodFactory
// loglevel.methodFactory = function (methodName, logLevel, loggerName) {
//   const rawMethod = originalFactory(methodName, logLevel, loggerName)

//   return function (message) {
//     _buffer.push(rawMethod(message))
//   }
// }
// loglevel.rebuild()

// import logPrefix from 'loglevel-plugin-prefix'

/* logPrefix.reg(rootLog)
const l2 = logPrefix.apply(rootLog, {
  template: '[%t] %l %n:',
  levelFormatter: function (level) {
    return level.toUpperCase()
  },
  nameFormatter: function (name) {
    return name ?? 'root'
  },
  timestampFormatter: function (date) {
    return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).* /, '$1')
  },
  format: undefined
})

l2.rebuild()

l2.error('rootLog error')
 */
/*
const originalFactory = rootLog.methodFactory
rootLog.methodFactory = function (methodName, logLevel, loggerName) {
  const rawMethod = originalFactory(methodName, logLevel, loggerName)

  return function (message) {
    rawMethod('Newsflash: ' + message)
  }
}

rootLog.rebuild() */
/* rootLog.setDefaultLevel('WARN')
rootLog.setLevel('WARN', false)
// loglevel.enableAll()

rootLog.getLogger('authReducer').setLevel('DEBUG', false)
rootLog.getLogger('AuthRoutes').setLevel('DEBUG', false)
rootLog.getLogger('apiSlice').setLevel('DEBUG', false)
rootLog.getLogger('tryoutsApiSlice').setLevel('DEBUG', false)
rootLog.getLogger('Tryout').setLevel('DEBUG', false)
rootLog.getLogger('RecordVideoDialog').setLevel('DEBUG', false)
rootLog.getLogger('ClientSyncProvider').setLevel('DEBUG', false)

// rootLog.getLogger('TenantSeasonProvider').setLevel('WARN', false)
// rootLog.getLogger('RosterView').setLevel('WARN', false)
// rootLog.getLogger('App').setLevel('WARN', false)

rootLog.rebuild()
 */
