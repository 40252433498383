import { type PropsWithChildren, createContext, useEffect, useState, type ReactElement, useContext } from 'react'
import { clearRecording, getLogs, haveLogs, isRecording, rootLog, startRecording, stopRecording } from '../logging'
import { createBackoff, createLock, intervalWithImmediate, omit } from '../util'
import { type Recording, db, dbLock } from '../db'
import { type RootState, useAppDispatch } from '../store'
import { AuthState, getTokenAndRefreshIfNeeded } from '../reducers/authReducer'
import { apiRootUrl } from '../reducers/apiSlice'
import { type ITryout } from '../../../api/api'
import { TenantSeasonContext } from './TenantSeasonProvider'
import { useGetAthletesQuery } from '../reducers/apiSlice-athletes'
import { useSelector } from 'react-redux'
import { Button, Dialog } from '@andyneville/tailwind-react'
import { ArrowTopRightOnSquareIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'

const log = rootLog.child({ module: 'ClientSyncProvider' })

const recordingSyncInterval = 10000 // 10 seconds

const timeForRecordingToProcess = 40000 // 40 seconds
const timeForHighFailureTimeout = 2 * 60 * 60 * 1000 // 2 hours

let haveSeenRecordingIssue = false

let haveWarnedAboutRecordingIssue = localStorage.getItem('haveWarnedAboutRecordingIssue') === 'true'

export interface ClientSyncContextType {
  videoAdded: (clientId: number) => void
  headshotAdded: (clientId: number) => void
  canPersist: string
  lastUpdatedVideo: number
  lastUpdatedHeadshot: number
  isCollectingLogs: boolean
  setIsCollectingLogs: (value: boolean) => Promise<void>
  getLogs: () => Promise<Blob>
  clearLogs: () => Promise<void>
  haveLogs: boolean
}

export const ClientSyncContext = createContext<ClientSyncContextType>({
  videoAdded: () => { },
  headshotAdded: () => { },
  canPersist: 'uninitialized',
  lastUpdatedVideo: 0,
  lastUpdatedHeadshot: 0,
  isCollectingLogs: false,
  setIsCollectingLogs: async () => { },
  getLogs: async () => new Blob(),
  clearLogs: async () => {},
  haveLogs: false
})

const updateWithBackoff = createBackoff(async (localId: number, recording: Partial<Recording>): Promise<void> => {
  log.debug('attempt update', localId)
  await db.recordings.update(localId, recording)
  log.debug('update complete', localId)
})

const recordingLock = createLock(dbLock('recordingSync'))
async function syncRecordings (accessToken: string): Promise<boolean> {
  log.debug('syncRecordingsx')
  let updated = false
  await recordingLock(async (): Promise<void> => {
    log.debug('doing recording sync cycle')
    const recordings = await db.recordings.toArray()
    if (recordings.length === 0) {
      log.debug('no recordings to syncx')
      return
    }
    log.debug('recordings to sync', recordings?.map(r => omit(r, 'thumbnail', 'video')))
    const now = Date.now()

    for (const recording of recordings.filter(r => r.uploadCompleted)) {
      if ((recording.uploadAttempts ?? 1) > 10) {
        log.debug('recording failed too many times, waiting until restart', recording.localId, recording.uploadStarted, (recording.uploadStartedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      if ((recording.uploadAttempts ?? 1) > 5 && ((recording.uploadCompletedAt ?? 0) + timeForHighFailureTimeout) > now) {
        log.debug('recording failed many times, waiting 2 hours to retry verification', recording.localId, recording.uploadStarted, (recording.uploadStartedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      log.debug('check recording updated status', recording.localId)
      const result = await fetch(`${apiRootUrl}/seasons/${recording.seasonId}/athletes/${recording.athleteId}/tryouts/${recording.tryoutId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      if (result.status !== 200) {
        log.debug('fetch recording updated status - got no result', recording.localId, result.status, result.statusText)
        break
      }
      const data = await result.json() as ITryout
      if (data == null) {
        log.debug('fetch recording updated status - tryout not foud')
        break
      }
      const foundRecording = (data.recordings ?? []).find(r => r.id === recording.id)
      if (foundRecording?.thumbnailUrl == null || foundRecording.videoUrl == null) {
        log.debug('fetch recording updated status - thumbnail or recording not found', foundRecording)
        break
      }
      log.debug('fetch recording updated status - remote item found, removing local item', recording.localId)
      await db.recordings.delete(recording.localId)
      log.debug('fetch recording updated status - delete complete', recording.localId)
      updated = true
    }

    for (const recording of recordings) {
      if (recording.uploadCompleted && ((recording.uploadCompletedAt ?? 0) + timeForRecordingToProcess) > now) {
        log.debug('recording uploaded, still waiting to verify', recording.localId, recording.uploadCompletedAt, (recording.uploadCompletedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      if (recording.uploadStarted && ((recording.uploadStartedAt ?? 0) + timeForRecordingToProcess) > now) {
        log.debug('recording uploaded, still waiting to upload', recording.localId, recording.uploadStarted, (recording.uploadStartedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      if ((recording.uploadAttempts ?? 1) > 10) {
        log.debug('recording failed too many times, waiting until restart', recording.localId, recording.uploadStarted, (recording.uploadStartedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      if ((recording.uploadAttempts ?? 1) > 20 && ((recording.createdAt?.getTime() ?? 0) + timeForHighFailureTimeout) > now) {
        log.debug('recording failed many times, waiting 2 hours to retry', recording.localId, recording.uploadStarted, (recording.uploadStartedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      try {
        let id: string
        let uploadUrl: string
        if (recording.id == null) {
          log.debug('creating tryout recording upload')
          const response = await fetch(`${apiRootUrl}/seasons/${recording.seasonId}/athletes/${recording.athleteId}/tryouts/${recording.tryoutId}/tryoutVideo`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mimeType: recording.videoMimeType })
          })
          if (!response.ok) {
            log.debug('failed to create tryout recording', response, await response.text())
            throw new Error('Failed to fetch tryout recording')
          }
          const responseJson = await response.json()
          id = responseJson.id
          uploadUrl = responseJson.uploadUrl
          recording.id = id
          log.debug('creating tryout recording upload - updating recording')
          await updateWithBackoff(recording.localId, { id, uploadAttempts: recording.uploadAttempts + 1, uploadStarted: true, uploadStartedAt: Date.now(), uploadCompleted: false })
          log.debug('creating tryout recording upload - updating recording complete')
        } else {
          log.debug('retrying tryout recording upload 2')
          const response = await fetch(`${apiRootUrl}/seasons/${recording.seasonId}/athletes/${recording.athleteId}/tryouts/${recording.tryoutId}/tryoutVideoRestart/${recording.id}`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mimeType: recording.videoMimeType })
          })
          if (!response.ok) {
            log.debug('failed to restart tryout recording 1')
            log.debug('failed to restart tryout recording', response, await response.text())
            throw new Error('Failed to fetch tryout recording')
          }
          log.debug('retrying tryout recording upload - status ok')
          const responseJson = await response.json()
          id = responseJson.id
          uploadUrl = responseJson.uploadUrl
          recording.id = id
          log.debug('retrying tryout recording upload - updating recording')
          await updateWithBackoff(recording.localId, { id, uploadAttempts: recording.uploadAttempts + 1, uploadStarted: true, uploadStartedAt: Date.now(), uploadCompleted: false })
          log.debug('retrying tryout recording upload - recording updated')
        }
        log.debug(`uploading tryout recording to ${uploadUrl}`)
        const uploadResult = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': recording.videoMimeType
          },
          body: recording.video
        })
        if (uploadResult.status < 200 && uploadResult.status >= 300) {
          log.debug('addTryoutVideo - upload failed', uploadResult, await uploadResult.text())
          throw new Error('Failed to upload tryout video')
        }
        log.debug('upload completed', id, recording.localId)
        await updateWithBackoff(recording.localId, { id, uploadCompleted: true, uploadCompletedAt: Date.now() })
        log.debug('uploaded tryout recording', id, recording.localId)
      } catch (err) {
        log.error('Exception uploading tryout recording', err)
        haveSeenRecordingIssue = true
        recording.uploadAttempts = recording.uploadAttempts + 1
        log.debug('incrementing exception count')
        await db.recordings.put(recording)
        log.debug('incremented exception count')
      }
    }
  })
  log.debug('syncRecordings', updated ? 'updated' : 'not updated')
  return updated
}

const headshotLock = createLock(dbLock('headshotSync'))
async function syncHeadshots (accessToken: string): Promise<boolean> {
  log.debug('syncHeadshots')
  let updated = false
  await headshotLock(async (): Promise<void> => {
    log.debug('doing headshot sync cycle')
    const headshots = await db.headshots.toArray()
    if (headshots.length === 0) {
      log.debug('no headshots to sync')
      return
    }
    log.debug('headshots to sync', headshots)
    const now = Date.now()

    for (const headshot of headshots.filter(h => h.uploadCompleted)) {
      if ((headshot.uploadAttempts ?? 1) > 20 && ((headshot.uploadCompletedAt ?? 0) + timeForHighFailureTimeout) > now) {
        log.debug('headshot failed many times, waiting 2 hours to retry verification', headshot.localId, headshot.uploadStarted, (headshot.uploadStartedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      log.debug('check headshot updated status', headshot.localId)
      const result = await fetch(`${apiRootUrl}/seasons/${headshot.seasonId}/athletes/${headshot.athleteId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      if (result.status !== 200) {
        log.debug('fetch headshot updated status - got no result', headshot.localId, result.status, result.statusText)
        break
      }
      const athlete = await result.json()
      if (athlete?.headshotUrl == null) {
        log.debug('fetch headshot updated status - headshot not found')
        break
      }

      log.debug('fetch headshot updated status - remote item found, removing local item', headshot.localId)
      await db.headshots.delete(headshot.localId)
      updated = true
    }

    for (const headshot of headshots) {
      if (headshot.uploadCompleted && ((headshot.uploadCompletedAt ?? 0) + timeForRecordingToProcess) > now) {
        log.debug('headshot uploaded, still waiting to verify', headshot.localId, headshot.uploadCompletedAt, (headshot.uploadCompletedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      if (headshot.uploadStarted && ((headshot.uploadStartedAt ?? 0) + timeForRecordingToProcess) > now) {
        log.debug('headshot uploaded, still waiting to upload', headshot.localId, headshot.uploadStarted, (headshot.uploadStartedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      if ((headshot.uploadAttempts ?? 1) > 20 && ((headshot.createdAt?.getTime() ?? 0) + timeForHighFailureTimeout) > now) {
        log.debug('headshot failed many times, waiting 2 hours to retry', headshot.localId, headshot.uploadStarted, (headshot.uploadStartedAt ?? 0) + timeForRecordingToProcess - now)
        break
      }
      try {
        log.debug('creating headshot upload')
        const response = await fetch(`${apiRootUrl}/seasons/${headshot.seasonId}/athletes/${headshot.athleteId}/headshot`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ mimeType: headshot.headshotMimeType })
        })
        if (!response.ok) {
          log.debug('failed to create headshot', response, await response.text())
          throw new Error('Failed to create headshot')
        }
        const { id, uploadUrl, downloadUrl } = await response.json() as { id: string, uploadUrl: string, downloadUrl: string }
        await db.headshots.update(headshot.localId, { id, headshotUrl: downloadUrl, uploadAttempts: headshot.uploadAttempts + 1, uploadStarted: true, uploadStartedAt: Date.now(), uploadCompleted: false })
        log.debug(`uploading headshot to ${uploadUrl}`)
        const uploadResult = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': headshot.headshotMimeType
          },
          body: headshot.headshot
        })
        if (uploadResult.status < 200 && uploadResult.status >= 300) {
          log.debug('addheadshot - upload failed', uploadResult, await uploadResult.text())
          throw new Error('Failed to upload headshot')
        }
        await db.headshots.update(headshot.localId, { id, uploadCompleted: true, uploadCompletedAt: Date.now() })
        log.debug('uploaded headshot', id, headshot.localId)
      } catch (err) {
        log.error('Exception uploading headshot', err)
      }
    }
  })
  log.debug('syncHeadshots', updated ? 'updated' : 'not updated')
  return updated
}

export default function ClientSyncProvider (props: PropsWithChildren): ReactElement {
  const { season } = useContext(TenantSeasonContext)
  const { authState } = useSelector((state: RootState) => state.auth)
  const [showVideoWarning, setShowVideoWarning] = useState(false)

  const doGetLogs = async (): Promise<Blob> => {
    const blob = new Blob([await getLogs()], { type: 'text/plain' })
    return blob
  }

  const {
    refetch: refetchAthletes
  } = useGetAthletesQuery({ seasonId: season?.id ?? '' }, { skip: authState !== AuthState.Authenticated || season == null })
  const { children } = props
  const [canPersist, setCanPersist] = useState('uninitialized')
  const [lastUpdatedVideo, setLastUpdatedVideo] = useState(0)
  const [lastUpdatedHeadshot, setLastUpdatedHeadshot] = useState(0)
  const [currentlyRecording, setCurrentlyRecording] = useState(isRecording())
  const [doHaveLogs, setDoHaveLogs] = useState(false)
  const videoAdded = (clientId: number): void => {
    log.debug('videoAdded', clientId)
  }
  const headshotAdded = (clientId: number): void => {
    log.debug('headshotAdded', clientId)
  }
  const dispatch = useAppDispatch()

  useEffect(() => {
    void (async () => {
      setDoHaveLogs(await haveLogs())
    })()
  }, [])

  useEffect(() => {
    log.debug('ClientSyncProvider useEffect')
    const { stop } = intervalWithImmediate(async (): Promise<void> => {
      if (authState !== AuthState.Authenticated) {
        return
      }
      if (haveSeenRecordingIssue && !haveWarnedAboutRecordingIssue) {
        haveWarnedAboutRecordingIssue = true
        localStorage.setItem('haveWarnedAboutRecordingIssue', 'true')
        setShowVideoWarning(true)
      }

      const recordings = await db.recordings.toArray()
      const headshots = await db.headshots.toArray()
      if (recordings.length === 0 && headshots.length === 0) {
        log.debug('no recordings or headshots to sync')
        return
      }
      const accessToken = await dispatch(getTokenAndRefreshIfNeeded())
      try {
        log.debug('ue syncRecordings')
        let updated = await syncRecordings(accessToken)
        if (updated) {
          log.debug('updated, setting lastUpdatedVideo')
          setLastUpdatedVideo(Date.now())
        }
        log.debug('ue syncHeadshots')
        updated = await syncHeadshots(accessToken)
        if (updated) {
          log.debug('updated, setting lastUpdatedVideo')
          setLastUpdatedHeadshot(Date.now())
          void refetchAthletes()
        }
        log.debug('ue done')
      } catch (err) {
        log.error('Exception in syncRecordings', err)
      }
    }, recordingSyncInterval, true)
    return () => {
      stop()
    }
  }, [dispatch, refetchAthletes, authState])

  log.debug('canPersist', canPersist)

  useEffect(() => {
    async function checkStorage (): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!navigator.storage?.persisted) {
        setCanPersist('never')
        return
      }
      let persisted = await navigator.storage.persisted()
      if (persisted) {
        setCanPersist('persisted1')
        return
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (navigator.permissions?.query == null) {
        setCanPersist('prompt1')
        return // It MAY be successful to prompt. Don't know.
      }
      try {
        const permission = await navigator.permissions.query({
          name: 'persistent-storage'
        })
        if (permission.state === 'granted') {
          persisted = await navigator.storage.persist()
          if (persisted) {
            setCanPersist('persisted2')
            return
          } else {
            throw new Error('Failed to persist')
          }
        }
        if (permission.state === 'prompt') {
          setCanPersist('prompt2')
          return
        }
      } catch {
        // Safari seems not to support navigator.permissions.query
        setCanPersist('prompt3')
        return // It MAY be successful to prompt. Don't know.
      }
      setCanPersist('never')
    }
    void checkStorage()
  }, [])

  return (
    <ClientSyncContext.Provider value={{
      videoAdded,
      headshotAdded,
      canPersist,
      lastUpdatedVideo,
      lastUpdatedHeadshot,
      isCollectingLogs: currentlyRecording,
      setIsCollectingLogs: async (value) => {
        if (value) {
          await startRecording()
          setDoHaveLogs(await haveLogs())
        } else {
          await stopRecording()
          setDoHaveLogs(await haveLogs())
        }
        setCurrentlyRecording(isRecording())
      },
      getLogs: async () => {
        return await doGetLogs()
      },
      clearLogs: async () => {
        await clearRecording()
        setCurrentlyRecording(isRecording())
        setDoHaveLogs(false)
      },
      haveLogs: doHaveLogs
    }}>
      <Dialog
        open={showVideoWarning}
        title="Video Recording Warning"
        Icon={ExclamationCircleIcon}
        iconClassName='rounded-full text-red-500 bg-red-200 dark:text-red-400 dark:bg-red-500/30 w-10 h-10 p-1'
        onClose={() => { setShowVideoWarning(false) }}
        buttons={(<>
          <Button className='sm:ml-3' label="OK" primary onClick={() => { setShowVideoWarning(false) }} />
        </>)}
      >
        <p>
          We have detected an issue with this device that may cause videos to not be stored properly.
          We are investigating a solution, but a small number of customers with similar problems have lost videos
          and in the meantime we recommend you pay close attention to your video uploads.
        </p>
        <p className="pt-4">
          Please see our <a href="https://cheersyncapp.com/faq" target='_blank'>FAQ page <ArrowTopRightOnSquareIcon className='-mt-1 inline w-4' /></a> for more information and instructions.
        </p>
        <p className="pt-4">
          Please reach out to our support at <a href="mailto:support@cheersync.app" target='_blank'>support@cheersync.app</a> if you have any trouble!
        </p>
      </Dialog>
      {children}
    </ClientSyncContext.Provider>
  )
}
