import { useState, type ReactElement } from 'react'
import { Picker, classes } from '@andyneville/tailwind-react'

export interface RatingProps {
  value: number | null | undefined
  small?: boolean
  min?: number
  max?: number
  className?: string
  onChange?: (value: number) => void
}

export default function Rating (props: RatingProps): ReactElement {
  const { value, onChange, min = 1, max = 10, small = false, className } = props
  const [showPicker, setShowPicker] = useState(false)
  // button w-12 = 3rem
  // gap-x-2 = 0.5rem
  // p-2 = 0.5rem (0.25 since half is on each side)
  // max-min+1 buttons
  const pickerMarginLeft = `-${(max - min + 1) / 2 * 3.5 + 0.25}rem`
  return (
    <>
      <div className={classes(small ? 'w-8 h-8' : 'w-16 h-16', onChange != null ? 'cursor-pointer' : '', 'flex items-center text-white rounded-full bg-brand-600 dark:bg-brand-600', className)} onClick={() => { if (onChange != null) setShowPicker(!showPicker) }}>
        <div className={classes(small ? 'text-[1.25rem]' : 'text-[2.5rem]', 'w-full text-center')}>{value ?? '?'}</div>
      </div>
      <Picker open={showPicker} className='flex flex-row p-2 gap-x-2' style={{ marginLeft: pickerMarginLeft }}>
        {Array.from({ length: max - min + 1 }, (_, i) => min + i).map((i) => (
          <button
            key={i}
            className='flex items-center w-12 h-12 text-white rounded-full bg-brand-600 dark:bg-brand-500'
            onClick={() => {
              if (onChange != null) onChange(i)
              setShowPicker(false)
            }}
          >
            <div className='w-full text-[2rem] text-center'>{i}</div>
          </button>
        ))}
      </Picker>
    </>
  )
}
