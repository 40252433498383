import { fileToDataUrl } from '@andyneville/tailwind-react'
import type { AccountRole, IContinuationResult, IStaffAccount, ITenant, ITenantCheckoutSession, ITenantInput, ITryoutMedia, ITryoutRecording, TenantPlan, TenantRole } from '../../../api/api'
import { apiRootUrl, apiSlice } from './apiSlice'
import { rootLog } from '../logging'
import { omit } from '../util'

const log = rootLog.child({ module: 'tenantsApiSlice' })

// RTK internal
interface PatchCollection {
  undo: () => void
}

export const tenantsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTenants: builder.query<ITenant[], void>({ // eslint-disable-line @typescript-eslint/no-invalid-void-type
      query: () => '/tenants',
      transformResponse: (responseData: IContinuationResult<ITenant>) => {
        return responseData?.items ?? []
      },

      providesTags: (result = [], _error, _arg) =>
        result != null
          ? [...result.map(({ id }) => ({ type: 'Tenant' as const, id })), 'Tenant']
          : ['Tenant']
    }),
    getTenant: builder.query<ITenant | undefined, { tenantId: string }>({
      query: (id: { tenantId: string }) => `/tenants/${id.tenantId}`,
      providesTags: (result, _error, _arg) => [{ type: 'Tenant', id: result?.id }]
    }),
    editTenant: builder.mutation({
      query: (tenant: Partial<ITenantInput> & { id: string }) => ({
        url: `/tenants/${tenant.id}`,
        method: 'PUT',
        body: tenant
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'Tenant', id: result?.id }]
    }),
    addLogo: builder.mutation<ITryoutRecording, { tenantId: string, file: File }>({
      query: (input: { tenantId: string, file: File }) => ({
        url: `/tenants/${input.tenantId}/logo`,
        method: 'POST',
        body: { mimeType: input.file.type }
      }),
      async onQueryStarted (input: { tenantId: string, file: File }, { dispatch, queryFulfilled }) {
        let patchResult: PatchCollection | undefined
        try {
          log.debug('addLogo - posting /logo')
          const result = await queryFulfilled
          const logoUploadResult = result.data as unknown as ITryoutMedia
          log.debug('addLogo - /tryoutVideo id', logoUploadResult.id)
          const logoInMemoryUrl = await fileToDataUrl(input.file)
          const authHeader = (result?.meta as { request?: Request })?.request?.headers.get('Authorization')
          log.debug('addLogo - meta', authHeader)
          log.debug('addLogo - logoInMemoryUrl', logoInMemoryUrl?.length)
          // ;(meta as unknown as { tempRecording: ITryoutRecording }).tempRecording = temporaryRecording
          patchResult = dispatch(
            tenantsApiSlice.util.updateQueryData('getTenant', { tenantId: input.tenantId }, (draft) => {
              log.debug('patch-1')
              if (draft == null) {
                log.debug('addLogo - draft is null')
                return
              }
              draft.logo = logoInMemoryUrl
              log.debug('addLogo - drafted temp tag')
            })
          )
          log.debug('addLogo - uploading file', logoUploadResult.uploadUrl, input.file.size)
          const uploadResult = await fetch(logoUploadResult.uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': input.file.type
              // 'Content-Length': newTryout.recording.size.toString(),
            },
            body: input.file
          })
          if (uploadResult.status !== 200) {
            log.debug('addLogo - upload failed', uploadResult)
            if (patchResult != null) {
              patchResult.undo()
            }
          }
          if (logoUploadResult.downloadUrl != null && logoUploadResult.downloadUrl !== '') {
            log.debug('addLogo - updating tenant', logoUploadResult.uploadUrl, input.file.size, authHeader)
            await fetch(`${apiRootUrl}/tenants/${input.tenantId}`, {
              method: 'PUT',
              headers: {
                Authorization: authHeader ?? '',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                logo: logoUploadResult.downloadUrl
              })
            })
          } else {
            log.debug('addLogo - no download url')
          }
        } catch (err) {
          log.debug('addLogo - exception', err)
          if (patchResult != null) {
            patchResult.undo()
          }

          dispatch(tenantsApiSlice.util.invalidateTags(['Tenant']))
        }
      }
    }),
    completeOnboarding: builder.mutation<ITenant, { tenantId: string }>({
      query: (input: { tenantId: string }) => ({
        url: `/tenants/${input.tenantId}/completeOnboarding`,
        method: 'POST'
      }),
      invalidatesTags: (result, _error, _arg) => ['Tenant', { type: 'Tenant', id: result?.id }]
      /* async onQueryStarted (input: { tenantId: string }, { dispatch, queryFulfilled }) {
        let patchResult: PatchCollection | undefined
        try {
          log.debug('completeOnboarding - postin')
          const { data: tenant } = await queryFulfilled
          patchResult = dispatch(
            tenantsApiSlice.util.updateQueryData('getTenant', { tenantId: input.tenantId }, () => {
              log.debug('patch-1')
              return tenant
            })
          )
          patchResult = dispatch(
            tenantsApiSlice.util.updateQueryData('getTenants', undefined, (draft) => {
              log.debug('patch-2')
              if (draft != null) {
                const tenantIndex = draft.findIndex((t) => t.id === input.tenantId)
                if (tenantIndex !== -1) {
                  draft[tenantIndex] = tenant
                }
              }
            })
          )
        } catch (err) {
          log.debug('addLogo - exception', err)
          if (patchResult != null) {
            patchResult.undo()
          }

          dispatch(tenantsApiSlice.util.invalidateTags(['Tenant']))
        }
      } */
    }),
    startCheckout: builder.mutation<ITenantCheckoutSession, { tenantId: string, plan: TenantPlan }>({
      query: (input: { tenantId: string, plan: TenantPlan }) => ({
        url: `/tenants/${input.tenantId}/startCheckout`,
        method: 'POST',
        body: { plan: input.plan }
      })
    }),
    getTenantAccounts: builder.query<IStaffAccount[], { tenantId: string }>({
      query: (input: { tenantId: string }) => `/tenants/${input.tenantId}/accounts`,
      // transformResponse: (responseData: List) => {
      //   return responseData ? unmarshalList(responseData) : undefined
      // },
      providesTags: (result = [], _error, arg) => [
        'AccountTenant',
        ...result.map((staffAccount) => ({ type: 'AccountTenant' as const, id: `${staffAccount?.id}#${arg.tenantId}` }))
      ]
    }),
    addTenantAccount: builder.mutation<IStaffAccount, { tenantId: string, email: string }>({
      query: (input: { tenantId: string, email: string }) => ({
        url: `/tenants/${input.tenantId}/accounts/${input.email}`,
        method: 'POST',
        body: omit(input, 'tenantId', 'accountId')
      }),
      invalidatesTags: (result, _error, arg) => ['AccountTenant', { type: 'AccountTenant', id: `${result?.id}#${arg.tenantId}` }]
    }),
    editTenantAccount: builder.mutation<IStaffAccount, { tenantId: string, accountId: string, roles: Array<TenantRole | AccountRole> }>({
      query: (input: { tenantId: string, accountId: string, roles: Array<TenantRole | AccountRole> }) => ({
        url: `/tenants/${input.tenantId}/accounts/${input.accountId}`,
        method: 'PUT',
        body: { roles: input.roles }
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'AccountTenant', id: `${arg.accountId}#${arg.tenantId}` }]
    }),
    deleteTenantAccount: builder.mutation({
      query: (id: { tenantId: string, accountId: string }) => ({
        url: `/tenants/${id.tenantId}/accounts/${id.accountId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, arg) => ['AccountTenant', { type: 'AccountTenant', id: `${arg.accountId}#${arg.tenantId}` }]
    })
  })
})

export const {
  useGetTenantsQuery,
  useGetTenantQuery,
  useEditTenantMutation,
  useAddLogoMutation,
  useCompleteOnboardingMutation,
  useStartCheckoutMutation,
  useGetTenantAccountsQuery,
  useAddTenantAccountMutation,
  useEditTenantAccountMutation,
  useDeleteTenantAccountMutation
} = tenantsApiSlice
