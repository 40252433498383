import { type ForwardRefRenderFunction, type ForwardedRef, type ReactElement, createElement, forwardRef } from 'react'

type IconSVGProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & React.RefAttributes<SVGSVGElement>
type IconProps = IconSVGProps & {
  title?: string
  titleId?: string
}

// const ParentIcon: React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & IconProps & React.RefAttributes<SVGSVGElement> = (props: IconProps, svgRef: ForwardedRef<HTMLInputElement>): ReactElement => {
const ParentIcon: ForwardRefRenderFunction<SVGSVGElement, IconProps> = (props: IconProps, svgRef: ForwardedRef<SVGSVGElement>): ReactElement => {
  const { title, titleId } = props
  return /* #__PURE__ */ createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'currentColor',
        viewBox: '0 0 100 100',
        strokeWidth: 1,
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId
      },
      props
    ),
    title != null
      ? /* #__PURE__ */ createElement('title', { id: titleId }, title)
      : null,
    /* #__PURE__ */ createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'm42.504 81.992c-0.003906 1.7344 0.43359 3.4375 1.2656 4.957h-31.57c-3.9727-0.011719-7.1875-3.2266-7.1992-7.1992v-10.801c0.015625-4.7695 1.9141-9.3398 5.2891-12.711 3.3711-3.375 7.9414-5.2734 12.711-5.2891h31.137c0.66016 2.207 1.7344 4.2656 3.168 6.0664-4.1406 0.71094-7.8945 2.8594-10.602 6.0703-2.707 3.207-4.1953 7.2734-4.1992 11.473zm-3.3047-36.441c-4.8789 0-9.5586-1.9375-13.008-5.3867-3.4492-3.4492-5.3867-8.1289-5.3867-13.008s1.9375-9.5586 5.3867-13.008c3.4492-3.4492 8.1289-5.3867 13.008-5.3867 4.8789 0 9.5586 1.9375 13.008 5.3867 3.4492 3.4492 5.3867 8.1289 5.3867 13.008s-1.9375 9.5586-5.3867 13.008c-3.4492 3.4492-8.1289 5.3867-13.008 5.3867zm55.801 36.441c-0.007812 2.7344-2.2227 4.9492-4.957 4.957h-37.184c-2.7344-0.007813-4.9492-2.2227-4.957-4.957v-7.4336c0.019531-6.8398 5.5586-12.375 12.395-12.395h22.309c6.8359 0.019532 12.375 5.5547 12.395 12.395zm-10.883-36.215c0 5.125-3.0859 9.7422-7.8203 11.703-4.7344 1.957-10.18 0.875-13.801-2.75-3.625-3.6211-4.707-9.0703-2.7461-13.801 1.9609-4.7344 6.5781-7.8164 11.703-7.8164 3.3594 0 6.5781 1.332 8.9531 3.707 2.375 2.3789 3.7109 5.5977 3.7109 8.957z'
    })
  )
}
const ForwardRef = forwardRef(ParentIcon)
export default ForwardRef
