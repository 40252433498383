import { Button, Dialog, GlobalDialogContext } from '@andyneville/tailwind-react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { type ReactElement, useContext } from 'react'
import { ClientSyncContext } from './ClientSyncProvider'

export interface LogsDialogProps {
  open: boolean
  onClose: () => void
}

export function LogsDialog (props: LogsDialogProps): ReactElement {
  const { open, onClose } = props
  const { isCollectingLogs, setIsCollectingLogs, getLogs, haveLogs, clearLogs } = useContext(ClientSyncContext)

  const globalDialog = useContext(GlobalDialogContext)

  const doStart = (): void => {
    void setIsCollectingLogs(true)
    globalDialog.popupInfo('Logs Collection Started', 'Logs are now being collected. Please return to this page to retrieve the logs as directed by our support team.')
    onClose()
  }

  const doStop = (): void => {
    void setIsCollectingLogs(false)
  }

  const doClear = (): void => {
    void clearLogs()
  }

  const doGetLogs = (): void => {
    void (async (): Promise<void> => {
      const logs = await getLogs()
      const url = URL.createObjectURL(logs)
      const a = document.createElement('a')
      a.href = url
      a.download = 'cheersync-logs.txt'
      a.click()
      URL.revokeObjectURL(url)
    })()
  }

  return (
    <Dialog title='Troubleshooting Logs' Icon={QuestionMarkCircleIcon} open={open} onClose={onClose} buttons={
      <>
        <Button label='Close' onClick={onClose} />
      </>
    }>
      <div className='grid gap-2 py-2'>
        {isCollectingLogs
          ? (
            <>
              <p>Logs are being collected. After encountering any issues, please return here and click the button below to stop collecting logs for troubleshooting.</p>
              <Button label='Stop Logs Collection' onClick={doStop} />
            </>
            )
          : haveLogs
            ? (
              <>
                <p>Logs have been collected. Please click the button below to download the log file, and attach it to an email to <a href="mailto:support@cheersync.app">support@cheersync.app</a>.</p>
                <Button label='Download Logs Collection' onClick={doGetLogs} />
                <Button label='Restart Logs Collection' onClick={doStart} />
                <Button label='Clear Logs' onClick={doClear} />
              </>
              )
            : (
                <>
                  <p>Logs are not currently being collected. Click the button below to start collecting logs for troubleshooting.</p>
                  <Button label='Start Logs Collection' onClick={doStart} />
                </>
              )
        }
      </div>

    </Dialog>
  )
}
