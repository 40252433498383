import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { type ReactElement } from 'react'

interface SortableTableHeaderProps {
  name: string
  sorted?: number
  srOnly?: boolean
  compact?: boolean
  className?: string
}

function classNames (...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

export default function SortableTableHeader (props: SortableTableHeaderProps): ReactElement {
  const { name, sorted = 0, srOnly = false, className = '', compact = false } = props
  const classes = classNames(
    'py-3.5 px-2 text-left whitespace-nowrap text-sm font-semibold text-gray-900 dark:text-white sm:pl-0',
    compact ? '' : 'pr-3',
    className
  )
  return (
    <th scope="col" className={classes} >
      {srOnly
        ? (
            <span className="sr-only">Edit</span>
          )
        : (
            <a href="#" className="inline-flex group">
              {name}
              {sorted === 0 &&
                <span className="flex-none invisible ml-2 text-gray-400 rounded dark:text-gray-100 group-hover:visible group-focus:visible">
                  <ChevronDownIcon
                    className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                    aria-hidden="true"
                  />
                </span>
              }
              {sorted > 0 &&
                <span className="flex-none ml-2 text-gray-900 rounded dark:text-gray-100 group-hover:bg-gray-200 dark:group-hover:bg-gray-600">
                  <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
                </span>
              }
              {sorted < 0 &&
                <span className="flex-none ml-2 text-gray-900 rounded dark:text-gray-100 group-hover:bg-gray-200 dark:group-hover:bg-gray-600">
                  <ChevronUpIcon className="w-5 h-5" aria-hidden="true" />
                </span>
              }
            </a>
          )
      }
    </th>
  )
}
