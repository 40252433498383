import { configureStore } from '@reduxjs/toolkit'
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import authReducer, { setApiRootUrl, setGoogleAuthUri, setGoogleClientId } from './reducers/authReducer'
import captchaReducer from './reducers/captchaReducer'
import { apiSlice } from './reducers/apiSlice'
import seasonReducer from './reducers/seasonReducer'
import { rootLog } from './logging'

const log = rootLog.child({ module: 'store' })

setApiRootUrl(import.meta.env.VITE_API_ROOT_URL as string)
setGoogleAuthUri(import.meta.env.VITE_GOOGLE_AUTH_URI as string)
setGoogleClientId(import.meta.env.VITE_GOOGLE_CLIENT_ID as string)

log.debug('store.ts loaded')
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    captcha: captchaReducer,
    season: seasonReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
})
log.debug('store.ts configured')

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type RootGetStateFn = () => RootState

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function getPersistedValue (key: string, defaultValue: string): string {
  try {
    return localStorage.getItem(key) ?? defaultValue
  } catch {
    return defaultValue
  }
}

export function setPersistedValue (key: string, value: string): void {
  try {
    localStorage.setItem(key, value)
  } catch { }
}
