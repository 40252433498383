import { Button, Dialog, Form, TextBox, Mask, classes } from '@andyneville/tailwind-react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useState, type ReactElement } from 'react'
import { GoogleSheetImportError, type IGoogleSheetImportPreviewError } from '../../../api/api'
import { useDoExportMutation } from '../reducers/apiSlice-seasons'
import { useSelector } from 'react-redux'
import { type RootState } from '../store'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'ExportDialog' })

export interface UnboundExportDialogProps {
  open: boolean
  isExporting: boolean
  error?: GoogleSheetImportError
  onClose: (updated?: boolean) => void
  onExport?: (url: string) => void
  exportComplete?: boolean
}

function errorDescription (error: GoogleSheetImportError): string {
  switch (error) {
    case GoogleSheetImportError.InvalidUrl:
      return 'An invalid URL was provided. Please provide a valid Google Sheets URL.'
    case GoogleSheetImportError.InvalidSheet:
      return 'The Google Sheets document is invalid. Please ensure the document is shared with anyone with the link.'
    case GoogleSheetImportError.InvalidHeaders:
      return 'The Google Sheets document is invalid. Please ensure the document has a header row with at least these three required headers: firstName, lastName, birthYear'
    case GoogleSheetImportError.NoAccess:
      return 'The Google Sheets document is not accessible. Please ensure the document is shared so that with anyone with the link can EDIT it.'
    case GoogleSheetImportError.NoAthletes:
      return 'The Google Sheets document does not contain any athletes.'
    case GoogleSheetImportError.NoHeaders:
      return 'The Google Sheets document does not contain any headers. Please ensure the document has the correct header row.'
    case GoogleSheetImportError.Internal:
      return 'An internal error occurred. Please try again later.'
    default:
      return 'An unknown error occurred.'
  }
}

export function UnboundExportDialog (props: UnboundExportDialogProps): ReactElement {
  const { open, onClose, error, onExport, isExporting, exportComplete } = props
  const [sheetUrl, setSheetUrl] = useState('')

  const googleSheetUrlRegex = /https:\/\/docs.google.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/

  const doExport = (): void => {
    if (!isValidSheetUrl()) {
      return
    }
    const match = googleSheetUrlRegex.exec(sheetUrl)
    log.debug('match', match)
    const sheetId = match?.[1]
    if (onExport != null && sheetId != null) {
      onExport(sheetId)
    }
  }

  const isValidSheetUrl = (): boolean => googleSheetUrlRegex.test(sheetUrl)

  return (
    <Dialog title='Export Athletes' Icon={ArrowDownTrayIcon} open={open} onClose={() => { setSheetUrl(''); onClose() }} buttons={
      <>
        {exportComplete !== true &&
          <Button label='Export' primary disabled={!isValidSheetUrl()} onClick={doExport} />
        }
        {exportComplete !== true &&
          <Button label='Cancel' onClick={() => { setSheetUrl(''); onClose() }} />
        }
        {exportComplete === true &&
          <Button label='Close' onClick={() => { log.debug('click close', exportComplete); setSheetUrl(''); onClose(exportComplete) }} />
        }
      </>
    }>
      <Mask show={isExporting} message='Exporting...'>
        <Form className='py-2'>
          <TextBox columns={12} label='Google Sheets URL' value={sheetUrl} onChange={setSheetUrl} invalid={sheetUrl.length > 0 && !isValidSheetUrl()} />
          <div className={classes('mt-4 sm:col-span-12', isExporting ? 'opacity-50' : '')}>
            Paste a link to a Google Sheets document which is shared with permissions for anyone to
            edit.
          </div>
        </Form>
        {error != null && error !== GoogleSheetImportError.Success &&
          <div className='text-red-600 dark:text-red-500'>{errorDescription(error)}</div>
        }
        {exportComplete === true && error != null && error === GoogleSheetImportError.Success &&
          <div className='text-green-600 dark:text-green-500 text-lg'>Export Complete</div>
        }
      </Mask>
    </Dialog>
  )
}

export interface ExportDialogProps {
  open: boolean
  onClose: (updated: boolean) => void
}

export default function ExportDialog (props: ExportDialogProps): ReactElement {
  const { currentSeasonId } = useSelector((state: RootState) => state.season)
  const { open, onClose } = props
  const [exportComplete, setExportComplete] = useState(false)
  const [processedComplete, setProcessedComplete] = useState(false)
  const [
    doExport,
    {
      data: exportResult,
      isSuccess: isExportSuccess,
      isError: isExportError,
      isLoading: isExportLoading
    }
  ] = useDoExportMutation()

  const importError: GoogleSheetImportError | undefined = (exportResult as unknown as IGoogleSheetImportPreviewError)?.error ?? undefined

  const onExport = (sheetId: string): void => {
    if (currentSeasonId == null) {
      log.debug('currentSeasonId is null')
      return
    }
    log.debug('doImport', sheetId)
    setProcessedComplete(false)
    void doExport({ seasonId: currentSeasonId, sheetId })
  }

  if (isExportSuccess && !processedComplete && !isExportError && !isExportLoading && !exportComplete && importError === GoogleSheetImportError.Success) {
    log.debug('import success')
    setExportComplete(true)
    setProcessedComplete(true)
    if (onClose != null) {
      // onClose(true)
    }
  }

  return (
    <UnboundExportDialog
      open={open}
      exportComplete={exportComplete}
      onClose={(updated) => { setExportComplete(false); onClose(updated ?? false) }}
      onExport={onExport}
      error={importError}
      isExporting={isExportLoading}
    />
  )
}
