import { Button, Dialog, Form, Mask, classes } from '@andyneville/tailwind-react'
import { CheckCircleIcon, StopCircleIcon, TrashIcon, VideoCameraIcon } from '@heroicons/react/24/outline'
import { useRef, type ReactElement, useState, useEffect, useCallback, useContext } from 'react'
import { rootLog } from '../logging'
import { db } from '../db'
import { ClientSyncContext } from './ClientSyncProvider'

const log = rootLog.child({ module: 'RecordVideoDialog' })

export interface RecordVideoDialogProps {
  tenantId: string
  athleteId: string
  seasonId: string
  tryoutId: string
  open: boolean
  onClose?: () => void
}

function createThumb (video: HTMLVideoElement): string | null {
  const c = document.createElement('canvas')
  const w = video.videoWidth
  const h = video.videoHeight
  log.debug('createThumb', video, w, h, c)
  const ctx = c.getContext('2d')
  log.debug('ctx', ctx)
  c.width = w
  c.height = h
  if (ctx != null) {
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, w, h)
    log.debug('drawImage', video, video.videoWidth, video.videoHeight, w, h)
    const dataUrl = c.toDataURL('image/jpeg', 0.8)
    // log.debug('dataUrl', dataUrl)
    return dataUrl
  }
  return null
}

export default function RecordVideoDialog (props: RecordVideoDialogProps): ReactElement {
  const { open, onClose, athleteId, tryoutId, seasonId, tenantId } = props
  const videoElement = useRef<HTMLVideoElement | null>(null)
  const mediaRecorder = useRef<MediaRecorder | null>(null)
  const mediaStream = useRef<MediaStream | null>(null)
  const recordedChunks = useRef<Blob[]>([])
  const [recordVideo, setRecordVideo] = useState(false)
  const [mimeType, setMimeType] = useState('')
  const [tempThumbnail, setTempThumbnail] = useState('')
  const [haveVideo, setHaveVideo] = useState(false)
  const [savedVideo, setSavedVideo] = useState(false)
  const [saving, setSaving] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [previewLoaded, setPreviewLoaded] = useState(false)
  const recordButtonRef = useRef<HTMLButtonElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const clientSyncContext = useContext(ClientSyncContext)

  const videoElementRef = useCallback((value: HTMLVideoElement) => {
    log.debug('RecordVideoDialog useCallback')
    if (value != null) {
      log.debug('useCallback videoElementRef', value)
      if (videoElement.current == null) {
        videoElement.current = value
        setHaveVideo(true)
      }
    } else {
      log.debug('useCallback null')
      videoElement.current = null
      setHaveVideo(false)
    }
  }, [])

  useEffect(() => {
    log.debug('RecordVideoDialog useEffect')
    void navigator.permissions.query({ name: 'camera' as PermissionName }).then((result) => {
      if (result.state === 'granted') {
        log.debug('camera permission has already been granted')
      } else if (result.state === 'prompt') {
        log.debug('camera permission has not been granted yet')
      } else if (result.state === 'denied') {
        log.debug('camera permission has been denied')
      }
      result.onchange = function () {
        log.debug(`Permission ${result.name} state changed to ${result.state}`)
      }
    })
    if (open && haveVideo) {
      if (mediaRecorder.current == null) {
        log.debug('starting preview')
        startPreview()
      }
    } else {
      log.debug('stopping preview', open, haveVideo)
      stopPreview()
    }
  }, [open, haveVideo]) // eslint-disable-line react-hooks/exhaustive-deps

  const startPreview = (): boolean => {
    if (videoElement.current == null) {
      log.debug('videoElement.current is null')
      return false
    }
    if (mediaRecorder.current != null) {
      log.debug('mediaRecorder.current already going')
      return true
    }

    log.debug('startPreview')
    const handleSuccess = function (stream: MediaStream): void {
      if (videoElement.current == null) {
        log.debug('videoElement.current is null')
        return
      }

      mediaStream.current = stream
      const options = {} // { mimeType: 'video/webm' }
      log.debug('new MediaRecorder')
      mediaRecorder.current = new MediaRecorder(stream, options)
      log.debug('mediaRecorder:', mediaRecorder.current)
      videoElement.current.srcObject = stream
      videoElement.current.onloadedmetadata = () => {
        void videoElement.current?.play()
        setPreviewLoaded(true)
      }

      mediaRecorder.current.addEventListener('dataavailable', function (e) {
        log.debug('dataavailable')
        if (e.data.size > 0) {
          recordedChunks.current.push(e.data)
        }
      })

      mediaRecorder.current.addEventListener('start', function (e) {
        log.debug('started', e)
        const mimeType = mediaRecorder.current?.mimeType ?? ''
        setMimeType(mimeType.split(';')[0])
        setTimeout(() => {
          log.debug('snag thumb')
          if (videoElement.current != null) {
            log.debug('getting thumbUrl')
            const thumbUrl = createThumb(videoElement.current)
            if (thumbUrl != null) {
              // log.debug('thumbUrl:', thumbUrl)
              setTempThumbnail(thumbUrl)
            }
          }
        }, 100)
      })

      mediaRecorder.current.addEventListener('stop', function () {
        log.debug('stopped, num chunks:', recordedChunks.current.length)
      })

      mediaRecorder.current.addEventListener('error', function (e) {
        log.debug('mediaRecorder error', e)
      })
    }

    log.debug('Devices:')
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        devices.forEach(function (device) {
          log.debug(device)
          log.debug(device.kind + ': ' + device.label +
            ' id = ' + device.deviceId)
        })
      })
      .catch(function (err) {
        log.debug(err.name + ': ' + err.message)
      })

    log.debug('getUserMedia')
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
          facingMode: 'environment'
          // width: { ideal: 1280 },
          // height: { ideal: 720 }
        }
      }) // video: { width: { exact:  1280} , height: { exact: 720 } },
      .then(handleSuccess).catch(function (error) {
        log.debug('navigator.getUserMedia error: ', error)
      })

    return true
  }

  const stopPreview = (): void => {
    log.debug('stopPreview')
    if (mediaStream.current != null) {
      mediaStream.current.getTracks().forEach(function (track) {
        track.stop()
      })
      mediaStream.current = null
    }
    if (videoElement.current != null) {
      videoElement.current.srcObject = null
    }
    setPreviewLoaded(false)
    if (mediaRecorder.current != null) {
      mediaRecorder.current.stop()
      mediaRecorder.current = null
    }
  }

  const startRecording = (): void => {
    setErrorText('')
    if (videoElement.current == null) {
      log.debug('videoElement.current is null')
      return
    }
    if (mediaRecorder.current == null) {
      log.debug('mediaRecorder.current is null')
      return
    }
    setTempThumbnail('')
    recordedChunks.current.length = 0
    mediaRecorder.current.start()
    setRecordVideo(true)
  }

  const stopRecording = (): void => {
    if (mediaRecorder.current != null) {
      mediaRecorder.current.stop()
      setSavedVideo(true)
    }
    setRecordVideo(false)
  }

  const onSave = async (recording: Blob, videoMimeType: string, thumbnailDataUrl: string, thumbMimeType: string): Promise<boolean> => {
    log.debug('onSave')
    const newRecordingId = await db.recordings.add({
      athleteId,
      tenantId,
      seasonId,
      tryoutId,
      uploadStarted: false,
      uploadCompleted: false,
      uploadVerified: false,
      uploadAttempts: 0,
      createdAt: new Date(),
      video: recording,
      videoMimeType,
      thumbnail: thumbnailDataUrl,
      thumbnailMimeType: thumbMimeType
    })
    if (newRecordingId == null) {
      log.debug('Failed to save recording')
      return false
    }
    log.debug('Saved recording with id', newRecordingId)
    clientSyncContext.videoAdded(newRecordingId)
    return true
  }

  const doSave = async (): Promise<void> => {
    // dispatch(setSaving(true))
    setErrorText('')
    setSaving(true)
    log.debug('addTryoutVideo call:')
    const recordingBlob = new Blob(recordedChunks.current, { type: mimeType })
    if (onSave != null) {
      log.debug('calling onSave')
      const result = await onSave(recordingBlob, mimeType, tempThumbnail, 'image/jpeg')
      if (!result) {
        setErrorText('An error occurred saving the video. Please try again.')
        log.debug('onSave failed, not leaving')
        setSaving(false)
        // setSavedVideo(true)
        return
      }
      log.debug('onSave returned')
    }
    setSaving(false)
    setSavedVideo(false)
  }

  const toggleRecord = (): void => {
    if (recordVideo) {
      stopRecording()
    } else {
      setSavedVideo(false)
      startRecording()
    }
  }

  const doClose = (): void => {
    if (recordVideo) {
      stopRecording()
    }
    setSavedVideo(false)
    if (onClose != null) {
      onClose()
    }
  }

  return (
    <Dialog wide hFull title='Record Video' open={open} onClose={doClose}>
      <Mask show={!previewLoaded} message='Opening Camera...'>
        <Mask show={saving} message='Uploading...'>
          <div ref={wrapperRef} className='h-full flex flex-col items-center w-full gap-2 overflow-hidden text-center justify-items-center'>
            <div className='h-full relative flex-grow overflow-hidden'>
              <video
                className={classes('h-full object-contain', recordVideo ? 'border-4 border-red-600 dark:border-red-500' : '')}
                ref={videoElementRef}
                muted
                playsInline></video>
            </div>
            {errorText !== '' &&
              <div className='flex-row w-full py-1 justify-items-center text-red-500 dark:text-red-500'>
                {errorText}
              </div>
            }
            <div className='flex-row w-full py-1 justify-items-center'>
              <Form noGrid className='items-center' autofocus={recordButtonRef}>
                <Button autofocus ref={recordButtonRef} Icon={recordVideo ? StopCircleIcon : VideoCameraIcon} label={recordVideo ? 'Stop' : savedVideo ? 'Retake' : 'Record'} warning={!savedVideo} onClick={toggleRecord} disabled={saving} />
                {savedVideo &&
                  <Button className='ml-2' Icon={CheckCircleIcon} label='Save' primary onClick={() => { void doSave() }} disabled={saving} />
                }
                <Button className='ml-2' Icon={TrashIcon} onClick={onClose} />
              </Form>
            </div>
          </div>

        </Mask>
      </Mask>
    </Dialog>
  )
}
