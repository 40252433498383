import { type ReactElement, useContext, useState } from 'react'

import { Button, Form, GlobalDialogContext, LoadingComponent, Mask, PageHeader, TextBox } from '@andyneville/tailwind-react'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { type AccountRole, type IStaffAccount, TenantRole } from '../../../api/api'
import { useAddTenantAccountMutation, useDeleteTenantAccountMutation, useEditTenantAccountMutation, useGetTenantAccountsQuery } from '../reducers/apiSlice-tenants'
import { MinusIcon, PlusIcon, TrashIcon, UserIcon } from '@heroicons/react/24/outline'

import { useSelector } from 'react-redux'
import { type RootState } from '../store'
import TableHeader from '../components/TableHeader'
import SortableTableHeader from '../components/SortableTableHeader'
import TableCell from '../components/TableCell'
import TableActionCell from '../components/TableActionCell'

// const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const emailRegex = /^[^@]+@(?:[^@.]+\.){1,}[a-zA-Z]{2,}$/

export default function Staff (): ReactElement {
  const { currentTenant } = useSelector((state: RootState) => state.auth)
  const { me, tenant } = useContext(TenantSeasonContext)
  const {
    data: accounts = [],
    isLoading: loadingAccounts
    // isSuccess,
    // isError,
    // error
  } = useGetTenantAccountsQuery({ tenantId: tenant?.id ?? '' }, { skip: tenant?.id == null || tenant.id.length === 0 })
  const [updateTenantAccount, { isLoading: updateLoadingTA }] = useEditTenantAccountMutation()
  const [addTenantAccount, { isLoading: addLoadingTA }] = useAddTenantAccountMutation()
  const [deleteTenantAccount, { isLoading: deleteLoadingTA }] = useDeleteTenantAccountMutation()
  const [newAccountEmail, setNewAccountEmail] = useState('')
  const globalDialog = useContext(GlobalDialogContext)

  const setAccountTenantRole = (account: IStaffAccount, roleName: TenantRole | AccountRole, value: boolean): void => {
    const roles = account.roles ?? []
    if (roles.includes(roleName) && !value) {
      void updateTenantAccount({ accountId: account.id, tenantId: currentTenant?.id ?? '', roles: roles.filter((role) => role !== roleName) })
    } else if (!roles.includes(roleName) && value) {
      void updateTenantAccount({ accountId: account.id, tenantId: currentTenant?.id ?? '', roles: [...roles, roleName] })
    }
  }

  const addUser = (): void => {
    if (newAccountEmail !== '') {
      void addTenantAccount({ tenantId: currentTenant?.id ?? '', email: newAccountEmail })
      setNewAccountEmail('')
    }
  }

  const deleteUser = (account: IStaffAccount): void => {
    globalDialog.showWarning('Remove from Organization', `Are you sure you want to remove ${account.name} from the ${currentTenant?.name} organization?`, 'Remove', () => {
      void deleteTenantAccount({ accountId: account.id, tenantId: currentTenant?.id ?? '' })
    })
  }

  return (
    <>
      <PageHeader
        title='Staff'
        subtitle={tenant?.name}
      >
      </PageHeader>
      <LoadingComponent isLoading={loadingAccounts} >
        <Mask show={updateLoadingTA || addLoadingTA || deleteLoadingTA} message='Updating...'>
          <h4>Staff Members</h4>
          {accounts.length === 0
            ? <p>No staff members yet, use the box below to invite them.</p>
            : (
              <table className="divide-y divide-gray-300 dark:divide-gray-500">
                <thead>
                  <tr>
                    <TableHeader name='Image' srOnly />
                    <SortableTableHeader name='Name' sorted={1} />
                    <SortableTableHeader name='Email' sorted={1} />
                    <SortableTableHeader name='Roles' />
                    <TableHeader name='Edit' srOnly />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {accounts.map((account) => (
                    <tr key={account.id}>
                      <TableCell>
                        {account.picture == null
                          ? (
                            <UserIcon className="w-6 h-6 rounded-full" aria-hidden="true" />
                            )
                          : (
                            <img className="w-6 h-6 rounded-full" src={account.picture} alt="" />
                            )
                        }
                      </TableCell>
                      <TableCell emphasize>
                        {account.name}
                      </TableCell>
                      <TableCell emphasize>
                        {account.email}
                      </TableCell>
                      <TableCell>{(account.roles ?? []).join(', ')}</TableCell>
                      <TableActionCell>
                        {account.id !== me?.id && !account.roles?.includes(TenantRole.Owner) &&
                          <div className='flex items-center gap-x-2'>
                            {account.roles?.includes(TenantRole.Manager)
                              ? <Button Icon={MinusIcon} small label='Manager' primary onClick={() => { setAccountTenantRole(account, TenantRole.Manager, false) }} />
                              : <Button Icon={PlusIcon} small label='Manager' primary onClick={() => { setAccountTenantRole(account, TenantRole.Manager, true) }} />
                            }
                            <Button Icon={TrashIcon} transparent onClick={() => { deleteUser(account) }} />
                          </div>
                        }
                      </TableActionCell>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={5} className='text-right'>
                      <span className='text-xs'><i>* Managers can edit staff roles and invite new staff members, as well as change the organization's settings</i></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
              )
          }
          <Form className='mt-8 gap-x-6 gap-y-8'>
            <TextBox id='name' label='Invite Staff Email' columns={4} value={newAccountEmail} onChange={setNewAccountEmail} />
            <Button className='mt-8' label='Add User' primary disabled={!emailRegex.test(newAccountEmail)} onClick={addUser} />
          </Form>
        </Mask>
      </LoadingComponent>
    </>
  )
}
