import { type ReactElement } from 'react'
import { type ISeasonAthleteWithAthlete } from '../../../api/api'
import { Link } from 'react-router-dom'
import { classes } from '@andyneville/tailwind-react'

import unknownUser from '../assets/user.png'
import { levelNames } from '../../../api/ageGrids'

export interface AthleteCardProps {
  athlete: ISeasonAthleteWithAthlete
  showAgeGroups?: Record<string, boolean | undefined>
  preview?: boolean
}

export default function AthleteCard (props: AthleteCardProps): ReactElement {
  const {
    athlete,
    showAgeGroups = {},
    preview = false
  } = props

  const getStateStyle = (): string => {
    if (athlete == null) return ''
    if (athlete.totalRating != null && athlete.totalRating > 0 &&
      athlete.positions != null && athlete.positions.length > 0 &&
      athlete.levels != null && athlete.levels.length > 0
    ) {
      return 'bg-green-200 divide-y divide-gray-200 dark:bg-green-600/50'
    }
    if ((athlete.totalRating != null && athlete.totalRating > 0) ||
      (athlete.positions != null && athlete.positions.length > 0) ||
      (athlete.levels != null && athlete.levels.length > 0)
    ) {
      return 'bg-yellow-200 divide-y divide-gray-200 dark:bg-yellow-600/50'
    }
    return 'bg-white divide-y divide-gray-200 dark:bg-dark-600'
  }

  const WrapperElement = preview ? 'div' : Link
  return (
    <li className={classes('col-span-1  rounded-lg shadow', getStateStyle())}>
      <WrapperElement to={athlete != null ? `/tryout/${athlete.id}` : ''}>
        <div className="flex items-center justify-between w-full p-4 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="w-full text-base font-medium text-gray-900 truncate">{athlete.firstName} {athlete.lastName}</h3>
              <div className='inline-block text-sm text-right text-gray-900 dark:text-white'>{athlete.birthYear}</div>
            </div>
            <p className="mt-1 text-sm text-gray-500 truncate">
              {/* <span className='inline-block w-12 text-gray-900 dark:text-white'>{athlete.birthYear}</span> */}
              {/* athlete.position && athlete.position.join(', ') */}
              {(athlete.levels ?? athlete.levelsRequested ?? []).length > 0 &&
                <span className={classes('mr-2 inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20')}>
                  L{(athlete.levels ?? athlete.levelsRequested ?? []).map((sl) => (levelNames[sl] ?? 'L').substring(1)).join('/')}
                </span>
              }
              {athlete.ageGroups != null && athlete.ageGroups.length > 0 && athlete.ageGroups.map((ageGroup, ageGroupIdx) => (
                <span key={ageGroupIdx} className={classes('mr-2 inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', showAgeGroups[ageGroup] !== false ? 'bg-gray-50 text-gray-700 ring-gray-600/20' : 'bg-gray-200/60 text-gray-800/60 ring-gray-600/20')}>
                  {ageGroup}
                </span>
              ))}
              {athlete.totalRating != null && athlete.totalRating > 0 &&
                <span className="mr-2 inline-flex justify-items-end flex-grow items-center rounded-full px-1.5 py-0.5 text-xs font-medium text-white bg-green-600 dark:bg-green-600">
                  {athlete.totalRating ?? '?'}
                </span>
              }
            </p>
          </div>
          <img className="flex-shrink-0 object-cover w-16 h-16 bg-gray-300 rounded-full" src={athlete.headshotUrl ?? unknownUser} alt="" />
        </div>
      </WrapperElement>
    </li>
  )
}
