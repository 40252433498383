import { useState, type ReactElement, useContext, useMemo } from 'react'
import { useDeleteAdminAccountMutation, useGetAdminAccountsQuery } from '../../reducers/apiSlice-admin-accounts'
import { TrashIcon, UserIcon } from '@heroicons/react/24/outline'
import { GlobalDialogContext, PageHeader, LoadingComponent, Button, TextBox } from '@andyneville/tailwind-react'
import { Link } from 'react-router-dom'
import TableHeader from '../../components/TableHeader'
import SortableTableHeader from '../../components/SortableTableHeader'
import TableCell from '../../components/TableCell'
import TableActionCell from '../../components/TableActionCell'
import AddUserDialog from '../../components/AddUserDialog'
import { AccountRole, type IAccount } from '../../../../api/api'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { type RootState } from '../../store'

export type CallbackFn = () => void

export default function AdminUsers (): ReactElement {
  const { roles: myRoles } = useSelector((state: RootState) => state.auth)
  const {
    data: accounts = [],
    isLoading,
    // isSuccess,
    isError,
    error
  } = useGetAdminAccountsQuery()
  const [showAddUser, setShowAddUser] = useState(false)
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteAdminAccountMutation()
  const globalDialog = useContext(GlobalDialogContext)
  const [filter, setFilter] = useState('')

  const doDeleteUser = (account: IAccount): void => {
    globalDialog.showWarning('Delete User', `Are you sure you want to delete the user ${account.name}?`, 'Delete User', () => {
      void deleteUser({ accountId: account.id })
    })
  }

  const filteredAccounts = useMemo(() => {
    if (filter === '') {
      return accounts
    }
    return accounts.filter((account) => {
      if (account?.name != null && account.name.toLowerCase().includes(filter.toLowerCase())) return true
      if (account?.firstName != null && account.firstName.toLowerCase().includes(filter.toLowerCase())) return true
      if (account?.lastName != null && account.lastName.toLowerCase().includes(filter.toLowerCase())) return true
      if (account?.email != null && account.email.toLowerCase().includes(filter.toLowerCase())) return true
      return false
    })
  }, [accounts, filter])

  // <div className="max-h-[600px] overflow-y-auto">
  return (
    <div className="">
      <PageHeader title='Users' subtitle='A list of all the users in the system.'>
        <Button label='Add User' primary onClick={() => { setShowAddUser(true) }} />
        <div className='flex items-center pl-4 twc-form'>
          <div className='mr-2 text-sm'>Filter:</div>
          <TextBox value={filter} onChange={setFilter} />
        </div>
      </PageHeader>
      <AddUserDialog open={showAddUser} onClose={() => { setShowAddUser(false) }} />
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <LoadingComponent isLoading={isLoading || deleteLoading} isError={isError} error={error} isEmpty={accounts.length === 0}>
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
                <thead>
                  <tr>
                    <TableHeader name='Image' srOnly/>
                    <SortableTableHeader name='Name' sorted={1} />
                    <SortableTableHeader name='Email' />
                    <SortableTableHeader name='Last Active' />
                    <SortableTableHeader name='Affiliate' />
                    {myRoles.includes(AccountRole.SuperAdministrator) &&
                      <SortableTableHeader name='Role' />
                    }
                    <TableHeader name='Edit' srOnly />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {filteredAccounts.map((account) => (
                    <tr key={account.id} className={!account.active ? 'line-through' : 'no'}>
                      <TableCell>
                        {account.picture == null
                          ? (
                              <UserIcon className="w-6 h-6 rounded-full" aria-hidden="true" />
                            )
                          : (
                              <img className="w-6 h-6 rounded-full" src={account.picture} alt="" />
                            )
                        }
                      </TableCell>
                      <TableCell emphasize>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/users/${account.id}`}>{account.name}</Link>
                      </TableCell>
                      <TableCell>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/users/${account.id}`}>{account.email}</Link>
                      </TableCell>
                      <TableCell>{account.lastActive != null ? DateTime.fromJSDate(new Date(account.lastActive as unknown as string)).toLocaleString(DateTime.DATETIME_SHORT) : ''}</TableCell>
                      <TableCell>{account.affiliate ?? ''}</TableCell>
                      {myRoles.includes(AccountRole.SuperAdministrator) &&
                        <TableCell>{(account.roles ?? []).join(', ')}</TableCell>
                      }
                      <TableActionCell>
                        <div className='flex items-center'>
                          {myRoles.includes(AccountRole.SuperAdministrator) &&
                            <Button Icon={TrashIcon} transparent onClick={() => { doDeleteUser(account) }} />
                          }
                        </div>
                      </TableActionCell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </LoadingComponent>
          </div>
        </div>
      </div>
    </div>
  )
}
