import { Button, DatePickerBox, Dialog, Form, Mask, SelectBox, TextBox } from '@andyneville/tailwind-react'
import { useState, type ReactElement, useRef } from 'react'
import { useNavigate } from 'react-router'
import { TenantPlan, TenantRole, TenantStatus } from '../../../api/api'
import { useAddAdminTenantAccountMutation, useAddAdminTenantMutation } from '../reducers/apiSlice-admin-tenants'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'AddTenantDialog' })

export interface AddOrganizationDialogProps {
  accountId?: string
  open: boolean
  onClose: () => void
}

const organizationStatuses = [
  { id: TenantStatus.Onboarding, name: 'Onboarding' },
  { id: TenantStatus.Active, name: 'Active' },
  { id: TenantStatus.Expired, name: 'Expired' }
]

const organizationPlans = [
  { id: TenantPlan.New, name: 'New Account' },
  { id: TenantPlan.Trial, name: 'Trial' },
  { id: TenantPlan.Starter, name: 'Starter' },
  { id: TenantPlan.Premium, name: 'Premium' },
  { id: TenantPlan.Unlimited, name: 'Unlimited' }
]

export default function AddOrganizationDialog (props: AddOrganizationDialogProps): ReactElement {
  const { open, onClose, accountId } = props
  const [name, setName] = useState('')
  const [status, setStatus] = useState('')
  const [plan, setPlan] = useState('')
  const [expiration, setExpiration] = useState<Date | null>(null)
  const nameBox = useRef<HTMLInputElement | null>(null)
  const [addTenant, { isLoading: addingTenant }] = useAddAdminTenantMutation()
  const [addTenantAccount, { isLoading: addingTenantAccount }] = useAddAdminTenantAccountMutation()
  const navigate = useNavigate()

  const newUser = async (): Promise<void> => {
    log.debug('new tenant')
    try {
      const tenant = await addTenant({
        name,
        status: status as TenantStatus,
        plan: plan as TenantPlan,
        expiration: expiration as unknown as Date
      }).unwrap()
      if (tenant != null && accountId != null) {
        log.debug('new tenant', tenant)
        const tenantAccount = await addTenantAccount({
          tenantId: tenant.id,
          accountId,
          roles: [TenantRole.Owner]
        }).unwrap()
        if (tenantAccount != null) {
          navigate(`/admin/organizations/${tenant.id}`)
          onClose()
        }
      } else if (tenant != null) {
        navigate(`/admin/organizations/${tenant.id}`)
        onClose()
      }
    } catch (e) {
      log.error('Error adding tenant', e)
    }
  }

  const isValid = name.length > 0 && status.length > 0 && plan.length > 0

  return (
    <Dialog
      title='Add New Organization'
      open={open}
      onClose={onClose}
      buttons={
        <>
          <Button disabled={!isValid} primary onClick={() => { void newUser() }}>Add</Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <Mask show={addingTenant || addingTenantAccount} message='Adding...'>
        <Form autofocus={nameBox}>
          <TextBox ref={nameBox} columns={6} autofocus label='Organization Name' value={name} onChange={setName} />
          <DatePickerBox label='Expiration' showClear columns={6} value={expiration} onChange={(value) => { setExpiration(value ?? null) }} />
          <SelectBox label='Status' columns={6} value={organizationStatuses.find(s => s.id === status)} options={organizationStatuses} onChange={(value) => { setStatus(value?.id ?? '') }} />
          <SelectBox label='Plan' columns={6} value={organizationPlans.find(p => p.id === plan)} options={organizationPlans} onChange={(value) => { setPlan(value?.id ?? '') }} />
          <div className='col-span-12 mb-8'></div>
        </Form>
      </Mask>
    </Dialog>
  )
}
