import { type ReactElement } from 'react'

import { PageHeader } from '@andyneville/tailwind-react'
import { clear as clearSeason } from '../reducers/seasonReducer'
import unknownLogo from '../assets/logo2.png'
import { useSelector } from 'react-redux'
import { type RootState, useAppDispatch } from '../store'
import { switchTenant } from '../reducers/authReducer'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'ChooseTenant' })

function TenantButton (props: { name: string, logo?: string, onClick: () => void }): ReactElement {
  const { name, logo, onClick } = props
  return (
    <a className='flex flex-row items-center w-full px-4 py-2 border rounded-lg cursor-pointer border-brand-600 bg-brand-50 dark:border-brand-400 dark:bg-brand-600/30 hover:bg-brand-200 hover:dark:bg-brand-600/50' onClick={onClick}>
      <img src={logo ?? unknownLogo} alt={name} className='w-6 h-6' />
      <h3 className='ml-2 text-lg'>{name}</h3>
    </a>
  )
}

export default function ChooseTenant (): ReactElement {
  const { tenants } = useSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()

  log.debug('ChooseTenant: tenants', tenants)

  const onChooseTenant = (tenantId: string): void => {
    log.debug('setting current tenant to ', tenantId)
    dispatch(clearSeason())
    void dispatch(switchTenant(tenantId))
  }

  return (
    <>
      <PageHeader
        title='Choose Organization'
      >
      </PageHeader>
      <div className='grid gap-2 py-2'>
        {tenants.map((tenant) => (
          <TenantButton key={tenant.id} name={tenant.name} logo={tenant.logo} onClick={() => { onChooseTenant(tenant.id) }} />
        ))}
      </div>
    </>
  )
}
