import { type ReactElement } from 'react'

interface TableHeaderProps {
  name: string
  srOnly?: boolean
}

export default function SortableTableHeader (props: TableHeaderProps): ReactElement {
  const { name, srOnly = false } = props
  return (
    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0">
      {srOnly
        ? (
            <span className="sr-only">Edit</span>
          )
        : (
            <a href="#" className="inline-flex group">
              {name}
            </a>
          )
      }
    </th>
  )
}
