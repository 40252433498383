import type { IContinuationResult, IGoogleSheetImportPreview, IGoogleSheetImportPreviewError, ISeason, ISeasonInput } from '../../../api/api'
import { apiSlice } from './apiSlice'

export const seasonsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSeasons: builder.query<ISeason[], void>({ // eslint-disable-line @typescript-eslint/no-invalid-void-type
      query: () => '/seasons',
      transformResponse: (responseData: IContinuationResult<ISeason>) => {
        return responseData?.items ?? []
      },

      providesTags: (result = [], _error, _arg) =>
        result != null
          ? [...result.map(({ id }) => ({ type: 'Season' as const, id })), 'Season']
          : ['Season']
    }),
    getSeason: builder.query<ISeason | undefined, { seasonId: string }>({
      query: (id: { seasonId: string }) => `/seasons/${id.seasonId}`,
      providesTags: (result, _error, _arg) => [{ type: 'Season', id: result?.id }]
    }),
    addSeason: builder.mutation<ISeason, ISeasonInput>({
      query: (newSeason: ISeasonInput) => ({
        url: '/seasons',
        method: 'POST',
        body: newSeason
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'Season', id: result?.id }]
    }),
    editSeason: builder.mutation({
      query: (season: Partial<ISeasonInput> & { id: string }) => ({
        url: `/seasons/${season.id}`,
        method: 'PUT',
        body: season
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'Season', id: result?.id }]
    }),
    deleteSeason: builder.mutation({
      query: (id: { seasonId: string }) => ({
        url: `/seasons/${id.seasonId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Season', id: arg.seasonId }]
    }),
    previewImport: builder.mutation<IGoogleSheetImportPreview | IGoogleSheetImportPreviewError, { sheetId: string, sheetName?: string }>({ // eslint-disable-line @typescript-eslint/no-invalid-void-type
      query: (props: { sheetId: string, sheetName?: string }) => ({
        url: `/seasons/import?sheetId=${props.sheetId}${props.sheetName != null && props.sheetName !== '' ? `&sheetName=${props.sheetName}` : ''}`,
        method: 'GET'
      })
    }),
    doImport: builder.mutation<IGoogleSheetImportPreview | IGoogleSheetImportPreviewError, { seasonId: string, sheetId: string, sheetName?: string }>({
      query: (props: { seasonId: string, sheetId: string, sheetName?: string }) => ({
        url: `/seasons/${props.seasonId}/import?sheetId=${props.sheetId}${props.sheetName != null && props.sheetName !== '' ? `&sheetName=${props.sheetName}` : ''}`,
        method: 'POST'
      }),
      invalidatesTags: (_result, _error, _arg) => ['Account', 'Season']
    }),
    doExport: builder.mutation<IGoogleSheetImportPreviewError, { seasonId: string, sheetId: string, separateSheetPerTeam?: boolean }>({
      query: (props: { seasonId: string, sheetId: string, separateSheetPerTeam?: boolean }) => ({
        url: `/seasons/${props.seasonId}/export?sheetId=${props.sheetId}${props.separateSheetPerTeam === true ? '&separateSheetPerTeam=true' : ''}`,
        method: 'POST'
      })
    })
  })
})

export const {
  useGetSeasonsQuery,
  useGetSeasonQuery,
  useAddSeasonMutation,
  useEditSeasonMutation,
  useDeleteSeasonMutation,
  usePreviewImportMutation,
  useDoImportMutation,
  useDoExportMutation
} = seasonsApiSlice
