import { type ThunkDispatch, type UnknownAction, createSlice } from '@reduxjs/toolkit'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'captchaReducer' })

export interface CaptchaState {
  ready: boolean
  loading: boolean
  token: string | null
  hasToken: boolean
}

type DispatchType = ThunkDispatch<{ captcha: CaptchaState }, void, UnknownAction>
type GetStateFnType = () => { captcha: CaptchaState }

const captchaSlice = createSlice({
  name: 'captcha',
  initialState: {
    ready: false,
    loading: false,
    token: null as string | null,
    hasToken: false
  },
  reducers: {
    setReady (state) {
      log.debug('Captcha loaded...')
      state.ready = true
    },
    setLoading (state) {
      log.debug('Captcha initialized...')
      state.loading = true
    },
    setToken (state, action) {
      log.debug('Captcha token set...')
      state.token = action.payload
      state.hasToken = true
    }
  }
})

const { actions, reducer } = captchaSlice
const { setLoading, setReady } = actions
export const { setToken } = actions
export default reducer

type ThunkFunction<TReturn> = (dispatch: DispatchType, getState: GetStateFnType) => Promise<TReturn>

export function initializeCaptcha (): ThunkFunction<void> {
  log.debug('initializeCaptcha')
  return async function (dispatch: DispatchType, getState: GetStateFnType): Promise<void> {
    log.debug('initializeCaptchaThunk')
    const { captcha } = getState()
    if (!captcha.ready && !captcha.loading) {
      await new Promise<void>((resolve) => {
        (globalThis as unknown as { onCaptchaLoaded: () => void }).onCaptchaLoaded = () => {
          dispatch(setReady())
          resolve()
        }
        const script = document.createElement('script')
        script.src = 'https://www.google.com/recaptcha/api.js?onload=onCaptchaLoaded&render=explicit'
        script.async = true
        script.defer = true
        document.body.appendChild(script)
        dispatch(setLoading())
      })
    }
  }
}
