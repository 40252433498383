import { classes } from '@andyneville/tailwind-react'
import { type PropsWithChildren, type ReactElement, useContext } from 'react'
import { SortableContext } from './SortableProvider'

export interface DraggableProps {
  as?: 'div' | 'li'
  id: string
  className?: string
  onClick?: () => void
}

export default function Draggable (props: PropsWithChildren<DraggableProps>): ReactElement {
  const sortable = useContext(SortableContext)
  const { children, id, as: ElementType = 'div', className, onClick = (sortable?.onClick == null) ? undefined : () => { if (sortable.onClick != null) sortable.onClick(props.id) } } = props

  return (
    <ElementType id={id} className={classes(sortable?.draggableClass, 'draggable', className)} onClick={onClick}>
      {children}
    </ElementType>
  )
}
