import { Combobox, Transition } from '@headlessui/react'
import { Fragment, useMemo, useState, type ReactElement } from 'react'
import { type ISeasonAthleteWithAthlete } from '../../../api/api'

import unknownUser from '../assets/user.png'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export interface AthleteSearchBoxProps {
  athletes: ISeasonAthleteWithAthlete[]
  onSelect: (athlete: ISeasonAthleteWithAthlete) => void
}

export default function AthleteSearchBox (props: AthleteSearchBoxProps): ReactElement {
  const { athletes, onSelect } = props
  const [selected, setSelected] = useState('')
  const [query, setQuery] = useState('')

  const mappedAthletes = useMemo(() => {
    if (athletes == null || athletes.length === 0) {
      return []
    }
    return athletes.map((athlete) => {
      return {
        id: athlete.id,
        name: athlete.firstName + ' ' + athlete.lastName,
        headshotUrl: athlete.headshotUrl,
        birthYear: athlete.birthYear
      }
    })
  }, [athletes])

  const filteredAthletes =
    query === ''
      ? mappedAthletes
      : mappedAthletes.filter((athlete) => {
        return athlete.name.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <Combobox value={selected} onChange={(value) => { console.log('selected', value); setSelected(''); onSelect(value as unknown as ISeasonAthleteWithAthlete) }}>
      <div className="relative mt-1 col-span-12 ">
          <div className='flex items-center twc-pseudoinput'>
            <MagnifyingGlassIcon className='w-5 h-5 mr-2 text-gray-500 dark:text-dark-300' />
            <Combobox.Input
              className="twc-inner-input"
              displayValue={(athlete: { name: string }) => athlete.name}
              onChange={(event) => { setQuery(event.target.value); console.log('onChange', event.target.value) }}
            />
          </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => { setQuery('') }}
        >
          <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white dark:bg-dark-700 rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredAthletes != null && filteredAthletes.length === 0 && query !== ''
              ? (
                <div className="relative px-4 py-2 text-gray-700 dark:text-white cursor-default select-none">
                  Nothing found.
                </div>
                )
              : (filteredAthletes.map((athlete) => (
                <Combobox.Option
                  key={athlete.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-4 pr-4 ${active ? 'bg-brand-600 text-white' : 'text-gray-900 dark:text-white'
                    }`
                  }
                  value={athlete}
                >
                  <div className="flex items-center">
                    <img className="flex-shrink-0 w-6 h-6 rounded-full" src={athlete.headshotUrl ?? unknownUser} alt="" />
                    <div className='ml-2'>{athlete.name} ({athlete.birthYear})</div>
                  </div>
                </Combobox.Option>
                ))
                )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
