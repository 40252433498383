import { createSlice } from '@reduxjs/toolkit'
import { type ISeason } from '../../../api/api'
import { rootLog } from '../logging'

export interface SeasonStateType {
  currentSeasonId?: string
  currentSeason?: ISeason
}

const log = rootLog.child({ module: 'seasonReducer' })

let persistedSeason: ISeason | undefined

try {
  const persistedSeasonStorage = localStorage.getItem('season')
  log.debug('seasonReducer: persistedSeasonStorage', persistedSeasonStorage)
  if (persistedSeasonStorage !== null) {
    try {
      persistedSeason = JSON.parse(persistedSeasonStorage)
    } catch (e) {
      log.error('app load init: error parsing persisted season profile')
      log.error(e)
    }
  }
} catch (e) {
  log.error('app load init: error reading persisted season profile')
  log.error(e)
}

const seasonSlice = createSlice({
  name: 'season',
  initialState: {
    currentSeasonId: persistedSeason?.id ?? undefined,
    currentSeason: persistedSeason
  } satisfies SeasonStateType,
  reducers: {
    set (state, action) {
      const currentSeason = action.payload
      log.debug('seasonReducer: set currentSeason', currentSeason)
      state.currentSeason = currentSeason
      state.currentSeasonId = currentSeason?.id ?? undefined
      try {
        if (currentSeason != null) {
          localStorage.setItem('season', JSON.stringify(currentSeason))
        } else {
          localStorage.removeItem('season')
        }
      } catch (e) {
        log.error('error saving season to local storage')
        log.error(e)
      }
    },
    clear (state) {
      log.debug('seasonReducer: clear currentSeasonId')
      state.currentSeasonId = undefined
      state.currentSeason = undefined
      try {
        localStorage.removeItem('season')
      } catch (e) {
        log.error('error clearing season from local storage')
        log.error(e)
      }
    }
  }
})

const { actions, reducer } = seasonSlice
export const { set, clear } = actions
export default reducer
