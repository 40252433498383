export interface JwtTokenBase {
  iss?: string /* issuer */
  sub?: string /* subject */
  iat?: number /* issued at (seconds since epoch) */
  exp?: number /* expiration (seconds since epoch) */
  jti?: string /* JWT ID */
}

export type JwtToken = JwtTokenBase & Record<string, unknown>

export class InvalidTokenError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'InvalidTokenError'
  }
}

export class InvalidSignatureError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'InvalidSignatureError'
  }
}

function decodePart (tokenPart: string): Record<string, unknown> {
  return JSON.parse(atob(tokenPart))
}

export function decode (token: string): JwtToken {
  if (typeof token !== 'string') {
    throw new InvalidTokenError('Invalid token specified: must be a string')
  }

  const parts = token.split('.')
  if (parts.length !== 3) {
    throw new InvalidTokenError('Invalid token specified: must have 3 parts')
  }

  const header = decodePart(parts[0])
  const payload = decodePart(parts[1]) as JwtToken
  if (payload.header != null) {
    throw new InvalidTokenError(
      'Invalid token specified: unexpected header field in payload'
    )
  }
  payload.header = header

  return payload
}
