import { type ForwardRefRenderFunction, type ForwardedRef, type ReactElement, createElement, forwardRef } from 'react'

type IconSVGProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & React.RefAttributes<SVGSVGElement>
type IconProps = IconSVGProps & {
  title?: string
  titleId?: string
}

// const ParentIcon: React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & IconProps & React.RefAttributes<SVGSVGElement> = (props: IconProps, svgRef: ForwardedRef<HTMLInputElement>): ReactElement => {
const SwitchIcon: ForwardRefRenderFunction<SVGSVGElement, IconProps> = (props: IconProps, svgRef: ForwardedRef<SVGSVGElement>): ReactElement => {
  const { title, titleId } = props
  return /* #__PURE__ */ createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'currentColor',
        viewBox: '0 0 512 512',
        strokeWidth: 1,
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId
      },
      props
    ),
    title != null
      ? /* #__PURE__ */ createElement('title', { id: titleId }, title)
      : null,
    /* #__PURE__ */ createElement('path', {
      d: 'M425 31l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H352c-12.6 0-24.4 5.9-32 16l-46 61.3-30-40 37.6-50.1C298.2 117 324.3 104 352 104h78.1L391 65c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM204 322.7l-37.6 50.1C149.8 395 123.7 408 96 408H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H96c12.6 0 24.4-5.9 32-16l46-61.3 30 40zM391 287c9.4-9.4 24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H352c-27.7 0-53.8-13-70.4-35.2L128 168c-7.6-10.1-19.4-16-32-16H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H96c27.7 0 53.8 13 70.4 35.2L320 344c7.6 10.1 19.4 16 32 16h78.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9z'
    })
  )
}
const ForwardRef = forwardRef(SwitchIcon)
export default ForwardRef
