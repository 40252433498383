import { Button, Dialog, Form, TextBox } from '@andyneville/tailwind-react'
import { useState, type ReactElement, useRef, useEffect } from 'react'
import { useEditTeamMutation, useGetTeamQuery } from '../reducers/apiSlice-teams'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'EditTeamDialog' })

export interface EditTeamDialogProps {
  seasonId: string
  teamId: string
  open: boolean
  onClose: () => void
}

export default function EditTeamDialog (props: EditTeamDialogProps): ReactElement {
  const { seasonId, teamId, open, onClose } = props
  const [name, setName] = useState('')
  const nameBox = useRef<HTMLInputElement | null>(null)
  const { data: team } = useGetTeamQuery({ seasonId, teamId })

  useEffect(() => {
    if (team != null) {
      setName(team.name)
    }
  }, [team])

  const [editTeam] = useEditTeamMutation()

  const onEdit = (): void => {
    log.debug('Updating team', { name, seasonId })
    const doAdd = async (): Promise<void> => {
      log.debug('Updating team', { name, seasonId, teamId })
      const result = await editTeam({ name, seasonId, teamId })
      log.debug('result', result)
    }
    void doAdd()
    onClose()
  }

  const isValid = name.length > 0
  return (
    <Dialog
      title='Edit Team'
      open={open}
      onClose={onClose}
      buttons={
        <>
          <Button disabled={!isValid} primary onClick={onEdit}>Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <Form autofocus={nameBox}>
        <TextBox ref={nameBox} columns={12} autofocus label='Team Name' value={name} onChange={setName} />
      </Form>
    </Dialog>
  )
}
