import type { IContinuationResult, ITeamAthlete, ITeamAthleteInput } from '../../../api/api'
import { apiSlice } from './apiSlice'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'teamAthletesApiSlice' })

// RTK internal
interface PatchCollection {
  undo: () => void
}

export const teamAthletesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTeamAthletes: builder.query<ITeamAthlete[], { seasonId: string, teamId: string }>({
      query: (id: { seasonId: string, teamId: string }) => `/seasons/${id.seasonId}/teams/${id.teamId}/athletes`,
      transformResponse: (responseData: IContinuationResult<ITeamAthlete>) => {
        return responseData?.items ?? []
      },

      providesTags: (result = [], _error, _arg) =>
        result != null
          ? [...result.map(({ athleteId, teamId }) => ({ type: 'TeamAthlete' as const, id: `${teamId}#${athleteId}` })), 'TeamAthlete']
          : ['TeamAthlete']
    }),
    getTeamAthlete: builder.query<ITeamAthlete | undefined, { seasonId: string, teamId: string, athleteId: string }>({
      query: (id: { seasonId: string, teamId: string, athleteId: string }) => `/seasons/${id.seasonId}/teams/${id.teamId}`,
      providesTags: (result, _error, _arg) => [{ type: 'TeamAthlete', id: `${result?.teamId}#${result?.athleteId}` }]
    }),
    addTeamAthlete: builder.mutation<ITeamAthlete, ITeamAthleteInput & { seasonId: string, teamId: string, athleteId: string }>({
      query: (input: ITeamAthleteInput & { seasonId: string, teamId: string, athleteId: string }) => ({
        url: `/seasons/${input.seasonId}/teams/${input.teamId}/athletes/${input.athleteId}`,
        method: 'POST',
        body: input
      }),
      async onQueryStarted (input: ITeamAthleteInput & { seasonId: string, teamId: string, athleteId: string }, { dispatch, queryFulfilled }) {
        let patchResult: PatchCollection | undefined
        try {
          log.debug('addTeamAthlete - dispatching')
          patchResult = dispatch(
            teamAthletesApiSlice.util.updateQueryData('getTeamAthletes', { seasonId: input.seasonId, teamId: input.teamId }, (draft) => {
              if (draft == null) {
                log.debug('addTeamAthlete - draft is null')
                return
              }
              draft.push(input as ITeamAthlete)
              log.debug('addTeamAthlete - drafted temp tag')
            })
          )
          log.debug('addTeamAthlete - posting')
          const result = await queryFulfilled
          const teamAthleteResult = result.data
          log.debug('addTeamAthlete - posted', teamAthleteResult.teamId, teamAthleteResult.athleteId)
        } catch (err) {
          log.debug('addTeamAthlete - exception', err)
          if (patchResult != null) {
            patchResult.undo()
          }

          dispatch(teamAthletesApiSlice.util.invalidateTags(['TeamAthlete']))
        }
      }
      // invalidatesTags: (_result, _error, _arg) => ['TeamAthlete']
    }),
    editTeamAthlete: builder.mutation({
      query: (team: Partial<ITeamAthleteInput> & { seasonId: string, teamId: string, athleteId: string }) => ({
        url: `/seasons/${team.seasonId}/teams/${team.teamId}/athletes/${team.athleteId}`,
        method: 'PUT',
        body: team
      }),
      async onQueryStarted (input: Partial<ITeamAthleteInput> & { seasonId: string, teamId: string, athleteId: string }, { dispatch, queryFulfilled }) {
        let patchResult: PatchCollection | undefined
        log.debug('editTeamAthlete - posting')
        try {
          patchResult = dispatch(
            teamAthletesApiSlice.util.updateQueryData('getTeamAthletes', { seasonId: input.seasonId, teamId: input.teamId }, (draft) => {
              if (draft == null) {
                log.debug('editTeamAthlete - draft is null')
                return
              }
              draft = draft.map((ta) => ta.teamId === input.teamId && ta.athleteId === input.athleteId ? { ...ta, ...input } : ta)
              log.debug('editTeamAthlete - drafted temp tag')
            })
          )
          log.debug('editTeamAthlete - posting')
          await queryFulfilled
          log.debug('editTeamAthlete - posted')
        } catch (err) {
          log.debug('editTeamAthlete - exception', err)
          if (patchResult != null) {
            patchResult.undo()
          }

          dispatch(teamAthletesApiSlice.util.invalidateTags(['TeamAthlete']))
        }
      }
    // invalidatesTags: (result, _error, _arg) => [{ type: 'TeamAthlete', id: `${result?.teamId}#${result?.athleteId}` }]
    }),
    deleteTeamAthlete: builder.mutation({
      query: (id: { seasonId: string, teamId: string, athleteId: string }) => ({
        url: `/seasons/${id.seasonId}/teams/${id.teamId}/athletes/${id.athleteId}`,
        method: 'DELETE'
      }),
      async onQueryStarted (id: { seasonId: string, teamId: string, athleteId: string }, { dispatch, queryFulfilled }) {
        let patchResult: PatchCollection | undefined
        log.debug('deleteTeamAthlete - posting')
        try {
          patchResult = dispatch(
            teamAthletesApiSlice.util.updateQueryData('getTeamAthletes', { seasonId: id.seasonId, teamId: id.teamId }, (draft) => {
              if (draft == null) {
                log.debug('deleteTeamAthlete - draft is null')
                return
              }
              draft = draft.filter((ta) => !(ta.teamId === id.teamId && ta.athleteId === id.athleteId))
              log.debug('deleteTeamAthlete - drafted temp tag')
            })
          )
          log.debug('deleteTeamAthlete - posting')
          await queryFulfilled
          log.debug('deleteTeamAthlete - posted')
        } catch (err) {
          log.debug('deleteTeamAthlete - exception', err)
          if (patchResult != null) {
            patchResult.undo()
          }

          dispatch(teamAthletesApiSlice.util.invalidateTags(['TeamAthlete']))
        }
      }
      // invalidatesTags: (result, _error, _arg) => [{ type: 'TeamAthlete', id: `${result?.teamId}#${result?.athleteId}` }]
    })
  })
})

export const {
  useGetTeamAthletesQuery,
  useGetTeamAthleteQuery,
  useAddTeamAthleteMutation,
  useEditTeamAthleteMutation,
  useDeleteTeamAthleteMutation
} = teamAthletesApiSlice
