import { fileToDataUrl } from '@andyneville/tailwind-react'
import type { IContinuationResult, IGoogleSheetImportPreview, ISeasonAthleteWithAthlete, ISeasonAthleteWithAthleteInput, ITryoutRecording, ITryoutMedia } from '../../../api/api'
import { apiRootUrl, apiSlice } from './apiSlice'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'athletesApiSlice' })

// RTK internal
interface PatchCollection {
  undo: () => void
}

export const athletesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAthletes: builder.query<ISeasonAthleteWithAthlete[], { seasonId: string }>({
      query: (id: { seasonId: string }) => `/seasons/${id.seasonId}/athletes`,
      transformResponse: (responseData: IContinuationResult<ISeasonAthleteWithAthlete>) => {
        return responseData?.items ?? []
      },

      providesTags: (result = [], _error, _arg) =>
        result != null
          ? [...result.map(({ id }) => ({ type: 'Athlete' as const, id })), 'Athlete']
          : ['Athlete']
    }),
    getAthlete: builder.query<ISeasonAthleteWithAthlete | undefined, { seasonId: string, athleteId: string }>({
      query: (id: { seasonId: string, athleteId: string }) => `/seasons/${id.seasonId}/athletes/${id.athleteId}`,
      providesTags: (result, _error, _arg) => [{ type: 'Athlete', id: result?.id }]
    }),
    addAthlete: builder.mutation<ISeasonAthleteWithAthlete, ISeasonAthleteWithAthleteInput & { seasonId: string }>({
      query: (newAthlete: ISeasonAthleteWithAthleteInput & { seasonId: string }) => ({
        url: `/seasons/${newAthlete.seasonId}/athletes`,
        method: 'POST',
        body: newAthlete
      }),
      invalidatesTags: (_result, _error, _arg) => ['Athlete']
    }),
    editAthlete: builder.mutation({
      query: (athlete: Partial<ISeasonAthleteWithAthlete> & { id: string, seasonId: string }) => ({
        url: `/seasons/${athlete.seasonId}/athletes/${athlete.id}`,
        method: 'PUT',
        body: athlete
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'Athlete', id: result?.id }]
    }),
    deleteAthlete: builder.mutation({
      query: (id: { seasonId: string, athleteId: string }) => ({
        url: `/seasons/${id.seasonId}/athletes/${id.athleteId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Athlete', id: arg.athleteId }]
    }),
    addHeadshot: builder.mutation<ITryoutRecording, { seasonId: string, athleteId: string, file: File }>({
      query: (input: { seasonId: string, athleteId: string, file: File }) => ({
        url: `/seasons/${input.seasonId}/athletes/${input.athleteId}/headshot`,
        method: 'POST',
        body: { mimeType: input.file.type }
      }),
      async onQueryStarted (input: { seasonId: string, athleteId: string, file: File }, { dispatch, queryFulfilled }) {
        let patchResult: PatchCollection | undefined
        try {
          log.debug('addHeadshot - posting /headshot')
          const result = await queryFulfilled
          const headshotUploadResult = result.data as unknown as ITryoutMedia // actually returns this but we're faking a local version
          log.debug('addHeadshot - /tryoutVideo id', headshotUploadResult.id)
          const headshotInMemoryUrl = await fileToDataUrl(input.file)
          const authHeader = (result?.meta as { request?: Request })?.request?.headers.get('Authorization')
          log.debug('addHeadshot - meta', authHeader)
          log.debug('addHeadshot - headshotInMemoryUrl', headshotInMemoryUrl?.length)
          // ;(meta as unknown as { tempRecording: ITryoutRecording }).tempRecording = temporaryRecording
          const patchResult = dispatch(
            athletesApiSlice.util.updateQueryData('getAthlete', { seasonId: input.seasonId, athleteId: input.athleteId }, (draft) => {
              log.debug('patch-1')
              if (draft == null) {
                log.debug('addHeadshot - draft is null')
                return
              }
              draft.headshotUrl = headshotInMemoryUrl
              log.debug('addHeadshot - drafted temp tag')
            })
          )
          log.debug('addHeadshot - uploading file', headshotUploadResult.uploadUrl, input.file.size)
          const uploadResult = await fetch(headshotUploadResult.uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': input.file.type
              // 'Content-Length': newTryout.recording.size.toString(),
            },
            body: input.file
          })
          if (uploadResult.status !== 200) {
            log.debug('addHeadshot - upload failed', uploadResult)
            if (patchResult != null) {
              patchResult.undo()
            }
          }
          if (headshotUploadResult.downloadUrl != null && headshotUploadResult.downloadUrl !== '') {
            log.debug('addHeadshot - updating athlete', headshotUploadResult.uploadUrl, input.file.size, authHeader)
            await fetch(`${apiRootUrl}/seasons/${input.seasonId}/athletes/${input.athleteId}`, {
              method: 'PUT',
              headers: {
                Authorization: authHeader ?? '',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                headshotUrl: headshotUploadResult.downloadUrl
              })
            })
          } else {
            log.debug('addHeadshot - no download url')
          }
        } catch (err) {
          log.debug('addTryoutVideo - exception', err)
          if (patchResult != null) {
            patchResult.undo()
          }

          dispatch(athletesApiSlice.util.invalidateTags(['Athlete']))
        }
      }
    }),
    previewImport: builder.mutation<IGoogleSheetImportPreview | undefined, { sheetId: string, sheetName?: string }>({ // eslint-disable-line @typescript-eslint/no-invalid-void-type
      query: (props: { sheetId: string, sheetName?: string }) => ({
        url: `/seasons/import?sheetId=${props.sheetId}${props.sheetName != null && props.sheetName !== '' ? `&sheetName=${props.sheetName}` : ''}`,
        method: 'GET'
      })
    }),
    doImport: builder.mutation({
      query: (props: { seasonId: string, sheetId: string, sheetName?: string }) => ({
        url: `/seasons/${props.seasonId}/import?sheetId=${props.sheetId}${props.sheetName != null && props.sheetName !== '' ? `&sheetName=${props.sheetName}` : ''}`,
        method: 'POST'
      }),
      invalidatesTags: (_result, _error, _arg) => ['Account', 'Season']
    })
  })
})

export const {
  useGetAthletesQuery,
  useGetAthleteQuery,
  useAddAthleteMutation,
  useEditAthleteMutation,
  useAddHeadshotMutation,
  useDeleteAthleteMutation,
  usePreviewImportMutation,
  useDoImportMutation
} = athletesApiSlice
