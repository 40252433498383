import { type ReactElement, useContext } from 'react'

import { LoadingComponent, classes } from '@andyneville/tailwind-react'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { TenantPlan } from '../../../api/api'
import { useStartCheckoutMutation } from '../reducers/apiSlice-tenants'
import { CheckIcon } from '@heroicons/react/24/outline'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'Plans' })

const plans = [
  {
    name: 'Starter',
    id: TenantPlan.Starter,
    price: '$399',
    description: 'The essentials to get your team up and running.',
    features: ['Up to 99 athletes', 'One video per tryout skill', 'Up to 5 skills per tryout'],
    mostPopular: false
  }, {
    name: 'Premium',
    id: TenantPlan.Premium,
    price: '$499',
    description: 'Get more even more videos and support for larger teams.',
    features: ['Up to 149 athletes', 'Three videos per tryout skill', 'Up to 10 skills per tryout'],
    mostPopular: true
  }, {
    name: 'Unlimited',
    id: TenantPlan.Unlimited,
    price: '$599',
    description: 'An unlimited plan for unlimited flexibility.',
    features: ['Unlimited athletes', 'Unlimited videos per tryout skill', 'Unlimited skills per tryout'],
    mostPopular: false
  }
]

export default function Plans (): ReactElement {
  const { tenant } = useContext(TenantSeasonContext)
  const [startCheckout] = useStartCheckoutMutation()

  const onSelectPlan = async (planId: TenantPlan): Promise<void> => {
    if (tenant != null) {
      try {
        log.debug('starting checkout')
        const checkoutSession = await startCheckout({ tenantId: tenant.id, plan: planId }).unwrap()
        if (checkoutSession?.url == null) {
          log.error('checkoutSession is null or missing parts', checkoutSession)
          return
        }
        log.debug(`redirecting to ${checkoutSession.url}`)
        window.location.href = checkoutSession.url
      } catch (err) {
        log.error('Failed to start checkout', err)
      }
    }
  }

  return (
    <>
      <LoadingComponent isLoading={tenant == null}>
        <div className='px-6 mx-auto mt-4 lg:max-w-5xl xl:max-w-5xl sm:mt-12 lg:px-8'>
          <h1 className='text-3xl text-center md:mt-8'>Plans for teams of all sizes</h1>
          <div className="grid max-w-md grid-cols-1 gap-8 mx-auto mt-10 isolate md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none lg:grid-cols-3">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={classes(
                  plan.mostPopular ? 'ring-2 ring-brand-600 dark:ring-brand-500' : 'ring-1 ring-gray-400 dark:ring-dark-400',
                  'rounded-3xl p-8 bg-white dark:bg-dark-700'
                )}
              >
                <h2
                  id={plan.id}
                  className={classes(
                    plan.mostPopular ? 'text-brand-600 dark:text-brand-500' : 'text-gray-900 dark:text-white',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {plan.name}
                </h2>
                <p className="mt-4 text-sm leading-6 text-gray-600 dark:text-dark-300">{plan.description}</p>
                <p className="flex items-baseline mt-6 gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white">{plan.price}</span>
                  <span className="text-sm font-semibold leading-6 text-gray-600 dark:text-dark-300">/year</span>
                </p>
                <a
                  onClick={() => { void onSelectPlan(plan.id as TenantPlan) }}
                  aria-describedby={plan.id}
                  className={classes(
                    plan.mostPopular
                      ? 'bg-brand-600 dark:bg-brand-600 text-white dark:text-white shadow-sm hover:bg-brand-500 dark:hover:bg-brand-500 hover:text-white dark:hover:text-white'
                      : 'text-brand-600 dark:text-brand-600 ring-1 ring-inset ring-brand-200 dark:ring-brand-400 hover:ring-brand-500 hover:bg-brand-500 dark:hover:bg-brand-500 hover:text-white dark:hover:text-white',
                    'cursor-pointer mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600'
                  )}
                >
                  Buy plan
                </a>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 dark:text-dark-300">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="flex-none w-5 h-6 text-brand-600 dark:text-brand-500" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </LoadingComponent>
    </>
  )
}
