import { useState, type ReactElement, useContext, useEffect, useMemo } from 'react'
import AthleteMiniCard from './AthleteMiniCard'
import { Button, classes, GlobalDialogContext } from '@andyneville/tailwind-react'
import SortableList, { type DraggableItem } from './SortableList'
import { Position, type ISeasonAthleteWithAthlete, type ITeamAthlete } from '../../../api/api'
import { ArrowUpIcon, ArrowDownIcon, PencilIcon, TrashIcon, ChevronDoubleDownIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import EditTeamDialog from './EditTeamDialog'
import { useDeleteTeamMutation } from '../reducers/apiSlice-teams'
import { useAddTeamAthleteMutation, useDeleteTeamAthleteMutation, useEditTeamAthleteMutation, useGetTeamAthletesQuery } from '../reducers/apiSlice-teamAthletes'
import { ListOperation } from './SortableOperation'
import { rootLog } from '../logging'
import { rtkErrorToString } from '../util'
import { TenantSeasonContext } from './TenantSeasonProvider'
import { type AgeGridId, ageGroupsFromBirthYear } from '../../../api/ageGrids'

const log = rootLog.child({ module: 'Team' })

export interface TeamProps {
  seasonId: string
  athletes: ISeasonAthleteWithAthlete[]
  id: string
  editable?: boolean
  className?: string
  name: string
  showAgeGroups?: Record<string, boolean | undefined>
  ownership?: string
  onSortUp: () => void
  onSortDown: () => void
  hideUp?: boolean
  hideDown?: boolean
  onAddCrossover?: (athlete: ISeasonAthleteWithAthlete) => void
}

export type ISeasonTeamAthleteWithCrossover = ISeasonAthleteWithAthlete & { crossover?: boolean }

const emptyAthletesArray = [] as ISeasonTeamAthleteWithCrossover[]
const emptyTeamAthletesArray = [] as ITeamAthlete[]

export default function Team (props: TeamProps): ReactElement {
  const { className, name, ownership, showAgeGroups = {}, id, seasonId, athletes: rawAthletes, onSortUp, onSortDown, hideUp = false, hideDown = false, editable = false, onAddCrossover } = props
  const { selectedAgeGridOption } = useContext(TenantSeasonContext)
  const [flyers, setFlyers] = useState<ISeasonTeamAthleteWithCrossover[]>(emptyAthletesArray)
  const [bases, setBases] = useState<ISeasonTeamAthleteWithCrossover[]>(emptyAthletesArray)
  const [backSpots, setBackSpots] = useState<ISeasonTeamAthleteWithCrossover[]>(emptyAthletesArray)
  const [tumblers, setTumblers] = useState<ISeasonTeamAthleteWithCrossover[]>(emptyAthletesArray)
  const total = flyers.length + bases.length + backSpots.length + tumblers.length
  const athleteCount = (count: number): string => count > 0 ? ` (${count} athlete${count > 1 ? 's' : ''})` : ''
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [showCollapsed, setShowCollapsed] = useState(false)
  const [deleteTeam, { error: deleteTeamError }] = useDeleteTeamMutation()
  const [addTeamAthlete, { error: addTeamAthleteError }] = useAddTeamAthleteMutation()
  const [editTeamAthlete, { error: editTeamAthleteError }] = useEditTeamAthleteMutation()
  const [deleteTeamAthlete, { error: deleteTeamAthleteError }] = useDeleteTeamAthleteMutation()
  const globalDialog = useContext(GlobalDialogContext)
  const {
    data: teamAthletes = emptyTeamAthletesArray,
    error: teamAthletesError
  } = useGetTeamAthletesQuery({ seasonId, teamId: id }, { skip: id == null })

  const athletes = useMemo(() => {
    console.log('a selectedAgeGridOption', selectedAgeGridOption.id)
    return rawAthletes.map((athlete) =>
      Object.assign({}, athlete, { ageGroups: ageGroupsFromBirthYear(selectedAgeGridOption.id as AgeGridId, athlete.birthYear) })
    )
  }, [rawAthletes, selectedAgeGridOption])
  // const teamAthletes = useMemo(() => {
  //   console.log('ta selectedAgeGridOption', selectedAgeGridOption.id)
  //   return rawTeamAthletes.map((athlete) =>
  //     Object.assign({}, athlete, { ageGroups: ageGroupsFromBirthYear(selectedAgeGridOption.id as AgeGridId, athlete.birthYear) })
  //   )
  // }, [rawTeamAthletes, selectedAgeGridOption])
  useEffect(() => {
    if (deleteTeamError != null) {
      globalDialog.popupError('Error deleting team', 'There was an error deleting the team: ' + rtkErrorToString(deleteTeamError))
      log.error('Error deleting team', deleteTeamError)
    }
  }, [deleteTeamError, globalDialog])
  useEffect(() => {
    if (addTeamAthleteError != null) {
      globalDialog.popupError('Error adding team athlete', 'There was an error adding the team athlete: ' + rtkErrorToString(addTeamAthleteError))
      log.error('Error adding team athlete', addTeamAthleteError)
    }
  }, [addTeamAthleteError, globalDialog])
  useEffect(() => {
    if (editTeamAthleteError != null) {
      globalDialog.popupError('Error updating team athlete', 'There was an error updating the team athlete: ' + rtkErrorToString(editTeamAthleteError))
      log.error('Error editing team athlete', editTeamAthleteError)
    }
  }, [editTeamAthleteError, globalDialog])
  useEffect(() => {
    if (deleteTeamAthleteError != null) {
      globalDialog.popupError('Error deleting team athlete', 'There was an error deleting the team athlete: ' + rtkErrorToString(deleteTeamAthleteError))
      log.error('Error deleting team athlete', deleteTeamAthleteError)
    }
  }, [deleteTeamAthleteError, globalDialog])
  useEffect(() => {
    if (teamAthletesError != null) {
      globalDialog.popupError('Error loading team athletes', 'There was an error loading the team athletes: ' + rtkErrorToString(teamAthletesError))
      log.error('Error loading team athletes', teamAthletesError)
    }
  }, [teamAthletesError, globalDialog])

  useEffect(() => {
    if (athletes.length > 0 && teamAthletes.length > 0) {
      const flyers = athletes.filter((athlete) => teamAthletes.some((teamAthlete) => teamAthlete.athleteId === athlete.id && teamAthlete.position === Position.Flyer)).map((athlete) => ({ ...athlete, crossover: teamAthletes.some((teamAthlete) => teamAthlete.athleteId === athlete.id && teamAthlete.crossover) }))
      const bases = athletes.filter((athlete) => teamAthletes.some((teamAthlete) => teamAthlete.athleteId === athlete.id && teamAthlete.position === Position.Base)).map((athlete) => ({ ...athlete, crossover: teamAthletes.some((teamAthlete) => teamAthlete.athleteId === athlete.id && teamAthlete.crossover) }))
      const backSpots = athletes.filter((athlete) => teamAthletes.some((teamAthlete) => teamAthlete.athleteId === athlete.id && teamAthlete.position === Position.Backspot)).map((athlete) => ({ ...athlete, crossover: teamAthletes.some((teamAthlete) => teamAthlete.athleteId === athlete.id && teamAthlete.crossover) }))
      const tumblers = athletes.filter((athlete) => teamAthletes.some((teamAthlete) => teamAthlete.athleteId === athlete.id && teamAthlete.position === Position.Tumbler)).map((athlete) => ({ ...athlete, crossover: teamAthletes.some((teamAthlete) => teamAthlete.athleteId === athlete.id && teamAthlete.crossover) }))
      setFlyers(flyers)
      setBases(bases)
      setBackSpots(backSpots)
      setTumblers(tumblers)
    }
  }, [athletes, teamAthletes])

  const onDeleteTeam = (): void => {
    log.debug('Delete team', id)
    globalDialog.showWarning('Delete Team', 'Are you sure you want to delete this team?', 'Delete Team', () => {
      log.debug('Deleting team', id)
      void deleteTeam({ seasonId, teamId: id })
    })
  }

  const setItems = (position: Position, oldItems: ISeasonTeamAthleteWithCrossover[], newItems: ISeasonTeamAthleteWithCrossover[], setItems: React.Dispatch<React.SetStateAction<ISeasonAthleteWithAthlete[]>>, itemId: string, fromListId: string, toListId: string, operation: ListOperation): void => {
    if (operation === ListOperation.Reorder) {
      log.debug('Reordering athlete (noop)')
      // currently a noop
    } else if (operation === ListOperation.Add) {
      const addedItems = newItems.filter((newItem) => !oldItems.some((oldItem) => oldItem.id === newItem.id))
      if (addedItems.length === 0) {
        log.error('WARNING: No items added')
      } else if (addedItems.length > 1) {
        log.error('WARNING: More than one item added', addedItems)
      } else if (addedItems[0].id !== itemId) {
        log.error('WARNING: Wrong item added', addedItems[0], itemId)
      } else {
        const [fromListIdRoot] = fromListId.split(':')
        if (fromListIdRoot !== id) {
          log.debug('Adding athlete', addedItems[0], 'to team', id, 'position', position, 'from', fromListIdRoot)
          log.debug('Adding athlete', addedItems[0].id, 'to team', id, 'position', position, 'from', fromListIdRoot)
          // added from another team
          void addTeamAthlete({ seasonId, teamId: id, athleteId: addedItems[0].id, position, crossover: addedItems[0].crossover })
        } else {
          log.debug('Moving athlete', addedItems[0].id, 'on team', id, 'to position', position, 'from another position on team', fromListId)
          // moved from another position on same team
          void editTeamAthlete({ seasonId, teamId: id, athleteId: addedItems[0].id, position, crossover: addedItems[0].crossover })
        }
      }
    } else if (operation === ListOperation.Remove) {
      const removedItems = oldItems.filter((oldItem) => !newItems.some((newItem) => oldItem.id === newItem.id))
      if (removedItems.length === 0) {
        log.error('WARNING: No items removed')
      } else if (removedItems.length > 1) {
        log.error('WARNING: More than one item removed', removedItems)
      } else if (removedItems[0].id !== itemId) {
        log.error('WARNING: Wrong item removed', removedItems[0], itemId)
      } else {
        const [toListIdRoot] = toListId.split(':')
        if (toListIdRoot !== id) {
          log.debug('Removing athlete', removedItems[0].id, 'from team', id, 'position', position, 'moving to new team/pos', toListId)
          // moving to another team
          void deleteTeamAthlete({ seasonId, teamId: id, athleteId: removedItems[0].id })
        } else {
          // moving to another position on same team, we'll let the subsequent add handle it
          log.debug('Removing athlete', removedItems[0].id, 'from team', id, 'position', position, 'to same team, pos', toListId)
        }
      }
    } else {
      log.error('Unknown operation', operation)
    }
    setItems(newItems)
  }

  const onRemoveCrossover = async (athlete: ISeasonAthleteWithAthlete, teamId: string, items: ISeasonTeamAthleteWithCrossover[], setItems: React.Dispatch<React.SetStateAction<ISeasonTeamAthleteWithCrossover[]>>): Promise<void> => {
    await deleteTeamAthlete({ seasonId, teamId, athleteId: athlete.id })
    setItems(items.filter((item) => item.id !== athlete.id))
  }

  return (
    <div className={classes('overflow-hidden mb-2 bg-white divide-y divide-gray-200 rounded-lg shadow dark:bg-dark-700 dark:divide-gray-600', className)}>
      <EditTeamDialog seasonId={seasonId} teamId={id} open={showEditDialog} onClose={() => { setShowEditDialog(false) }} />
      <div className="relative flex items-center px-4 py-2 gap-x-2 sm:px-6">
        <div className={classes('absolute top-0 right-0 px-2 lg:px-4 pt-0 pb-0 text-xs text-white rounded-bl-lg', editable ? 'bg-brand-600 dark:bg-brand-600' : 'bg-gray-400 dark:bg-dark-500')}>{ownership}</div>
        <div className='ml-12 py-4 order-3 flex flex-row items-center gap-x-0'>
          {!hideUp && hideDown && editable &&
            <Button Icon={ArrowUpIcon} small transparent onClick={onSortUp} />
          }
          {!hideUp && !hideDown && editable &&
            <Button Icon={ArrowUpIcon} small transparent className='-mr-6 ml-1 !px-0 sm:px-3' onClick={onSortUp} />
          }
          {!hideDown && editable &&
            <Button Icon={ArrowDownIcon} small transparent onClick={onSortDown} />
          }
          {editable &&
            <Button small onClick={() => { setShowEditDialog(true) }} >
                  <div className='flex items-center'>
                      <PencilIcon className="w-3 h-3 mr-1"/>
                    <div className='hidden sm:block'>Edit Team</div>
                  </div>
            </Button>
          }
          {editable &&
            <Button Icon={TrashIcon} small transparent onClick={onDeleteTeam} />
          }
        </div>
        {showCollapsed
          ? <Button Icon={ChevronDoubleRightIcon} transparent onClick={() => { setShowCollapsed(false) }} />
          : <Button Icon={ChevronDoubleDownIcon} transparent onClick={() => { setShowCollapsed(true) }} />
        }
        <h3 className='py-0 text-base font-light sm:text-2xl sm:font-normal text-wrap order-2 -mr-9'><span>Team: </span>{name} <span className='font-normal'>{athleteCount(total)}</span></h3>
      </div>
      <div className={classes('px-4 pt-2 pb-4 sm:px-6', showCollapsed ? 'hidden' : '')}>
        <div>Flyers:{athleteCount(flyers.length)}</div>
        <SortableList readonly={!editable} items={flyers as unknown as DraggableItem[]} setItems={(items, itemId, fromListId, toListId, operation) => { setItems(Position.Flyer, flyers, items as unknown as ISeasonAthleteWithAthlete[], setFlyers, itemId, fromListId, toListId, operation) }} id={id + ':flyers'}
          className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 team min-h-4"
        >
          {flyers.map((athlete) => (
            <AthleteMiniCard
              key={athlete.id}
              athlete={athlete}
              editable={editable}
              showAgeGroups={showAgeGroups}
              crossover={athlete.crossover}
              canCrossover={true}
              onAddCrossover={onAddCrossover}
              onRemoveCrossover={() => { if (onRemoveCrossover != null) { void onRemoveCrossover(athlete, id, flyers, setFlyers) } }}
            />
          ))}
        </SortableList>
      </div>
      <div className={classes('px-4 pt-2 pb-4 sm:px-6', showCollapsed ? 'hidden' : '')}>
        <div>Bases:{athleteCount(bases.length)}</div>
        <SortableList readonly={!editable} items={bases as unknown as DraggableItem[]} setItems={(items, itemId, fromListId, toListId, operation) => { setItems(Position.Base, bases, items as unknown as ISeasonAthleteWithAthlete[], setBases, itemId, fromListId, toListId, operation) }} id={id + ':bases'}
          className="relative grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 team min-h-4"
        >
          {bases.map((athlete) => (
            <AthleteMiniCard
              key={athlete.id}
              athlete={athlete}
              editable={editable}
              showAgeGroups={showAgeGroups}
              crossover={athlete.crossover}
              canCrossover={true}
              onAddCrossover={onAddCrossover}
              onRemoveCrossover={() => { if (onRemoveCrossover != null) { void onRemoveCrossover(athlete, id, bases, setBases) } }}
            />
          ))}
        </SortableList>
      </div>
      <div className={classes('px-4 pt-2 pb-4 sm:px-6', showCollapsed ? 'hidden' : '')}>
        <div>Backspots:{athleteCount(backSpots.length)}</div>
        <SortableList readonly={!editable} items={backSpots as unknown as DraggableItem[]} setItems={(items, itemId, fromListId, toListId, operation) => { setItems(Position.Backspot, backSpots, items as unknown as ISeasonAthleteWithAthlete[], setBackSpots, itemId, fromListId, toListId, operation) }} id={id + ':backSpots'}
          className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 team min-h-4"
        >
          {backSpots.map((athlete) => (
            <AthleteMiniCard
              key={athlete.id}
              athlete={athlete}
              editable={editable}
              showAgeGroups={showAgeGroups}
              crossover={athlete.crossover}
              canCrossover={true}
              onAddCrossover={onAddCrossover}
              onRemoveCrossover={() => { if (onRemoveCrossover != null) { void onRemoveCrossover(athlete, id, backSpots, setBackSpots) } }}
            />
          ))}
        </SortableList>
      </div>
      <div className={classes('px-4 pt-2 pb-4 sm:px-6', showCollapsed ? 'hidden' : '')}>
        <div>Tumblers:{athleteCount(tumblers.length)}</div>
        <SortableList readonly={!editable} items={tumblers as unknown as DraggableItem[]} setItems={(items, itemId, fromListId, toListId, operation) => { setItems(Position.Tumbler, tumblers, items as unknown as ISeasonAthleteWithAthlete[], setTumblers, itemId, fromListId, toListId, operation) }} id={id + ':tumblers'}
          className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 team min-h-4"
        >
          {tumblers.map((athlete) => (
            <AthleteMiniCard
              key={athlete.id}
              athlete={athlete}
              editable={editable}
              showAgeGroups={showAgeGroups}
              crossover={athlete.crossover}
              canCrossover={true}
              onAddCrossover={onAddCrossover}
              onRemoveCrossover={() => { if (onRemoveCrossover != null) { void onRemoveCrossover(athlete, id, tumblers, setTumblers) } }}
            />
          ))}
        </SortableList>
      </div>

    </div>
  )
}
