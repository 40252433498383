import { Button, Dialog, Form, TextBox } from '@andyneville/tailwind-react'
import { useState, type ReactElement, useRef } from 'react'
import { useAddTeamMutation } from '../reducers/apiSlice-teams'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'AddTeamDialog' })

export interface AddTeamDialogProps {
  seasonId: string
  open: boolean
  onClose: () => void
}

export default function AddTeamDialog (props: AddTeamDialogProps): ReactElement {
  const { seasonId, open, onClose } = props
  const [name, setName] = useState('')
  const nameBox = useRef<HTMLInputElement | null>(null)

  const [addTeam] = useAddTeamMutation()

  const onAdd = (): void => {
    log.debug('Adding team', { name, seasonId })
    const doAdd = async (): Promise<void> => {
      log.debug('Adding team', { name, seasonId })
      const result = await addTeam({ name, seasonId })
      log.debug('result', result)
    }
    void doAdd()
    onClose()
  }

  const isValid = name.length > 0
  return (
    <Dialog
      title='Add Team'
      open={open}
      onClose={onClose}
      buttons={
        <>
          <Button disabled={!isValid} primary onClick={onAdd}>Add</Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <Form autofocus={nameBox}>
        <TextBox ref={nameBox} columns={12} autofocus label='Team Name' value={name} onChange={setName} />
      </Form>
    </Dialog>
  )
}
