import { type Columns, classes, smColumnClass } from '@andyneville/tailwind-react'
import { Switch } from '@headlessui/react'
import { type Dispatch, type SetStateAction, type ReactElement } from 'react'

type BoundState = [boolean, Dispatch<SetStateAction<boolean>>]

export interface ToggleButtonProps {
  value?: boolean
  columns?: Columns
  label?: string
  onChange?: (value: boolean) => void
  binding?: BoundState
  className?: string
  disabled?: boolean
  leftLabel?: string
  rightLabel?: string
  labelClass?: string
  labelHighlightClass?: string
  helpText?: string
  id?: string
  wrapper?: boolean
}

export default function ToggleButton (props: ToggleButtonProps): ReactElement {
  const {
    value,
    onChange,
    columns: initialColumns,
    label,
    id,
    binding,
    className,
    labelClass = 'text-gray-500 dark:text-dark-400',
    labelHighlightClass = 'text-gray-900 dark:text-white',
    disabled = false,
    helpText,
    leftLabel,
    rightLabel,
    wrapper: formWrapper = (initialColumns == null && label == null && helpText == null)
  } = props

  const sideLabels = (leftLabel != null || rightLabel != null)

  const columns = initialColumns ?? 6
  const boundValue = value ?? (binding != null ? binding[0] : undefined)
  const boundOnChange = onChange ?? (binding != null ? binding[1] : undefined)

  const columnClassName = smColumnClass(columns)

  const classNames = classes(
    columnClassName,
    className
  )

  let Toggle = (
    <Switch
      checked={boundValue}
      onChange={(value) => { if (boundOnChange != null && !disabled) boundOnChange(value) }}
      className={classes(
        boundValue === true ? 'bg-brand-600 dark:bg-brand-600' : 'bg-gray-200 dark:bg-dark-600',
        disabled ? 'opacity-50 cursor-not-allowed' : '',
        (!sideLabels && !formWrapper) ? className : undefined,
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-600 dark:focus:ring-brand-500 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classes(
          boundValue === true ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
      </Switch>
  )
  if (sideLabels) {
    Toggle = (
      <div className={classes('flex form-row items-center gap-x-3', !formWrapper ? className : undefined)}>
        {leftLabel != null &&
          <span className={boundValue === false ? labelHighlightClass : labelClass}>{leftLabel}</span>
        }
        {Toggle}
        {rightLabel != null &&
          <span className={boundValue === true ? labelHighlightClass : labelClass}>{rightLabel}</span>
        }
      </div>
    )
  }

  if (initialColumns == null && label == null && helpText == null) {
    return Toggle
  }
  return (
    <div className={classNames}>
      {label != null &&
        <label htmlFor={id}>
          {label}
        </label>
      }
      {Toggle}
      {helpText != null &&
        <label htmlFor={id} className="block">
          {helpText}
        </label>
      }
    </div>
  )
}
