import { type ReactElement } from 'react'

import rufusPicture from '../assets/Rufus404.png'

export default function Rufus404 (): ReactElement {
  return (
    <div className='w-64 h-64 rounded-full sm:w-72 sm:h-72 md:w-96 md:h-96 bg-brand-200'>
      <img className='w-64 h-64 rounded-full sm:w-72 sm:h-72 md:w-96 md:h-96' src={rufusPicture} />
    </div>
  )
}
