import { Button, Dialog, Form, TextBox } from '@andyneville/tailwind-react'
import { useState, type ReactElement, useRef, useMemo } from 'react'
import { useAddAthleteMutation } from '../reducers/apiSlice-athletes'
import { rootLog } from '../logging'
import { birthYearRequiresMidYearRange } from '../../../api/ageGrids'

const log = rootLog.child({ module: 'AddAthleteDialog' })

export interface AddAthleteDialogProps {
  seasonId: string
  open: boolean
  onClose: () => void
}

export default function AddAthleteDialog (props: AddAthleteDialogProps): ReactElement {
  const { seasonId, open, onClose } = props
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birthYear, setBirthYear] = useState('')
  const [midYearValue, setMidYearValue] = useState(0)
  const firstNameBox = useRef<HTMLInputElement | null>(null)

  const [addAthlete] = useAddAthleteMutation()

  const onAdd = (): void => {
    log.debug('Adding athlete', { firstName, lastName, birthYear, seasonId })
    const doAdd = async (): Promise<void> => {
      log.debug('Adding athlete', { firstName, lastName, birthYear, seasonId })
      const lteMay31 = (needMidYearRange && midYearValue === 1) ? true : needMidYearRange ? false : undefined
      const gteJune1 = (needMidYearRange && midYearValue === 2) ? true : needMidYearRange ? false : undefined
      const result = await addAthlete({ firstName, lastName, birthYear: parseInt(birthYear), seasonId, lteMay31, gteJune1 })
      log.debug('result', result)
      setFirstName('')
      setLastName('')
      setBirthYear('')
    }
    void doAdd()
    onClose()
  }

  const needMidYearRange = useMemo(() => {
    if (birthYear !== '') {
      return birthYearRequiresMidYearRange(parseInt(birthYear))
    } else {
      return false
    }
  }, [birthYear])

  const isValid = firstName.length > 0 &&
    lastName.length > 0 &&
    birthYear.length > 0 &&
    !isNaN(parseInt(birthYear)) &&
    parseInt(birthYear) > 1900 &&
    parseInt(birthYear) < (new Date()).getFullYear() &&
    (birthYearRequiresMidYearRange(parseInt(birthYear)) ? (midYearValue === 1 || midYearValue === 2) : true)
  return (
    <Dialog
      title='Add Athlete'
      open={open}
      onClose={onClose}
      buttons={
        <>
          <Button disabled={!isValid} primary onClick={onAdd}>Add</Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <Form autofocus={firstNameBox}>
        <TextBox ref={firstNameBox} autofocus label='First Name' value={firstName} onChange={setFirstName} />
        <TextBox label='Last Name' value={lastName} onChange={setLastName} />
        <TextBox label='Birth Year' value={birthYear} onChange={setBirthYear} />
        {needMidYearRange && (
          <div className='sm:col-span-6'>
            <label>Birth Day</label>
            <fieldset className="">
              <div className="flex items-center">
                <input
                  id='lteMay31'
                  type="radio"
                  checked={midYearValue === 1}
                  onChange={(e) => { if (e.currentTarget.checked) { setMidYearValue(1) } }}
                    className="!py-0"
                />
                <label htmlFor='lteMay31' className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  Jan 1 - May 31
                </label>
              </div>
              <div className="flex items-center -mt-1 ">
                <input
                  id='gteJun1'
                  type="radio"
                  checked={midYearValue === 2}
                  onChange={(e) => { if (e.currentTarget.checked) { setMidYearValue(2) } }}
                  className="!py-0"
                />
                <label htmlFor='lteMay31' className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  Jun 1 - Dec 31
                </label>
                </div>
            </fieldset>
          </div>
        )}
      </Form>
    </Dialog>
  )
}
