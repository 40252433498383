import { useEffect, type ReactElement, useState, type Dispatch, type SetStateAction } from 'react'
import { type RootState, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { initializeCaptcha, setToken } from '../reducers/captchaReducer'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'CaptchaView' })

type BoundState = [string | null, Dispatch<SetStateAction<string | null>>]

export interface CaptchaProps {
  recaptchaSiteKey: string
  binding?: BoundState
  onChange?: (value: string | null) => void
}

export default function Captcha (props: CaptchaProps): ReactElement {
  const { recaptchaSiteKey, binding, onChange } = props
  const { ready, loading, token } = useSelector((state: RootState) => state.captcha)
  const [captchaAttached, setCaptchaAttached] = useState(false)
  const dispatch = useAppDispatch()
  log.debug('Captcha View')

  useEffect(() => {
    if (ready && !captchaAttached) {
      log.debug('attaching captcha')
      const renderParams = {
        sitekey: recaptchaSiteKey,
        theme: document.documentElement.classList.contains('dark') ? 'dark' : 'light',
        callback: (newToken: string) => {
          log.debug('captcha token:', newToken)
          dispatch(setToken(newToken))
        }
      }
      ;(globalThis as unknown as { grecaptcha: { render: (id: string, params: object) => void } }).grecaptcha.render('captchaRoot', renderParams)
      setCaptchaAttached(true)
    } else if (!ready && !loading) {
      log.debug('captcha view initializing captcha')
      void dispatch(initializeCaptcha())
    }
  }, [ready, captchaAttached, dispatch, loading, recaptchaSiteKey])

  useEffect(() => {
    log.debug('token changed:', token)
    if (onChange != null) {
      onChange(token)
    } else if (binding != null) {
      binding[1](token)
    }
  }, [token, binding, onChange])

  return (
    <div id='captchaRoot' className={captchaAttached ? 'display' : 'hidden'}>
    </div>
  )
}
