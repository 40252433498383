import { Button, Dialog } from '@andyneville/tailwind-react'
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline'
import { type ReactElement } from 'react'
import { type ITenant } from '../../../api/api'
import { useSelector } from 'react-redux'
import { clear as clearSeason } from '../reducers/seasonReducer'

import unknownLogo from '../assets/logo2.png'
import { useAppDispatch, type RootState } from '../store'
import { switchTenant } from '../reducers/authReducer'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'TenantDialog' })

export type CallbackFn = () => void
/*
function stopPropagationIcon (fn?: CallbackFn) {
  return (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
    if (fn != null) {
      fn()
    }
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
}

function stopPropagationButton(fn?: CallbackFn) {
  log.debug('stopPropagationButton')
  return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    log.debug('stopPropagationButton fn called')
    if (fn != null) {
      fn()
    }
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
} */

export interface UnboundTenantDialogProps {
  tenants?: ITenant[]
  open: boolean
  onClose: () => void
  onChooseTenant?: (seasonId: string) => void
}
// onClick={(event): void => { if (onDelete != null) { onDelete() }; event.stopPropagation(); event.nativeEvent.stopImmediatePropagation() }} />
function TenantButton (props: { name: string, logo?: string, onClick: () => void }): ReactElement {
  const { name, logo, onClick } = props
  return (
    <a className='flex flex-row items-center w-full px-4 py-2 border rounded-lg cursor-pointer border-brand-600 bg-brand-50 dark:border-brand-400 dark:bg-brand-600/30 hover:bg-brand-200 hover:dark:bg-brand-600/50' onClick={onClick}>
      <img src={logo ?? unknownLogo} alt={name} className='w-6 h-6' />
      <h3 className='ml-2 text-lg'>{name}</h3>
    </a>
  )
}

export function UnboundTenantDialog (props: UnboundTenantDialogProps): ReactElement {
  const { open, onClose, tenants = [], onChooseTenant } = props

  const doChooseTenant = (tenantId: string): void => {
    if (onChooseTenant != null) {
      onChooseTenant(tenantId)
    }
  }

  return (
    <>
      <Dialog title='Choose Organization' Icon={ArrowsRightLeftIcon} wide open={open} onClose={onClose} buttons={
        <>
          <Button label='Close' onClick={onClose} />
        </>
      }>
        <div className='grid gap-2 py-2'>
          {tenants.map((tenant) => (
            <TenantButton key={tenant.id} name={tenant.name} logo={tenant.logo} onClick={() => { doChooseTenant(tenant.id) }} />
          ))}
        </div>
      </Dialog>
    </>
  )
}

export interface TenantDialogProps {
  open: boolean
  onClose: () => void
}

export default function TenantDialog (props: Omit<UnboundTenantDialogProps, 'tenants'>): ReactElement {
  const { open, onClose } = props
  const { tenants } = useSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()

  const onChooseTenant = (tenantId: string): void => {
    log.debug('setting current tenant to ', tenantId)
    dispatch(clearSeason())
    void dispatch(switchTenant(tenantId))
    onClose()
  }

  if (!open || tenants == null) {
    return <></>
  }

  return (
    <UnboundTenantDialog open={open} onClose={onClose} tenants={Object.values(tenants)} onChooseTenant={onChooseTenant} />
  )
}
