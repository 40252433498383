import { classes } from '@andyneville/tailwind-react'
import { useMemo, type ReactElement } from 'react'

export interface ProgressBulletsProps {
  total: number
  current: number
  label?: string
  className?: string
  onClick?: (step: number) => void
}

export default function ProgressBullets (props: ProgressBulletsProps): ReactElement {
  const { total, current, className, label = `Step ${current} of ${total}`, onClick } = props
  const bullets = useMemo(() => {
    return Array.from({ length: total }, (_, i) => i + 1)
  }, [total])

  const StepElement = onClick == null ? 'div' : 'a'
  return (
    <nav className={classes('flex items-center justify-center', className)} aria-label="Progress">
      <p className="text-sm font-medium">
        {label}
      </p>
      <ol role="list" className="flex items-center ml-8 space-x-5">
        {bullets.map((i) => (
          <li key={i}>
            {i < current
              ? (
                <StepElement className={classes('block h-2.5 w-2.5 rounded-full bg-brand-600 dark:bg-brand-600', onClick != null ? 'hover:bg-brand-500 hover:dark:bg-brand-500 cursor-pointer' : '')} onClick={() => { if (onClick != null) onClick(i) }}>
                  <span className="sr-only">step {i}</span>
                </StepElement>
                )
              : i === current
                ? (
                  <StepElement className={classes('relative flex items-center justify-center', onClick != null ? 'cursor-pointer' : '')} onClick={() => { if (onClick != null) onClick(i) }}>
                    <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
                      <span className="w-full h-full rounded-full bg-brand-200 dark:bg-brand-700" />
                    </span>
                    <span className="relative block h-2.5 w-2.5 rounded-full bg-brand-600 dark:bg-brand-500" aria-hidden="true" />
                    <span className="sr-only">step {i}</span>
                  </StepElement>
                  )
                : (
                  <StepElement className={classes('block h-2.5 w-2.5 rounded-full bg-gray-200 dark:bg-dark-400', onClick != null ? 'hover:bg-gray-400 dark:hover:bg-dark-400 cursor-pointer' : '')} onClick={() => { if (onClick != null) onClick(i) }}>
                    <span className="sr-only">step {i}</span>
                  </StepElement>
                  )
            }
          </li>
        ))}
      </ol>
    </nav>
  )
}
