import { useContext, type ReactElement, useState, useMemo } from 'react'
import { ArrowUturnDownIcon, TrashIcon } from '@heroicons/react/24/outline'
import { GlobalDialogContext, PageHeader, LoadingComponent, Button, TextBox, Breadcrumbs } from '@andyneville/tailwind-react'
import { Link, useParams } from 'react-router-dom'
import TableHeader from '../../components/TableHeader'
import SortableTableHeader from '../../components/SortableTableHeader'
import TableCell from '../../components/TableCell'
import TableActionCell from '../../components/TableActionCell'
import { type IAthlete } from '../../../../api/api'
import { useEditAdminAthleteMutation, useGetAdminAthletesQuery } from '../../reducers/apiSlice-admin-athletes'
import unknownUser from '../../assets/user.png'
import { useGetAdminTenantQuery } from '../../reducers/apiSlice-admin-tenants'
export type CallbackFn = () => void

function sortAthletes (a: IAthlete, b: IAthlete): number {
  if (a.lastName != null && a.lastName === b.lastName) {
    return a.firstName.localeCompare(b.firstName)
  }
  return a.lastName.localeCompare(b.lastName)
}

export default function AdminTenantAthletes (): ReactElement {
  const { tenantId } = useParams()
  const {
    data: athletes = [],
    isLoading,
    // isSuccess,
    isError,
    error
  } = useGetAdminAthletesQuery({ tenantId: tenantId ?? '' }, { skip: tenantId == null || tenantId.length === 0 })
  const {
    data: tenant
  } = useGetAdminTenantQuery(tenantId ?? '', { skip: tenantId == null || tenantId.length === 0 })

  const globalDialog = useContext(GlobalDialogContext)
  const [editAthlete, { isLoading: editLoading }] = useEditAdminAthleteMutation()
  const [filter, setFilter] = useState('')

  const doDeleteAthlete = (athlete: IAthlete): void => {
    globalDialog.showWarning('Delete Athlete', `Are you sure you want to delete the athlete ${athlete.firstName} ${athlete.lastName}?`, 'Delete', () => {
      void editAthlete({ tenantId: tenantId ?? '', id: athlete.id, deleted: true })
    })
  }

  const doRestoreAthlete = (athlete: IAthlete): void => {
    globalDialog.showQuestion('Restore Athlete', `Are you sure you want to restore the athlete ${athlete.firstName} ${athlete.lastName}?`, 'Restore', () => {
      void editAthlete({ tenantId: tenantId ?? '', id: athlete.id, deleted: false })
    })
  }

  const filteredAthletes = useMemo(() => {
    if (filter === '') {
      return athletes
    }
    return athletes.filter((athlete) => {
      if (athlete?.firstName != null && athlete.firstName.toLowerCase().includes(filter.toLowerCase())) return true
      if (athlete?.lastName != null && athlete.lastName.toLowerCase().includes(filter.toLowerCase())) return true
      if (athlete?.birthYear != null && athlete.birthYear.toString().includes(filter.toLowerCase())) return true
      return false
    })
  }, [athletes, filter])

  // <div className="max-h-[600px] overflow-y-auto">
  return (
    <div className="">
      <PageHeader
        title='Athletes'
        subtitle='A list of all the athletes for this organization.'
        breadcrumbs={<Breadcrumbs routes={[{ route: '/admin/organizations', name: 'Orgs' }, { route: `/admin/organizations/${tenantId}`, name: tenant?.name ?? 'Org' }, { name: 'Athletes' }]} />}
      >
        <div className='flex items-center pl-4 twc-form'>
          <div className='mr-2 text-sm'>Filter:</div>
          <TextBox value={filter} onChange={setFilter} />
        </div>
      </PageHeader>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <LoadingComponent isLoading={isLoading} isError={isError} error={error} isEmpty={athletes.length === 0}>
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
                <thead>
                  <tr>
                    <TableHeader name='Image' srOnly/>
                    <SortableTableHeader name='First Name' sorted={1} />
                    <SortableTableHeader name='Last Name' />
                    <SortableTableHeader name='Birth Year' />
                    <TableHeader name='Actions' srOnly />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {filteredAthletes.slice().sort(sortAthletes).map((athlete) => (
                    <tr key={athlete.id} className={athlete.deleted === true ? 'line-through' : 'no'}>
                      <TableCell>
                        <img className="w-6 h-6 rounded-full" src={athlete.headshotUrl ?? unknownUser} alt="" />
                      </TableCell>
                      <TableCell emphasize>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenantId}/athletes/${athlete.id}`}>{athlete.firstName}</Link>
                      </TableCell>
                      <TableCell emphasize>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenantId}/athletes/${athlete.id}`}>{athlete.lastName}</Link>
                      </TableCell>
                      <TableCell>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenantId}/athletes/${athlete.id}`}>{athlete.birthYear}</Link>
                      </TableCell>
                      <TableActionCell>
                        <div className='flex items-center'>
                          {athlete.deleted === true
                            ? <Button Icon={ArrowUturnDownIcon} waiting={editLoading} transparent onClick={() => { doRestoreAthlete(athlete) }} />
                            : <Button Icon={TrashIcon} waiting={editLoading} transparent onClick={() => { doDeleteAthlete(athlete) }} />
                          }
                        </div>
                      </TableActionCell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </LoadingComponent>
          </div>
        </div>
      </div>
    </div>
  )
}
