import { Button, CheckBox, Dialog, EmailBox, Form, Mask, TextBox } from '@andyneville/tailwind-react'
import { useState, type ReactElement, useRef } from 'react'
import { useAddAdminAccountMutation, useAddAdminAccountTenantMutation } from '../reducers/apiSlice-admin-accounts'
import { useNavigate } from 'react-router'
import { useAddAdminTenantMutation } from '../reducers/apiSlice-admin-tenants'
import { TenantPlan, TenantRole, TenantStatus } from '../../../api/api'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'AddUserDialog' })

export interface AddUserDialogProps {
  open: boolean
  onClose: () => void
}

export default function AddUserDialog (props: AddUserDialogProps): ReactElement {
  const { open, onClose } = props
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [createOrg, setCreateOrg] = useState(true)
  const nameBox = useRef<HTMLInputElement | null>(null)
  const [addAccount, { isLoading: addingAccount }] = useAddAdminAccountMutation()
  const [addTenant, { isLoading: addingTenant }] = useAddAdminTenantMutation()
  const [addAccountTenant, { isLoading: addingAccountTenant }] = useAddAdminAccountTenantMutation()
  const navigate = useNavigate()

  const newUser = async (): Promise<void> => {
    log.debug('newUser')
    try {
      const account = await addAccount({
        name: firstName + ' ' + lastName,
        firstName,
        lastName,
        email,
        active: true
      }).unwrap()
      if (account != null) {
        log.debug('newUser', account)
        if (createOrg) {
          const organization = await addTenant({
            name: firstName + ' ' + lastName + '\'s Organization',
            status: TenantStatus.Onboarding,
            plan: TenantPlan.New
          }).unwrap()
          if (organization != null) {
            log.debug('newOrg', organization)
          }
          const accountTenant = await addAccountTenant({
            accountId: account.id,
            tenantId: organization.id,
            roles: [TenantRole.Owner]
          }).unwrap()
          if (accountTenant != null) {
            log.debug('newAccountTenant', accountTenant)
          }
        }
        navigate(`/admin/users/${account.id}`)
      }
      onClose()
    } catch (e) {
      log.error('Error adding user', e)
    }
  }

  const isValid = firstName.length > 0 && lastName.length > 0 && email.length > 0

  return (
    <Dialog
      title='Add New User'
      open={open}
      onClose={onClose}
      buttons={
        <>
          <Button disabled={!isValid} primary onClick={() => { void newUser() }}>Add</Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <Mask show={addingAccount || addingTenant || addingAccountTenant} message='Adding...'>
        <Form autofocus={nameBox}>
          <TextBox ref={nameBox} columns={6} autofocus label='First Name' value={firstName} onChange={setFirstName} />
          <TextBox columns={6} label='Last Name' value={lastName} onChange={setLastName} />
          <EmailBox columns={6} label='Email' value={email} onChange={setEmail} />
          <CheckBox columns={6} label='Setup' helpText='Create Organization' value={createOrg} onChange={setCreateOrg} />
        </Form>
      </Mask>
    </Dialog>
  )
}
