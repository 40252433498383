import { classes } from '@andyneville/tailwind-react'
import { type PropsWithChildren, type ReactElement, useEffect, useContext } from 'react'
import { type InstanceInfo, SortableContext } from './SortableProvider'
import { type ListOperation } from './SortableOperation'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'SortableList' }, {
  level: 'warn'
})

// https://afi.io/blog/how-to-use-shopify-draggable-with-react/?ref=blog.afi.io

// export type SetList = Dispatch<SetStateAction<DraggableItem[]>>
export type SetList = (items: DraggableItem[], itemId: string, fromListId: string, toListId: string, operation: ListOperation, instance: InstanceInfo) => void
export type Update = (instance: InstanceInfo) => void

export type DraggableItem = {
  id: string
} & Record<string, unknown>

export interface SortableListProps {
  // what to render the container as
  as?: 'div' | 'ul'
  id: string
  className: string
  readonly?: boolean
  items: DraggableItem[]
  setItems: SetList
  update?: Update
  onClick?: () => void
}

export default function SortableList (props: PropsWithChildren<SortableListProps>): ReactElement {
  const { children, as: ElementType = 'div', items, setItems, update, id, className, readonly = false } = props
  const sortable = useContext(SortableContext)
  useEffect(() => {
    if (readonly) {
      return
    }
    // log.debug('SL4 useEffect', id)
    if (sortable == null) {
      log.error('SortableList4: no SortableContext')
      throw new Error('SortableList4: no SortableContext')
    }
    sortable.setList(id, { id, items, setItems, update })
  }, [readonly, id, sortable, items, setItems, update])

  // log.debug('renderList', id, JSON.stringify(items))
  return (
    <ElementType className={classes(readonly ? '' : sortable?.sortableClass, readonly ? 'overflow-hidden' : 'droppable overflow-hidden', className)} id={id}>
      {children}
    </ElementType>
  )
}
