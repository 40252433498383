import { useState, type ReactElement } from 'react'
import { classes, Picker } from '@andyneville/tailwind-react'

export interface RatingProps {
  value?: number
  small?: boolean
  setValue?: (value: number) => void
}

export default function Rating (props: RatingProps): ReactElement {
  const { value, setValue, small = false } = props
  const [showPicker, setShowPicker] = useState(false)
  return (
    <>
      <div className={classes(small ? 'w-8 h-8' : 'w-16 h-16', setValue != null ? 'cursor-pointer' : '', 'flex items-center text-white rounded-full bg-green-600 dark:bg-green-500')} onClick={() => { if (setValue != null) setShowPicker(true) }}>
        <div className={classes(small ? 'text-[1.25rem]' : 'text-[2.5rem]', 'w-full text-center')}>{value ?? '?'}</div>
      </div>
      <Picker open={showPicker} className='flex flex-row p-2 gap-x-2 -ml-72'>
        {Array.from({ length: 10 }, (_, i) => i + 1).map((i) => (
          <button
            key={i}
            className='flex items-center w-12 h-12 text-white bg-green-600 rounded-full dark:bg-green-500'
            onClick={() => {
              if (setValue != null) setValue(i)
              setShowPicker(false)
            }}
          >
            <div className='w-full text-[2rem] text-center'>{i}</div>
          </button>
        ))}
      </Picker>
    </>
  )
}
