import type { IAthlete, IAthleteInput } from '../../../api/api'
import { apiSlice } from './apiSlice'

export const adminAthletesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAdminAthletes: builder.query<IAthlete[], { tenantId: string }>({
      query: (id: { tenantId: string }) => `/admin/tenants/${id.tenantId}/athletes`,

      providesTags: (result = [], _error, _arg) => [
        ...result.map(({ id }) => ({ type: 'AdminAthlete' as const, id })),
        'AdminAthlete'
      ]
    }),
    getAdminAthlete: builder.query<IAthlete | undefined, { athleteId: string, tenantId: string }>({
      query: (id: { athleteId: string, tenantId: string }) => `/admin/tenants/${id.tenantId}/athletes/${id.athleteId}}`,
      providesTags: (result, _error, _arg) => [{ type: 'AdminAthlete', id: result?.id }]
    }),
    addAdminAthlete: builder.mutation<IAthlete, IAthleteInput & { tenantId: string }>({
      query: (newAthlete: IAthleteInput & { tenantId: string }) => ({
        url: `/admin/tenants/${newAthlete.tenantId}/athletes`,
        method: 'POST',
        body: newAthlete
      }),
      invalidatesTags: (_result, _error, _arg) => ['AdminAthlete']
    }),
    editAdminAthlete: builder.mutation({
      query: (athlete: Partial<IAthlete> & { id: string, tenantId: string }) => ({
        url: `/admin/tenants/${athlete.tenantId}/athletes/${athlete.id}`,
        method: 'PUT',
        body: athlete
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'AdminAthlete', id: arg.id }]
    })
  })
})

export const {
  useGetAdminAthletesQuery,
  useGetAdminAthleteQuery,
  useAddAdminAthleteMutation,
  useEditAdminAthleteMutation
} = adminAthletesApiSlice
