import { Button, Dialog, Form, TextBox } from '@andyneville/tailwind-react'
import { PencilIcon } from '@heroicons/react/24/outline'
import { useState, type ReactElement } from 'react'
import { type ISeason } from '../../../api/api'

export type CallbackFn = () => void

export interface EditSeasonDialogProps {
  season: ISeason
  open: boolean
  onClose: () => void
  onEditSeason: (seasonId: string, name: string) => void
}

export function EditSeasonDialog (props: EditSeasonDialogProps): ReactElement {
  const { open, onClose, season, onEditSeason } = props
  const [seasonName, setSeasonName] = useState(season.name)

  return (
    <Dialog title="Edit Season" Icon={PencilIcon} open={open} onClose={onClose} buttons={
      <>
        <Button label='Save' disabled={seasonName === ''} onClick={() => { if (seasonName !== '') onEditSeason(season.id, seasonName) }} />
        <Button label='Cancel' onClick={onClose} />
      </>
    }>
      <Form>
        <TextBox columns={12} label='Season Name' value={seasonName} onChange={setSeasonName} />
      </Form>
    </Dialog>
  )
}
