import { Button, Dialog, Form, LoadingIcon } from '@andyneville/tailwind-react'
import { CloudArrowUpIcon, PauseIcon, PlayIcon } from '@heroicons/react/24/outline'
import { useRef, type ReactElement, useEffect, useState } from 'react'

export interface LocalPlayVideoDialogProps {
  video: Blob
  thumbnail?: string
  title: string
  autoPlay?: boolean
  open: boolean
  onClose: () => void
  onEnded?: () => void
}

export default function PlayVideoDialog (props: LocalPlayVideoDialogProps): ReactElement {
  const { video, autoPlay = false, open, onClose, title, thumbnail, onEnded } = props
  const playButtonRef = useRef<HTMLButtonElement | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [videoUrl, setVideoUrl] = useState('')

  useEffect(() => {
    setVideoUrl(URL.createObjectURL(video))
  }, [video])
  useEffect(() => {
    if (videoUrl != null) {
      setIsLoading(true) // reset every time the URL changes
    }
  }, [videoUrl])

  const togglePlay = (): void => {
    if (isPlaying) {
      videoRef.current?.pause()
    } else {
      void videoRef.current?.play()
    }
  }

  return (
    <Dialog wide hFull title={title} open={open} onClose={onClose}>
      <div ref={wrapperRef} className='h-full flex flex-col items-center w-full gap-2 overflow-hidden text-center justify-items-center'>
        <div className='h-full relative flex-grow overflow-hidden'>
          <video
            ref={videoRef}
            src={videoUrl}
            autoPlay={autoPlay}
            className='h-full object-contain'
            onEnded={() => { setIsPlaying(false); if (onEnded != null) onEnded() }}
            onPlay={() => { setIsPlaying(true) }}
            onLoadedData={() => { setIsLoading(false) }}
          />
          <div className={isLoading ? 'absolute inset-0 flex items-center justify-center' : 'hidden'}>
            {thumbnail != null && thumbnail.length > 0 &&
              <div className='bg-center bg-cover' style={{ backgroundImage: `url("${thumbnail}")` }} />
            }
          </div>
          <div className={isLoading ? 'absolute inset-0 flex items-center justify-center' : 'hidden'}>
            <LoadingIcon className='w-24 h-24 animate-spin'/>
          </div>
          <CloudArrowUpIcon className='absolute text-gray-50 dark:text-dark-50 bottom-0 left-0 w-6 h-6' />
        </div>
        <div className='flex-shrink flex-row w-full py-1 justify-items-center gap-x-2'>
          <Form noGrid className='items-center' autofocus={playButtonRef}>
            <Button ref={playButtonRef} autofocus Icon={isPlaying ? PauseIcon : PlayIcon} primary={!isPlaying} onClick={togglePlay} />
          </Form>
        </div>
      </div>
    </Dialog>
  )
}
