import { classes } from '@andyneville/tailwind-react'
import { type ReactElement } from 'react'
import { ChevronDoubleUpIcon } from '@heroicons/react/24/outline'

export interface EmptyListBoxProps {
  name: string
  hideArrow?: boolean
  capitalizedName?: string
  namePlural?: string
  className?: string
  title?: string
  text?: string
}

export default function EmptyListBox (props: EmptyListBoxProps): ReactElement {
  const {
    name,
    capitalizedName = name.charAt(0).toUpperCase() + name.slice(1),
    namePlural = name + 's',
    className,
    hideArrow = false,
    title = `No ${namePlural}`,
    text = `Get started by clicking the "Add ${capitalizedName}" button above.`
  } = props
  return (
    <div className={classes('flex flex-col items-center text-center py-4 border rounded-lg border-gray-300 dark:border-dark-500 relative', className)}>
      {!hideArrow &&
        <ChevronDoubleUpIcon className="absolute w-10 h-10 m-2 top-2 left-8 sm:left-auto sm:right-8 text-brand-600 dark:text-brand-500 animate-bounce" />
      }
      <svg
        className="w-12 h-12 mx-auto text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-base font-semibold">{title}</h3>
      <p className="mt-1 p-2 text-base text-center max-w-[75%] md:max-w-[480px]">
        {text}
      </p>
    </div>
  )
}
