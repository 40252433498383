import React, { useState, type ReactElement, useEffect, useContext, useRef, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { GlobalDialogContext, Breadcrumbs, Button, ComboBox, DatePickerBox, Dialog, Form, FormButtons, ImageFileDropInput, LoadingComponent, Mask, SelectBox, TextArea, TextBox, classes } from '@andyneville/tailwind-react'
import { HomeIcon, InformationCircleIcon, MinusIcon, PlusIcon, TrashIcon, UserIcon } from '@heroicons/react/24/outline'
import { TenantStatus, type ITenant, type IAccountTenant, TenantRole, type IAccountWithAccountTenant, TenantPlan, type ISubscriptionTransaction, type ISeason } from '../../../../api/api'
import TableHeader from '../../components/TableHeader'
import SortableTableHeader from '../../components/SortableTableHeader'
import TableCell from '../../components/TableCell'
import TableActionCell from '../../components/TableActionCell'
import { useAddAdminTenantAccountMutation, useDeleteAdminTenantAccountMutation, useEditAdminTenantAccountMutation, useEditAdminTenantMutation, useGetAdminTenantAccountsQuery, useGetAdminTenantQuery, useGetAdminTenantTransactionsQuery } from '../../reducers/apiSlice-admin-tenants'
import { DateTime } from 'luxon'
import { rootLog } from '../../logging'
import { useGetAdminAccountsQuery } from '../../reducers/apiSlice-admin-accounts'
import { type ComboOption } from '@andyneville/tailwind-react/ComboBox'
import { useGetAdminSeasonsQuery } from '../../reducers/apiSlice-admin-seasons'
import { useGetAdminAthletesQuery } from '../../reducers/apiSlice-admin-athletes'

const log = rootLog.child({ module: 'TenantDetail' })

const organizationStatuses = [
  { id: TenantStatus.Onboarding, name: 'Onboarding' },
  { id: TenantStatus.Active, name: 'Active' },
  { id: TenantStatus.Expired, name: 'Expired' }
]

const organizationPlans = [
  { id: TenantPlan.New, name: 'New Account' },
  { id: TenantPlan.Trial, name: 'Trial' },
  { id: TenantPlan.Starter, name: 'Starter' },
  { id: TenantPlan.Premium, name: 'Premium' },
  { id: TenantPlan.Unlimited, name: 'Unlimited' }
]

export default function AdminTenantDetail (): ReactElement {
  const { tenantId } = useParams()
  const {
    data: sourceTenant,
    isLoading: loadingTenant
    // isSuccess,
    // isError,
    // error
  } = useGetAdminTenantQuery(tenantId ?? '', { skip: tenantId == null || tenantId.length === 0 })
  const {
    data: accounts = [],
    isLoading: loadingAccounts
    // isSuccess,
    // isError,
    // error
  } = useGetAdminTenantAccountsQuery(tenantId ?? '', { skip: tenantId == null || tenantId.length === 0 })
  const {
    data: subscriptions = [],
    isLoading: loadingSubscriptions
    // isSuccess,
    // isError,
    // error
  } = useGetAdminTenantTransactionsQuery(tenantId ?? '', { skip: tenantId == null || tenantId.length === 0 })
  const {
    data: seasons = [],
    isLoading: loadingSeasons
    // isSuccess,
    // isError,
    // error
  } = useGetAdminSeasonsQuery({ tenantId: tenantId ?? '' }, { skip: tenantId == null || tenantId.length === 0 })
  const {
    data: allAccounts = []
    // isLoading: loadingAllAccounts,
    // isSuccess,
    // isError,
    // error
  } = useGetAdminAccountsQuery()
  const {
    data: athletes = [],
    isLoading: isLoadingAthletes
  } = useGetAdminAthletesQuery({ tenantId: tenantId ?? '' }, { skip: tenantId == null || tenantId.length === 0 })
  const [updateTenant, { isLoading: updateLoading }] = useEditAdminTenantMutation()
  const [updateTenantAccount, { isLoading: updateLoadingTA }] = useEditAdminTenantAccountMutation()
  const [addTenantAccount, { isLoading: addLoadingTA }] = useAddAdminTenantAccountMutation()
  const [deleteTenantAccount, { isLoading: deleteLoadingTA }] = useDeleteAdminTenantAccountMutation()
  const [tenant, setTenant] = useState<ITenant>({} as unknown as ITenant)
  const [isDirty, setIsDirty] = useState(false)
  const [newAccount, setNewAccount] = useState<ComboOption | null | undefined>(null)
  const globalDialog = useContext(GlobalDialogContext)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [showTransactionDetails, setShowTransactionDetails] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState<ISubscriptionTransaction | null>(null)
  const [textAreaHeight, setTextAreaHeight] = useState<number>(100)

  const accountOptions = useMemo(() => {
    const availableAccounts = allAccounts.filter((account) => !accounts.some((a) => a.id === account.id))
    return availableAccounts.map((account) => { return { id: account.id, name: `${account.name} (${account.email})` } })
  }, [allAccounts, accounts])

  useEffect(() => {
    log.debug('useEffect', textAreaRef.current)
    if (textAreaRef.current != null) {
      log.debug('updateHeight')
      updateHeight()
      /*       const dialogParent = textAreaRef.current.parentElement?.parentElement?.parentElement?.parentElement?.parentElement
      if (dialogParent != null) {
        setTextAreaHeight(dialogParent.clientHeight - 100)
      }
 */
    } else {
      setTimeout(() => {
        updateHeight()
      }, 100)
    }
  }, [textAreaRef, showTransactionDetails])

  const displayTransactionDetails = (transaction: ISubscriptionTransaction): void => {
    setSelectedTransaction(transaction)
    setShowTransactionDetails(true)
  }
  const cancelTransactionDetails = (): void => {
    setShowTransactionDetails(false)
  }

  const updateHeight = (): void => {
    if (textAreaRef.current != null) {
      const dialogParent = textAreaRef.current.parentElement?.parentElement?.parentElement?.parentElement?.parentElement
      if (dialogParent != null) {
        setTextAreaHeight(dialogParent.clientHeight - 100)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateHeight)
    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, []) // Empty dependency array ensures this effect runs once on mount and cleanup on unmount

  useEffect(() => {
    if (sourceTenant != null) {
      setTenant(sourceTenant)
      setIsDirty(false)
    }
  }, [sourceTenant])

  const setValue = (value: Partial<ITenant>): void => {
    setTenant(Object.assign({}, tenant, value))
    setIsDirty(true)
  }

  const setAccountTenantRole = (accountTenant: IAccountTenant, roleName: TenantRole, value: boolean): void => {
    const roles = accountTenant.roles ?? []
    if (roles.includes(roleName) && !value) {
      void updateTenantAccount({ accountId: accountTenant.accountId, tenantId: tenant.id, roles: roles.filter((role) => role !== roleName) })
    } else if (!roles.includes(roleName) && value) {
      void updateTenantAccount({ accountId: accountTenant.accountId, tenantId: tenant.id, roles: [...roles, roleName] })
    }
  }

  const doSave = async (): Promise<void> => {
    try {
      await updateTenant(tenant).unwrap()
      setIsDirty(false)
    } catch (e) {
      log.error('Error updating tenant', e)
      setIsDirty(true)
    }
  }

  const addUser = (): void => {
    if (newAccount?.id != null) {
      void addTenantAccount({ tenantId: tenant.id, accountId: newAccount.id })
      setNewAccount(null)
    }
  }

  const deleteUser = (account: IAccountWithAccountTenant): void => {
    globalDialog.showWarning('Remove from Organization', `Are you sure you want to remove ${account.name} from the ${tenant.name} organization?`, 'Remove', () => {
      void deleteTenantAccount({ accountId: account.id, tenantId: tenant.id })
    })
  }

  const seasonStyle = (season: ISeason): string => {
    return classes(
      'hover:text-brand-500 dark:hover:text-brand-400',
      season.deleted === true ? 'line-through' : ''
    )
  }

  return (
    <>
      <Breadcrumbs backRoute={'/admin/organizations'} routes={[{ route: '/', Icon: HomeIcon }, { route: '/admin/organizations', name: 'Organizations' }, { name: tenant?.name ?? 'Organization Details' }]} />
      <div className='sm:mt-5'></div>
      <Dialog
        open={showTransactionDetails}
        onClose={cancelTransactionDetails}
        title='Transaction Details'
        wide
        Icon={InformationCircleIcon}
        buttons={<Button label="Close" onClick={cancelTransactionDetails} />}>
        <Form>
          <TextArea readonly style={{ height: `${textAreaHeight}px` }} ref={textAreaRef} autofocus id='transaction-details' columns={12} rows={10} value={selectedTransaction != null ? JSON.stringify(selectedTransaction, null, 2) : ''} />
        </Form>
      </Dialog>
      <LoadingComponent isLoading={loadingTenant}>
        <Mask show={updateLoading} message='Updating...'>
          <Form>
            <ImageFileDropInput round value={tenant.logo} className='!ml-0' columns={1} />
            <TextBox id='name' label='Organization Name' columns={5} value={tenant.name} onChange={(value) => { setValue({ name: value }) }} />
            <SelectBox label='Status' columns={2} value={organizationStatuses.find(s => s.id === tenant.status)} options={organizationStatuses} onChange={(value) => { setValue({ status: value?.id as TenantStatus }) }} />
            <SelectBox label='Plan' columns={2} value={organizationPlans.find(p => p.id === tenant.plan)} options={organizationPlans} onChange={(value) => { setValue({ plan: value?.id as TenantPlan }) }} />
            <DatePickerBox label='Expiration' showClear columns={2} value={tenant.expiration != null ? new Date(tenant.expiration) : null} onChange={(value) => { setValue({ expiration: value != null ? value.getTime() as unknown as Date : null as unknown as Date }) }} />
            <FormButtons>
              {!isLoadingAthletes && athletes.length > 0 &&
                <Link to={`/admin/organizations/${tenantId}/athletes`} className='pt-1.5 pr-2'>{ athletes.length } Athletes</Link>
              }
              <Button label='Save' primary disabled={!isDirty} onClick={() => { void doSave() }} />
            </FormButtons>
          </Form>
        </Mask>
      </LoadingComponent>
      <LoadingComponent isLoading={loadingAccounts} >
        <Mask show={updateLoading || updateLoadingTA || addLoadingTA || deleteLoadingTA} message='Updating...'>
          <h4>Accounts</h4>
          <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
            <thead>
              <tr>
                <TableHeader name='Image' srOnly />
                <SortableTableHeader name='Name' sorted={1} />
                <SortableTableHeader name='Email' sorted={1} />
                <SortableTableHeader name='Roles' />
                <SortableTableHeader name='Active' />
                <TableHeader name='Edit' srOnly />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {(accounts ?? []).map((account) => (
                <tr key={account.id} className={!account.active ? 'line-through' : ''}>
                  <TableCell compact>
                    {account.picture == null
                      ? (
                        <UserIcon className="w-6 h-6 rounded-full" aria-hidden="true" />
                        )
                      : (
                        <img className="w-6 h-6 rounded-full" src={account.picture} alt="" />
                        )
                    }
                  </TableCell>
                  <TableCell emphasize>
                    <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/users/${account.id}`}>{account.name}</Link>
                  </TableCell>
                  <TableCell emphasize>
                    <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/users/${account.id}`}>{account.email}</Link>
                  </TableCell>
                  <TableCell>{(account.accountTenant?.roles ?? []).join(', ')}</TableCell>
                  <TableCell>{account.active ? 'active' : ''}</TableCell>
                  <TableActionCell>
                    <div className='flex items-center gap-x-2'>
                      {account.accountTenant?.roles?.includes(TenantRole.Owner) === true
                        ? <Button Icon={MinusIcon} small label='Owner' primary onClick={() => { setAccountTenantRole(account.accountTenant as unknown as IAccountTenant, TenantRole.Owner, false) }} />
                        : <Button Icon={PlusIcon} small label='Owner' primary onClick={() => { setAccountTenantRole(account.accountTenant as unknown as IAccountTenant, TenantRole.Owner, true) }} />
                      }
                      {account.accountTenant?.roles?.includes(TenantRole.Manager) === true
                        ? <Button Icon={MinusIcon} small label='Manager' primary onClick={() => { setAccountTenantRole(account.accountTenant as unknown as IAccountTenant, TenantRole.Manager, false) }} />
                        : <Button Icon={PlusIcon} small label='Manager' primary onClick={() => { setAccountTenantRole(account.accountTenant as unknown as IAccountTenant, TenantRole.Manager, true) }} />
                      }
                      <Button Icon={TrashIcon} transparent onClick={() => { deleteUser(account) }} />
                    </div>
                  </TableActionCell>
                </tr>
              ))}
            </tbody>
          </table>
          <Form>
              <ComboBox id='name' label='Add User' columns={4} options={accountOptions} value={newAccount} onChange={setNewAccount} />
              <Button className='mt-8' label='Add User' primary disabled={newAccount?.id == null} onClick={addUser} />
          </Form>
        </Mask>
      </LoadingComponent>
      <LoadingComponent isLoading={loadingSeasons} >
        <h4 className='mt-8'>Seasons</h4>
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
          <thead>
            <tr>
              <SortableTableHeader name='Name' sorted={1} />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {(seasons ?? []).map((season) => (
              <tr key={season.id} >
                <Link className={seasonStyle(season)} to={`/admin/organizations/${tenantId}/seasons/${season.id}`}>{season.name}</Link>
              </tr>
            ))}
          </tbody>
        </table>
      </LoadingComponent>
      <LoadingComponent isLoading={loadingSubscriptions} >
        <h4 className='mt-8'>Subscriptions</h4>
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
          <thead>
            <tr>
              <SortableTableHeader name='Subscription' sorted={1} />
              <SortableTableHeader name='Activity' sorted={1} />
              <SortableTableHeader name='Transaction' sorted={1} />
              <SortableTableHeader name='Date' />
              <SortableTableHeader name='Amount' />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {(subscriptions ?? []).map((subscription) => (
              <React.Fragment key={subscription.id}>
                <tr>
                  <td colSpan={5}>
                    Status: {subscription.status}
                  </td>
                </tr>
                {subscription.transactions.map((transaction) => (
                  <tr key={transaction.id} onClick={() => { displayTransactionDetails(transaction) }}>
                    <TableCell></TableCell>
                    <TableCell emphasize>{transaction.message}</TableCell>
                    <TableCell>{transaction.transactionType}</TableCell>
                    <TableCell>{DateTime.fromMillis(transaction.transactionDate as unknown as number).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</TableCell>
                    <TableCell>{transaction.transactionAmount != null ? `$${transaction.transactionAmount}` : ''}</TableCell>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </LoadingComponent>
    </>
  )
}
