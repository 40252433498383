import { Button, Dialog, Form, GlobalDialogContext, TextArea } from '@andyneville/tailwind-react'
import { useContext, useRef, useState, type ReactElement } from 'react'
import { useSubmitFeedbackMutation } from '../reducers/apiSlice'

export interface FeedbackDialogProps {
  open: boolean
  onClose: () => void
}

export default function FeedbackDialog (props: FeedbackDialogProps): ReactElement {
  const { open, onClose } = props
  const [text, setText] = useState('')
  const nameBox = useRef<HTMLTextAreaElement | null>(null)
  const [submitFeedbackMutation] = useSubmitFeedbackMutation()
  const globalDialog = useContext(GlobalDialogContext)

  const onSubmit = async (): Promise<void> => {
    if (text.length === 0) {
      return
    }
    await submitFeedbackMutation({ feedback: text })
    setText('')
    globalDialog.showInfo('Help / Feedback Sent', 'Thank you for reaching out to us, our team will review this as soon as possible, typically within a day.', () => {
      onClose()
    })
  }

  return (
    <Dialog
      title='Request Help / Provide Feedback'
      open={open}
      onClose={onClose}
      buttons={
        <>
          <Button disabled={text.length === 0} primary onClick={() => { void onSubmit() }}>Send</Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <div className='pt-2 text-sm'>Please provide a description of what you are experiencing and how we can help:</div>
      <Form autofocus={nameBox}>
        <TextArea
          ref={nameBox}
          columns={12}
          autofocus
          rows={8}
          value={text}
          onChange={setText}
        />
      </Form>
    </Dialog>
  )
}
