// import { Redirect, Route } from 'react-router-dom';
// import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { Route, Routes } from 'react-router-dom'

import './App.css'
import '@andyneville/tailwind-react/index.css'
import AppLayout from './layout/AppLayout'
import { useSelector } from 'react-redux'
import { type RootState } from './store'
// import { AuthState } from '@andyneville/garbanzo-lib/browser/authReducer'
import { AuthState } from './reducers/authReducer'
import { useContext, type ReactElement } from 'react'
import { Login, Logout, Signup, Loading, TokenAuth, SelfRegistrationDisabled } from './pages/AuthRoutes'
import Teams from './pages/Teams'
import Tryout from './pages/Tryout'
import Roster from './pages/Roster'
import Error404 from './pages/Error404'
import AdminUsers from './pages/admin/Users'
import Settings from './pages/Settings'
import ChooseTenant from './pages/ChooseTenant'
import { TenantRole, TenantStatus } from '../../api/api'
import Onboarding from './pages/Onboarding'
import AdminTenants from './pages/admin/Tenants'
import AdminUserDetail from './pages/admin/UserDetail'
import AdminTenantDetail from './pages/admin/TenantDetail'
import { TenantSeasonContext } from './components/TenantSeasonProvider'
import Plans from './pages/Plans'
import PlansSuccess from './pages/PlansSuccess'
import { rootLog } from './logging'
import Staff from './pages/Staff'
import AdminTenantAthletes from './pages/admin/TenantAthletes'
import AdminSeasonDetail from './pages/admin/SeasonDetail'
import AdminSeasonAthleteDetail from './pages/admin/SeasonAthleteDetail'
import LocalVideos from './pages/LocalVideos'

const log = rootLog.child({ module: 'App' })

/* Theme variables */
// import './theme/variables.css';

function App (): ReactElement {
  const { authState, isAdmin, roles } = useSelector((state: RootState) => state.auth)
  const { tenant } = useContext(TenantSeasonContext)

  if (authState === AuthState.Loading) {
    log.info('Loading route')
    return (
      <Routes>
        <Route path="/logout" element={<AppLayout />}><Route index element={<Logout />} /></Route>
        <Route path="/auth" element={<AppLayout />}>
          <Route path=":providerId" element={<TokenAuth />} />
        </Route>
        <Route path="*" element={<AppLayout />}><Route path="*" element={<Loading />} /></Route>
      </Routes>
    )
  }

  if (authState === AuthState.Unauthenticated) {
    log.info('Unauthenticated route')
    return (
      <Routes>
        <Route path="/logout" element={<AppLayout />}><Route index element={<Logout />} /></Route>
        <Route path="/login" element={<AppLayout />}><Route index element={<Login />} /></Route>
        <Route path="/signup" element={<AppLayout />}><Route index element={<Signup />} /></Route>
        <Route path="/auth" element={<AppLayout />}>
          <Route path=":providerId" element={<TokenAuth />} />
        </Route>
        <Route path="*" element={<AppLayout />}><Route path="*" element={<Signup />} /></Route>
      </Routes>
    )
  }

  if (authState === AuthState.SelfRegistrationDisabled) {
    log.info('Self registration disabled route')
    return (
      <Routes>
        <Route path="/logout" element={<AppLayout />}><Route index element={<Logout />} /></Route>
        <Route path="/login" element={<AppLayout />}><Route index element={<Login />} /></Route>
        <Route path="/signup" element={<AppLayout />}><Route index element={<Signup />} /></Route>
        <Route path="*" element={<AppLayout />}><Route path="*" element={<SelfRegistrationDisabled />} /></Route>
      </Routes>
    )
  }

  if (authState === AuthState.AuthenticatedNeedsTenantChoice) {
    log.info('AuthenticatedNeedsTenantChoice route')
    return (
      <Routes>
        <Route path="*" element={<AppLayout />} ><Route path="*" element={<ChooseTenant />} /></Route>
      </Routes>
    )
  }

  log.debug('Route: Evaluating Authenticated Onboarding route', authState, tenant)
  if (authState === AuthState.Authenticated && tenant != null && tenant.status === TenantStatus.Onboarding) {
    log.info('Authenticated Onboarding route')
    return (
      <Routes>
        <Route path="*" element={<AppLayout />} >
          <Route path="plans" element={<Plans />} />
          <Route path="plans/success" element={<PlansSuccess />} />
          <Route path="*" element={<Onboarding />} />
        </Route>
      </Routes>
    )
  }
  if (authState === AuthState.Authenticated && tenant?.expiration != null && new Date(tenant.expiration) < new Date()) {
    log.info('Authenticated Expired route')
    return (
      <Routes>
        <Route path="*" element={<AppLayout />} >
          <Route path="plans/success" element={<PlansSuccess />} />
          <Route path="*" element={<Plans />} />
        </Route>
      </Routes>
    )
  }

  log.info('Authenticated route')
  return (
    <Routes>
      <Route path="/login" element={<AppLayout />}><Route index element={<Login />} /></Route>
      <Route path="/signup" element={<AppLayout />}><Route index element={<Signup />} /></Route>
      <Route path="/logout" element={<AppLayout />}><Route index element={<Logout />} /></Route>

      <Route path="/auth" element={<AppLayout />}>
        <Route path=":providerId" element={<TokenAuth />} />
      </Route>
      {authState === AuthState.Authenticated
        ? (
          <>
            <Route path="/" element={<AppLayout />}>
              <Route index element={<Roster />} />
              <Route path="teams" element={<Teams />} />
              <Route path="settings" element={<Settings />} />
              <Route path="tryout/:athleteId" element={<Tryout />} />
              <Route path="plans" element={<Plans />} />
              <Route path="plans/success" element={<PlansSuccess />} />
              <Route path="localVideos" element={<LocalVideos />} />
              {(isAdmin || roles.includes(TenantRole.Owner) || roles.includes(TenantRole.Manager)) &&
                <Route path="staff" element={<Staff />} />
              }
              {isAdmin &&
                <Route path="admin" >
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="users/:userId" element={<AdminUserDetail />} />
                  <Route path="organizations" element={<AdminTenants />} />
                  <Route path="organizations/:tenantId" element={<AdminTenantDetail />} />
                  <Route path="organizations/:tenantId/athletes" element={<AdminTenantAthletes />} />
                  <Route path="organizations/:tenantId/seasons/:seasonId" element={<AdminSeasonDetail />} />
                  <Route path="organizations/:tenantId/seasons/:seasonId/athletes/:athleteId" element={<AdminSeasonAthleteDetail />} />
                </Route>
              }
            </Route>
          </>
          )
        : (
          <Route path="*" element={<Login />} />
          )
      }
      <Route path="*" element={<AppLayout />}>
        <Route index element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  )
}

export default App
