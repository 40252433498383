export function rtkErrorToString (error: unknown): string {
  if (error == null) return 'unknown error'
  if (typeof error === 'string') return error
  if ((error as Record<string, unknown>).message != null && typeof (error as Record<string, unknown>).message === 'string') {
    return (error as Record<string, unknown>).message as string
  }
  if ((error as Record<string, unknown>).error != null && typeof (error as Record<string, unknown>).error === 'string') {
    return (error as Record<string, unknown>).error as string
  }
  return 'unknown error'
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function isNullOrEmpty (value: string | null | undefined): boolean {
  return value == null || value === ''
}

export function isObject (value: any): boolean {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function')
}

// convert a value to an object which can be safely serialized
export function safeToObject (value: any): object {
  const type = typeof value
  if (value == null || type === 'string' || type === 'number' || type === 'boolean' || type === 'bigint' || value instanceof Date) {
    return value as object
  }
  if (type === 'function') {
    return null as unknown as object
  }
  if (Array.isArray(value)) {
    return value.map((v: any) => safeToObject(v))
  }

  const result: Record<string, unknown> = {}
  for (const key of Object.keys(value)) {
    const innerValue = value[key]
    if (innerValue === null) {
      result[key] = null
      continue
    }
    const innerType = typeof innerValue
    if (innerValue == null || innerType === 'function') {
      continue
    }
    if (innerType === 'string' || innerType === 'number' || innerType === 'boolean' || innerType === 'bigint' || innerValue instanceof Date) {
      result[key] = innerValue
      continue
    }
    if (Array.isArray(innerValue)) {
      result[key] = innerValue.map((v: any) => safeToObject(v))
      continue
    }
    if (innerType === 'object') {
      result[key] = safeToObject(innerValue)
    }
  }
  return result as object
}

/*
const toString = Object.prototype.toString

function getTag (value: unknown): string {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]'
  }
  return toString.call(value)
}
*/

export function isString (value: any): boolean {
  const type = typeof value
  return type === 'string'
}

export function isBoolean (value: any): boolean {
  const type = typeof value
  return type === 'boolean'
}

export function isNumber (value: any): boolean {
  return typeof value === 'number'
}

export function isArray (value: any): boolean {
  return Array.isArray(value)
}

export class SelfRegistrationDisabledError extends Error { }

export function pick<T> (obj: T, ...paths: string[]): Partial<T> {
  if (obj == null) {
    return {} satisfies Partial<T>
  }
  const result: Partial<T> = {}

  for (const path of paths) {
    const value = obj[path as keyof T]
    if (value != null) {
      result[path as keyof T] = value
    }
  }
  return result
}

export function omit<T> (obj: T, ...paths: string[]): Partial<T> {
  if (obj == null) {
    return {} satisfies Partial<T>
  }
  const result: Partial<T> = {}

  for (const key of Object.keys(obj)) {
    if (!paths.includes(key)) {
      const value = obj[key as keyof T]
      if (value != null) {
        result[key as keyof T] = value
      }
    }
  }
  return result
}

export function bind<T extends (...args: any[]) => any> (func: T, thisArg: any, ...boundArgs: any[]): T {
  return function (this: any, ...args: any[]): any {
    return func.apply(thisArg, boundArgs.concat(args))
  } as T
}

// eslint-disable-next-line @typescript-eslint/return-await
export const sleep = async (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

// a function to throttle a function call
// https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
export function throttle<T extends (...args: any[]) => void | Promise<void>> (func: T, limit: number, maxInvocations = 1): (...args: any[]) => void {
  let inThrottle: boolean
  let invocations = 0
  let returnsPromise = false
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return function (...args: any[]): void | Promise<void> {
    if (!inThrottle || invocations < maxInvocations) {
      const result = func.apply(args)
      if (result instanceof Promise) {
        returnsPromise = true
      }
      invocations++
      if (!inThrottle) {
        inThrottle = true
        setTimeout(() => { inThrottle = false; invocations = 0 }, limit)
      }
      return result
    }
    if (returnsPromise) {
      return Promise.resolve()
    }
  } as unknown as T
}

export function debounce<T extends (...args: any[]) => void> (func: T, wait: number, options: { leading?: boolean, trailing?: boolean, maxWait?: number } = {}): T & { cancel: () => void, flush: () => void } {
  const { leading = false, trailing = true, maxWait = 0 } = options
  let boundFunc: T
  let inDebounce: boolean
  let calledSinceLeading: boolean
  let timer: any
  let maxTimer: any
  const result = function (...args: any[]): void {
    if (!inDebounce && leading) {
      func(...args)
      inDebounce = true
    } else {
      calledSinceLeading = true
      boundFunc = bind(func, null, ...args)
    }
    if (timer != null) {
      clearTimeout(timer)
      timer = undefined
    }
    timer = setTimeout(() => {
      inDebounce = false
      timer = undefined
      if (trailing && calledSinceLeading) {
        boundFunc()
      }
    }, wait)

    if (maxWait > 0 && maxTimer == null) {
      maxTimer = setTimeout(() => {
        // basically the same as flush
        if (timer != null) {
          clearTimeout(timer)
          timer = undefined
        }
        if (trailing && calledSinceLeading) {
          boundFunc()
        }
        maxTimer = undefined
      }, maxWait)
    }
  } as unknown as T & { cancel: () => void, flush: () => void }
  result.cancel = function (): void {
    if (timer != null) {
      clearTimeout(timer)
      timer = undefined
    }
    if (maxTimer != null) {
      clearTimeout(maxTimer)
      maxTimer = undefined
    }
  }
  result.flush = function (): void {
    if (timer != null) {
      clearTimeout(timer)
      timer = undefined
    }
    if (maxTimer != null) {
      clearTimeout(maxTimer)
      maxTimer = undefined
    }
    if (trailing && calledSinceLeading) {
      boundFunc()
    }
  }
  return result
}

type BreakerFn = (resetBreakerFn: () => void) => void
export function circuitBreaker<T extends (...args: any[]) => void | Promise<void>> (func: T, limit: number, breaker: number | BreakerFn, maxInvocations = 1): T {
  let inThrottle: boolean
  let inBreaker: boolean
  let returnsPromise = false
  let invocations = 0
  return function (this: any, ...args: any[]): void | Promise<void> {
    if (inBreaker) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    if (!inBreaker && (!inThrottle || invocations < maxInvocations)) {
      const result = func.apply(context, args)
      if (result instanceof Promise) {
        returnsPromise = true
      }
      invocations++
      if (!inThrottle) {
        inThrottle = true
        setTimeout(() => { inThrottle = false; invocations = 0 }, limit)
      }
      return result
    } else if (!inBreaker) {
      inBreaker = true
      if (breaker != null) {
        if (typeof breaker === 'number') {
          setTimeout(() => { inThrottle = false; inBreaker = false; invocations = 0 }, breaker)
        } else {
          breaker(() => { inThrottle = false; inBreaker = false; invocations = 0 })
        }
      }
    }
    if (returnsPromise) {
      return Promise.resolve()
    }
  } as unknown as T
}

export class Duration {
  constructor (private readonly milliseconds: number) { }

  toMilliseconds (): number {
    return this.milliseconds
  }

  toSeconds (): number {
    return this.milliseconds / 1000
  }

  static Milliseconds (seconds: number): Duration {
    return new Duration(seconds * 1000)
  }

  static Seconds (seconds: number): Duration {
    return new Duration(seconds * 1000)
  }

  static Minutes (minutes: number): Duration {
    return new Duration(minutes * 60 * 1000)
  }

  static Hours (hours: number): Duration {
    return new Duration(hours * 60 * 60 * 1000)
  }

  static Days (days: number): Duration {
    return new Duration(days * 24 * 60 * 60 * 1000)
  }

  static Weeks (weeks: number): Duration {
    return new Duration(weeks * 7 * 24 * 60 * 60 * 1000)
  }

  static Months (months: number): Duration {
    return new Duration(months * 30 * 24 * 60 * 60 * 1000)
  }

  static Years (years: number): Duration {
    return new Duration(years * 365 * 24 * 60 * 60 * 1000)
  }

  static Today (hour = 0, minutes = 0, seconds = 0): Date {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minutes, seconds)
  }

  static DaysFrom (date: Date, days: number): Date {
    return new Date(date.getTime() + Duration.Days(days).toMilliseconds())
  }

  static DaysFromToday (days: number, hour = 0, minutes = 0, seconds = 0): Date {
    return Duration.DaysFrom(Duration.Today(hour, minutes, seconds), days)
  }

  static WeeksFrom (date: Date, weeks: number): Date {
    return new Date(date.getTime() + Duration.Weeks(weeks).toMilliseconds())
  }

  static WeeksFromToday (weeks: number, hour = 0, minutes = 0, seconds = 0): Date {
    return Duration.WeeksFrom(Duration.Today(hour, minutes, seconds), weeks)
  }

  static MonthsFrom (date: Date, months: number): Date {
    let year = date.getUTCFullYear()
    let month = date.getUTCMonth() + months
    if (month > 11) {
      month = 0
      year++
    }
    let day = date.getUTCDate()
    const hour = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const seconds = date.getUTCSeconds()
    const newDate = new Date(Date.UTC(year, month, day, hour, minutes, seconds))
    if (newDate.getUTCMonth() !== month) {
      // return last day of month
      day = new Date(Date.UTC(year, month + 1, 0, 0, 0, 0)).getUTCDate()
      return new Date(Date.UTC(year, month, day, hour, minutes, seconds))
    }
    return newDate
  }

  static MonthsFromToday (months: number, hour = 0, minutes = 0, seconds = 0): Date {
    return Duration.MonthsFrom(Duration.Today(hour, minutes, seconds), months)
  }

  static YearsFrom (date: Date, years: number): Date {
    const year = date.getUTCFullYear() + years
    const month = date.getUTCMonth()
    let day = date.getUTCDate()
    const hour = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const seconds = date.getUTCSeconds()

    const newDate = new Date(Date.UTC(year, month, day, hour, minutes, seconds))
    if (newDate.getUTCMonth() !== month) {
      // return last day of month
      day = new Date(Date.UTC(year, month + 1, 0, 0, 0, 0)).getUTCDate()
      return new Date(Date.UTC(year, month, day, hour, minutes, seconds))
    }
    return newDate
  }

  static YearsFromToday (years: number, hour = 0, minutes = 0, seconds = 0): Date {
    return Duration.YearsFrom(Duration.Today(hour, minutes, seconds), years)
  }
}

export function cacheParallel<T extends (...args: any[]) => Promise<unknown>> (func: T): (...args: any[]) => ReturnType<T> {
  let inParallel = false
  let resolvers: Array<(value: unknown) => void> = []
  let rejecters: Array<(reason?: any) => void> = []
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  return function (...args: any[]): Promise<unknown> {
    if (!inParallel) {
      inParallel = true
      resolvers = []
      rejecters = []
      const promise = new Promise<unknown>((resolve, reject) => {
        resolvers.push(resolve)
        rejecters.push(reject)
        const result = func(...args)
        result.then((value: unknown) => {
          inParallel = false
          resolvers.forEach(resolver => { resolver(value) })
        }).catch((reason: any) => {
          inParallel = false
          rejecters.forEach(rejecter => { rejecter(reason) })
        })
      })
      return promise
    } else {
      const promise = new Promise<unknown>((resolve, reject) => {
        resolvers.push(resolve)
        rejecters.push(reject)
      })
      return promise
    }
  } as unknown as (...args: any[]) => ReturnType<T>
}

/**
 * Returns a copy of the input with all nullish properties removed
 * @param input An object with properties
 */
export function strip<T> (input: T): T {
  if (input == null || typeof input !== 'object') {
    return input
  }
  const result: Record<string, unknown> = {}
  for (const key of Object.keys(input)) {
    if ((input as Record<string, unknown>)[key] != null) {
      result[key] = (input as Record<string, unknown>)[key]
    }
  }
  return result as T
}

/**
 * A wrapper that will run a function on an interval, with the option to run the function immediately and returning functions to stop the interval or execute it immediately
 * The action function can be asynchronous, and the interval will not start again until the action function completes.
 * @param action The function to run on an interval
 * @param interval The interval (in milliseconds) to run the function
 * @param runImmediately Whether the function should run immediately (true) or wait for the first interval (false) - immediate is not truly immediate, it will run on the next tick
 * @param args Optional arguments to pass to the action function
 * @returns An object with two functions - immediate and stop. immediate will run the action function immediately, and stop will stop the interval
 * @example
 * const interval = intervalWithImmediate((start: number) => { console.log('interval ', Date.now() - start) }, 100, true, Date.now())
 * await sleep(300)
 * interval.stop()
 * // approximage output (will vary due to clock and other factors):
 * // interval 10
 * // interval 110
 * // interval 210
 */
export function intervalWithImmediate<T extends (...args: any) => Promise<void> | void> (action: (...args: Parameters<T>) => ReturnType<T>, interval: number, runImmediately: boolean, ...args: Parameters<T>): { immediate: () => void, stop: () => void } {
  let running = false
  let shouldStop = false
  let intervalTimer: any | undefined

  const doAction = (): void => {
    if (running) {
      return
    }
    if (shouldStop) {
      return
    }
    if (intervalTimer != null) {
      clearTimeout(intervalTimer)
      intervalTimer = undefined
    }
    running = true
    try {
      const result = action(...args)
      if (result instanceof Promise) {
        result.then(() => {
          running = false
          if (shouldStop) {
            return
          }
          intervalTimer = setTimeout(doAction, interval)
        }).catch((err) => {
          running = false
          console.error(err)
          if (shouldStop) {
            return
          }
          intervalTimer = setTimeout(doAction, interval)
        })
      } else {
        running = false
        if (shouldStop) {
          return
        }
        intervalTimer = setTimeout(doAction, interval)
      }
    } catch (err) {
      running = false
      console.error(err)
      if (shouldStop) {
        return
      }
      intervalTimer = setTimeout(doAction, interval)
    }
  }

  const stop = (): void => {
    shouldStop = true
    if (intervalTimer != null) {
      clearInterval(intervalTimer)
    }
  }

  intervalTimer = setTimeout(doAction, runImmediately ? 0 : interval)

  return {
    immediate: doAction,
    stop
  }
}

export interface LockImplementation {
  get: () => Promise<boolean>
  release: () => Promise<void>
}

export type AsyncFunction = () => Promise<void>

/**
 * Creates a lock function that will run a function only if a lock can be acquired. Locks can be configured to expire
 * @param lock A custom lock implementation, or a number of milliseconds to use as a lock expiration with a simple lock implementation (0 means lock will never expire)
 * @returns Returns true if the lock was acquired and the function was run, false if the lock was not acquired
 */
export function createLock (lock: LockImplementation | number): (fn: AsyncFunction) => Promise<boolean> {
  if (typeof lock === 'number') {
    if (lock <= 0) {
      let locked = false
      lock = {
        get: async (): Promise<boolean> => {
          if (locked) {
            return false
          }
          locked = true
          return true
        },
        release: async (): Promise<void> => {
          locked = false
        }
      }
    } else {
      const lockExpiration = lock
      let locked = false
      let expires = 0
      lock = {
        get: async (): Promise<boolean> => {
          const now = Date.now()
          if (locked) {
            if (expires < now) {
              expires = now + lockExpiration
              return true
            }
            return false
          }
          locked = true
          expires = now + lockExpiration
          return true
        },
        release: async (): Promise<void> => {
          locked = false
        }
      }
    }
  }

  return async (fn: AsyncFunction): Promise<boolean> => {
    // console.log('get', Date.now())
    const hasLock = await (lock as LockImplementation).get()
    if (!hasLock) {
      // console.log('lock unavailable', Date.now())
      return false
    }
    try {
      await fn()
      await (lock as LockImplementation).release()
    } catch (err) {
      // console.log('lock run complete', Date.now())
      await (lock as LockImplementation).release()
      // console.log('lock released', Date.now())
      throw err
    }
    return true
  }
}

export type UnwrappedMaybePromiseArg<T> = T extends PromiseLike<infer U> ? U : T
export interface BackoffOptions<TReturn> {
  /** the maximum number of failures before giving up */
  maxFailures?: number
  /** function that returns the delay between iterations */
  iterationDelay?: (iteration: number) => number
  /** the maximum delay between iterations */
  maxDelay?: number
  /** the maximum time to keep trying */
  maxTime?: number
  /** function to evaluate the result, returns true if the result should be returned or false if the backoff should be retried  */
  evaluateResultFn?: (result: UnwrappedMaybePromiseArg<TReturn>) => boolean
  /** function to evaluate the error, returns true if the exception should be returned or false if the backoff should be retried  */
  evaluateExceptionFn?: (err: unknown) => boolean
}

export class BackoffError extends Error {
  constructor (message: string, public readonly innerException?: unknown) {
    if (innerException instanceof Error) {
      message = `${message} - last exception: ${innerException.message}`
    }
    super(message)
  }
}

export function createBackoff<T extends (...args: any[]) => unknown> (func: T, options: BackoffOptions<ReturnType<T>> = {}): (...args: any[]) => Promise<ReturnType<T>> {
  const {
    maxFailures, // = 3,
    iterationDelay = (iteration: number) => 10 * Math.pow(2, iteration - 1),
    maxDelay, // = 1000 * 60 * 5,
    maxTime, // = 1000 * 60 * 5,
    evaluateResultFn = () => true,
    evaluateExceptionFn = () => false
  } = options
  const backoffFn = async function (...args: any[]): Promise<ReturnType<T>> {
    let iteration = 0
    const expiration = (maxTime == null) ? undefined : (Date.now() + maxTime)
    do {
      try {
        let result = func(...args) as ReturnType<T>
        if (result instanceof Promise) {
          result = await result
        }
        if (evaluateResultFn(result as UnwrappedMaybePromiseArg<ReturnType<T>>)) {
          return result
        }
      } catch (err) {
        if (evaluateExceptionFn(err)) {
          // console.log('backoff error evaluateExceptionFn', err)
          throw err
        }
        iteration++
        if (maxFailures != null && iteration >= maxFailures) {
          // console.log('backoff error maxFailures', err)
          throw new BackoffError('maxFailures exceeded', err)
        }
        const now = Date.now()
        let delay = iterationDelay(iteration)
        if (maxDelay != null && delay > maxDelay) {
          delay = maxDelay
        }
        if (expiration != null && (now + delay) > expiration) {
          // console.log('backoff error expiration', err)
          throw new BackoffError('maxTime exceeded', err)
        }
        await sleep(delay)
        continue
      }
      iteration++
      if (maxFailures != null && iteration >= maxFailures) {
        throw new BackoffError('maxFailures exceeded')
      }
      const now = Date.now()
      let delay = iterationDelay(iteration)
      if (maxDelay != null && delay > maxDelay) {
        delay = maxDelay
      }
      if (expiration != null && (now + delay) > expiration) {
        throw new BackoffError('maxTime exceeded')
      }
      await sleep(delay)
    } while (true)
  }
  return backoffFn as unknown as (...args: any[]) => Promise<ReturnType<T>>
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export async function backoff<T extends (this: void, ...args: any[]) => unknown> (func: T, ...args: any[]): Promise<ReturnType<T>> {
  const backoffFn = createBackoff<T>(func, {
    maxFailures: 3
  })
  return await backoffFn(...args)
}

/*
// Lodash functions below

function hasIn (obj: object, key: string): boolean {
  return obj != null && key in Object(obj)
}

// Used as references for various `Number` constants.
const INFINITY = 1 / 0
function toKey (value: any): string | symbol {
  if (typeof value === 'string' || isSymbol(value)) {
    return value
  }
  const result = `${value as string}`
  return (result === '0' && (1 / value) === -INFINITY) ? '-0' : result
}

function baseGet (obj: object, path: string | string[]): any {
  path = castPath(path, obj)

  let index = 0
  const length = path.length

  while (obj != null && index < length) {
    obj = (obj as any)[toKey(path[index++]) as string] as object
  }
  return (index > 0 && index === length) ? obj : undefined
}

function baseSet (obj: object, path: string | string[], value: any, customizer: (value: any, key: string, obj: object) => any): object {
  if (!isObject(obj)) {
    return obj
  }
  path = castPath(path, obj)

  const length = path.length
  const lastIndex = length - 1

  let index = -1
  let nested = obj

  while (nested != null && ++index < length) {
    const key = toKey(path[index])
    let newValue = value

    if (index !== lastIndex) {
      const objValue = (nested as any)[key]
      newValue = customizer != null ? customizer(objValue, key, nested) : undefined
      if (newValue === undefined) {
        newValue = isObject(objValue)
          ? objValue
          : (isIndex(path[index + 1]) ? [] : {})
      }
    }
    assignValue(nested, key, newValue)
    nested = nested[key]
  }
  return object
}

function castPath (value: unknown, obj: object): string[] {
  if (Array.isArray(value)) {
    return value
  }
  return isKey(value, obj) ? [value] : stringToPath(value)
}

function isSymbol (value: unknown): boolean {
  const type = typeof value
  return (
    type === 'symbol' ||
    (type === 'object' && value != null && getTag(value) === '[object Symbol]')
  )
}

export function isObject (value: any): boolean {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function')
}

export function isString (value: any): boolean {
  const type = typeof value;
  return (
    type === 'string' ||
    (type === 'object' &&
      value != null &&
      !Array.isArray(value) &&
      getTag(value) === '[object String]')
  )
}

function isObjectLike (value: any): boolean {
  return typeof value === 'object' && value !== null;
}

// Used as references for various `Number` constants.
const MAX_SAFE_INTEGER = 9007199254740991

function isLength (value: any): boolean {
  return typeof value === 'number' && value > -1 && value % 1 === 0 && value <= MAX_SAFE_INTEGER
}

// Used to detect unsigned integer values.
const reIsUint = /^(?:0|[1-9]\d*)$/

function isIndex (value: any, length: number = MAX_SAFE_INTEGER): boolean {
  const type = typeof value

  return !(length == null || length === 0) &&
    (type === 'number' ||
      (type !== 'symbol' && reIsUint.test(value))) &&
    (value > -1 && value % 1 === 0 && value < length)
}

function isArrayLike (value: any): boolean {
  return value != null && typeof value !== 'function' && isLength(value.length)
}

const toString = Object.prototype.toString

function getTag (value: unknown): string {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]'
  }
  return toString.call(value)
}

const charCodeOfDot = '.'.charCodeAt(0)
const reEscapeChar = /\\(\\)?/g
const rePropName = RegExp(
  // Match anything that isn't a dot or bracket.
  '[^.[\\]]+' + '|' +
  // Or match property names within brackets.
  '\\[(?:' +
  // Match a non-string expression.
  '([^"\'][^[]*)' + '|' +
  // Or match strings (supports escaping characters).
  '(["\'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2' +
  ')\\]' + '|' +
  // Or match "" as the space between consecutive dots or empty brackets.
  '(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))'
  , 'g')

interface HasCache { cache: Map<any, any> }

function memoize<TResult, TFunc extends (...args: string[]) => TResult>(func: TFunc, resolver?: (...args: string[]) => string): TFunc & HasCache {
  if (typeof func !== 'function' || (resolver != null && typeof resolver !== 'function')) {
    throw new TypeError('Expected a function')
  }
  const memoized = function (this: string, ...args: string[]): TResult {
    const key = resolver != null ? resolver.apply(this, args) : args[0]
    const cache = memoized.cache

    if (cache.has(key)) {
      return cache.get(key)
    }
    const result = func.apply(this, args)
    memoized.cache = cache.set(key, result) ?? cache
    return result
  }
  memoized.cache = new (memoize.Cache ?? Map)()
  return memoized as TFunc & HasCache
}

memoize.Cache = Map

const MAX_MEMOIZE_SIZE = 500

function memoizeCapped<TResult, TFunc extends (...args: string[]) => TResult> (func: TFunc): TFunc {
  const result = memoize(func, (key: string) => {
    const { cache } = result
    if (cache.size === MAX_MEMOIZE_SIZE) {
      cache.clear()
    }
    return key
  })

  return result
}

const stringToPath = memoizeCapped((str: string) => {
  const result = []
  if (str.charCodeAt(0) === charCodeOfDot) {
    result.push('')
  }
  str.replace(rePropName, (match, expression, quote, subString): string => {
    let key = match
    if (quote != null) {
      key = subString.replace(reEscapeChar, '$1')
    } else if (expression != null) {
      key = expression.trim()
    }
    result.push(key)
  })
  return result
})

const reIsDeepProp = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/
const reIsPlainProp = /^\w*$/

function isKey (value: unknown, obj: object): boolean {
  if (Array.isArray(value)) {
    return false
  }
  const type = typeof value
  if (type === 'number' || type === 'boolean' || value == null || isSymbol(value)) {
    return true
  }
  return reIsPlainProp.test(value as string) || !reIsDeepProp.test(value as string) ||
    (obj != null && value as string | number | symbol in Object(obj))
}

function basePickBy (obj: object, paths: string[], predicate: (value: any, path: string) => boolean): any {
  let index = -1
  const length = paths.length
  const result = {}

  while (++index < length) {
    const path = paths[index]
    const value = baseGet(obj, path)
    if (predicate(value, path)) {
      baseSet(result, castPath(path, obj), value)
    }
  }
  return result
}

function basePick (obj: object, ...paths: string[]): object {
  return basePickBy(obj, paths, (value, path) => hasIn(object, path))
}

export function pick (obj: object, ...paths: string[]): object {
  return obj == null ? {} : basePick(object, paths)
}
*/
