import { useState, type ReactElement, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { GlobalDialogContext, Breadcrumbs, Button, Form, FormButtons, LoadingComponent, Mask, TextBox } from '@andyneville/tailwind-react'
import { ArrowUturnDownIcon, TrashIcon } from '@heroicons/react/24/outline'
import { type ITryout, type ISeasonAthleteWithAthlete } from '../../../../api/api'
import TableHeader from '../../components/TableHeader'
import SortableTableHeader from '../../components/SortableTableHeader'
import TableCell from '../../components/TableCell'
import TableActionCell from '../../components/TableActionCell'
import { useGetAdminTenantQuery } from '../../reducers/apiSlice-admin-tenants'
import { rootLog } from '../../logging'
import { useGetAdminSeasonQuery } from '../../reducers/apiSlice-admin-seasons'
import { useEditAdminSeasonAthleteMutation, useGetAdminSeasonAthleteQuery } from '../../reducers/apiSlice-admin-season-athletes'
import { useEditAdminTryoutMutation, useGetAdminTryoutsQuery } from '../../reducers/apiSlice-admin-tryouts'
import VideoPreview from '../../components/VideoPreview'
import PlayVideoDialog from '../../components/PlayVideoDialog'
const log = rootLog.child({ module: 'SeasonDetail' })

export default function AdminSeasonAthleteDetail (): ReactElement {
  const { tenantId, seasonId, athleteId } = useParams()
  const {
    data: sourceAthlete,
    isLoading: loadingAthlete
    // isSuccess,
    // isError,
    // error
  } = useGetAdminSeasonAthleteQuery({ tenantId: tenantId ?? '', seasonId: seasonId ?? '', athleteId: athleteId ?? '' }, { skip: tenantId == null || tenantId.length === 0 || seasonId == null || seasonId.length === 0 || athleteId == null || athleteId.length === 0 })
  const {
    data: season
  } = useGetAdminSeasonQuery({ tenantId: tenantId ?? '', seasonId: seasonId ?? '' }, { skip: tenantId == null || tenantId.length === 0 || seasonId == null || seasonId.length === 0 })
  const {
    data: tryouts = [],
    isLoading: loadingTryouts
    // isSuccess,
    // isError,
    // error
  } = useGetAdminTryoutsQuery({ tenantId: tenantId ?? '', seasonId: seasonId ?? '', athleteId: athleteId ?? '' }, { skip: tenantId == null || tenantId.length === 0 || seasonId == null || seasonId.length === 0 || athleteId == null || athleteId.length === 0 })
  const {
    data: tenant
  } = useGetAdminTenantQuery(tenantId ?? '', { skip: tenantId == null || tenantId.length === 0 })
  const [updateAthlete, { isLoading: updateLoading }] = useEditAdminSeasonAthleteMutation()
  const [athlete, setAthlete] = useState<ISeasonAthleteWithAthlete>({} as unknown as ISeasonAthleteWithAthlete)
  const [isDirty, setIsDirty] = useState(false)
  const globalDialog = useContext(GlobalDialogContext)
  const [editTryout, { isLoading: editLoading }] = useEditAdminTryoutMutation()
  const [showVideoDialog, setShowVideoDialog] = useState(false)
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const [videoTitle, setVideoTitle] = useState('Tryout Video')

  useEffect(() => {
    if (sourceAthlete != null) {
      setAthlete(sourceAthlete)
      setIsDirty(false)
    }
  }, [sourceAthlete])

  const showVideo = (title?: string, videoUrl?: string, thumbnailUrl?: string): void => {
    setVideoTitle(title ?? 'Tryout Video')
    setVideoUrl(videoUrl ?? '')
    setThumbnailUrl(thumbnailUrl ?? '')
    setShowVideoDialog(true)
  }
  const setValue = (value: Partial<ISeasonAthleteWithAthlete>): void => {
    setAthlete(Object.assign({}, athlete, value))
    setIsDirty(true)
  }

  const doSave = async (): Promise<void> => {
    try {
      await updateAthlete({ ...athlete, tenantId: tenantId ?? '', seasonId: seasonId ?? '', id: athleteId ?? '' }).unwrap()
      setIsDirty(false)
    } catch (e) {
      log.error('Error updating tenant', e)
      setIsDirty(true)
    }
  }

  const doDeleteTryout = (tryout: ITryout): void => {
    globalDialog.showWarning('Delete Tryout', `Are you sure you want to delete this ${tryout.type} tryout for ${athlete.firstName} ${athlete.lastName}?`, 'Delete', () => {
      void editTryout({ tenantId: tenantId ?? '', seasonId: seasonId ?? '', athleteId: athlete.id, id: tryout.id, deleted: true })
    })
  }

  const doRestoreTryout = (tryout: ITryout): void => {
    globalDialog.showQuestion('Restore Tryout', `Are you sure you want to restore this ${tryout.type} tryout for ${athlete.firstName} ${athlete.lastName}?`, 'Restore', () => {
      void editTryout({ tenantId: tenantId ?? '', seasonId: seasonId ?? '', athleteId: athlete.id, id: tryout.id, deleted: false })
    })
  }

  return (
    <>
      <PlayVideoDialog autoPlay title={videoTitle} open={showVideoDialog} onClose={() => { setShowVideoDialog(false) }} thumbnailUrl={thumbnailUrl} videoUrl={videoUrl ?? ''} onEnded={() => { setShowVideoDialog(false) }} />
      <Breadcrumbs routes={[{ route: '/admin/organizations', name: 'Orgs' }, { route: `/admin/organizations/${tenantId}`, name: tenant?.name ?? 'Org' }, { route: `/admin/organizations/${tenantId}/seasons/${seasonId}`, name: season?.name ?? 'Season' }, { name: athlete?.firstName != null ? `${athlete.firstName} ${athlete.lastName}` : 'Athlete' }]} />
      <div className='sm:mt-5'></div>
      <LoadingComponent isLoading={loadingAthlete}>
        <Mask show={updateLoading} message='Updating...'>
          <Form>
            <TextBox id='firstName' label='First Name' columns={4} value={athlete.firstName} onChange={(value) => { setValue({ firstName: value }) }} />
            <TextBox id='lastName' label='Last Name' columns={4} value={athlete.lastName} onChange={(value) => { setValue({ lastName: value }) }} />
            <TextBox label='Rating' columns={1} value={athlete.totalRating?.toString() ?? ''} />
            <FormButtons>
              <Button label='Save' primary disabled={!isDirty} onClick={() => { void doSave() }} />
            </FormButtons>
          </Form>
        </Mask>
      </LoadingComponent>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <LoadingComponent isLoading={loadingTryouts} isEmpty={tryouts.length === 0}>
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
                <thead>
                  <tr>
                    <SortableTableHeader name='Type' sorted={1} />
                    <SortableTableHeader name='Level' />
                    <SortableTableHeader name='Skill' />
                    <SortableTableHeader name='Rating' />
                    <SortableTableHeader name='Recordings' />
                    <TableHeader name='Actions' srOnly />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {tryouts.map((tryout) => (
                    <tr key={tryout.id} className={tryout.deleted === true ? 'line-through' : 'no'}>
                      <TableCell emphasize>
                        {tryout.type}
                      </TableCell>
                      <TableCell emphasize>
                        {tryout.level}
                      </TableCell>
                      <TableCell emphasize>
                        <div>{tryout.skill}</div>
                        {tryout.notes != null && tryout.notes.length > 0 &&
                          <div className='text-xs text-gray-400 dark:text-dark-500'>Notes: {tryout.notes}</div>
                        }
                      </TableCell>
                      <TableCell emphasize>
                        {tryout.rating}
                      </TableCell>
                      <TableCell emphasize>
                        {tryout.recordings != null &&
                          <div className='flex items-center'>
                            {tryout.recordings.map((recording) => (
                              <VideoPreview key={recording.id} className='w-8 h-16 mr-4' thumbUrl={recording.thumbnailUrl} videoUrl={recording.videoUrl ?? ''} onClick={() => { showVideo(athlete.firstName + ' ' + athlete.lastName, recording.videoUrl, recording.thumbnailUrl) }} />
                            ))}
                          </div>
                        }
                      </TableCell>
                      <TableActionCell>
                        <div className='flex items-center'>
                          {tryout.deleted === true
                            ? <Button Icon={ArrowUturnDownIcon} waiting={editLoading} transparent onClick={() => { doRestoreTryout(tryout) }} />
                            : <Button Icon={TrashIcon} waiting={editLoading} transparent onClick={() => { doDeleteTryout(tryout) }} />
                          }
                        </div>
                      </TableActionCell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </LoadingComponent>
          </div>
        </div>
      </div>
    </>
  )
}
