import { VideoCameraIcon } from '@heroicons/react/24/outline'
import { type ReactElement } from 'react'

export interface RecordButtonProps {
  onClick?: () => void
}

export default function RecordButton (props: RecordButtonProps): ReactElement {
  const { onClick } = props

  return (
    <div className='flex-none flex flex-row items-center justify-center w-16 h-16 text-center border-2 border-gray-400 rounded-full cursor-pointer' onClick={onClick}>
      <div className='flex flex-col items-center text-white bg-red-600 rounded-full w-14 h-14 dark:bg-red-500 dark:text-white'>
        <VideoCameraIcon className='w-6 h-6 mt-1.5' />
        <div className='-mt-1 '>REC</div>
      </div>
    </div>
  )
}
