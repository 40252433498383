import { type ReactNode, type ReactElement } from 'react'

interface TableCellProps {
  emphasize?: boolean
  children?: ReactNode
  className?: string
  compact?: boolean
}

function classNames (...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

export default function TableCell (props: TableCellProps): ReactElement {
  const { emphasize = false, children, className = '', compact = false } = props
  const classes = classNames(
    'px-2 whitespace-nowrap',
    emphasize ? 'font-medium text-gray-900 dark:text-white' : 'text-gray-500 dark:text-gray-300',
    compact ? 'text-xs py-2' : 'text-sm py-4 pr-3',
    className
  )
  /*
  if (emphasize) {
    classes = 'py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap sm:pl-0 ' + className
  } else {
    classes = 'px-3 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap ' + className
  }
  */
  return (
    <td className={classes}>
      {children}
    </td>
  )
}
