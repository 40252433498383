import type { IContinuationResult, ISeason, ISeasonInput } from '../../../api/api'
import { apiSlice } from './apiSlice'

export const adminSeasonsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAdminSeasons: builder.query<ISeason[], { tenantId: string }>({ // eslint-disable-line @typescript-eslint/no-invalid-void-type
      query: (id: { tenantId: string }) => `/admin/seasons?tenantId=${id.tenantId}`,
      transformResponse: (responseData: IContinuationResult<ISeason>) => {
        return responseData?.items ?? []
      },

      providesTags: (result = [], _error, _arg) =>
        result != null
          ? [...result.map(({ id }) => ({ type: 'AdminSeason' as const, id })), 'AdminSeason']
          : ['AdminSeason']
    }),
    getAdminSeason: builder.query < ISeason | undefined, { tenantId: string, seasonId: string }>({
      query: (id: { tenantId: string, seasonId: string }) => `/seasons/${id.seasonId}?tenantId=${id.tenantId}`,
      providesTags: (result, _error, _arg) => [{ type: 'AdminSeason', id: result?.id }]
    }),
    addAdminSeason: builder.mutation<ISeason, ISeasonInput & { tenantId: string }>({
      query: (newSeason: ISeasonInput) => ({
        url: '/admin/seasons',
        method: 'POST',
        body: newSeason
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'AdminSeason', id: result?.id }]
    }),
    editAdminSeason: builder.mutation({
      query: (season: Partial<ISeasonInput> & { id: string, tenantId: string }) => ({
        url: `/admin/seasons/${season.id}`,
        method: 'PUT',
        body: season
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'AdminSeason', id: result?.id }]
    })
  })
})

export const {
  useGetAdminSeasonsQuery,
  useGetAdminSeasonQuery,
  useAddAdminSeasonMutation,
  useEditAdminSeasonMutation
} = adminSeasonsApiSlice
