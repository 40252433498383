import { type ReactElement } from 'react'
import { classes } from '@andyneville/tailwind-react'
import { PlayCircleIcon } from '@heroicons/react/24/solid'

interface VideoPreviewProps {
  onClick?: () => void
  className?: string
  videoUrl: string
  thumbUrl?: string
}

export default function VideoPreview (props: VideoPreviewProps): ReactElement {
  const { onClick, thumbUrl, className } = props
  if (thumbUrl != null && thumbUrl.length > 0) {
    return (
      <div className={classes('cursor-pointer bg-center bg-cover rounded-lg flex items-center justify-center group', className)} style={{ backgroundImage: `url("${thumbUrl}")` }} onClick={onClick}>
        <PlayCircleIcon className='w-12 h-12 text-gray-400 opacity-80 group-hover:text-brand-600 group-hover:dark:text-brand-500 group-hover:opacity-100' />
      </div>
    )
  } else {
    return (
      <div className={classes('cursor-pointer border border-gray-400 rounded-lg flex items-center justify-center group', className)} onClick={onClick}>
        <PlayCircleIcon className='w-8 h-8 text-gray-400 group-hover:text-brand-400' />
      </div>
    )
  }
}
