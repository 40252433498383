import { type PropsWithChildren, createContext, type ReactElement, useState, useEffect, useRef } from 'react'
import { Sortable } from '@shopify/draggable'
import { ListOperation } from './SortableOperation'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'SortableProvider' }, {
  level: 'warn'
})

export interface SortableContextType {
  sortableClass: string
  draggableClass: string
  setList: (id: string, list: List) => void
  onClick?: (itemId: string) => void
}

export const SortableContext = createContext<SortableContextType | null>(null)

export type OnClickListItemFn = (listId: string, item: Item) => void

export interface SortableProviderProps {
  handleSelector?: string
  addMirrorClass?: string
  addSourceClass?: string
  rootSelector?: string
  onClick?: (listId: string, item: Item) => void
}

export type Item = { id: string } & Record<string, unknown>

export interface ItemInfo {
  list: List
  item: Item
}

export interface List {
  id: string
  items: Item[]
  setItems: (items: Item[], itemId: string, fromListId: string, toListId: string, operation: ListOperation, instance: InstanceInfo) => void
  update?: (instance: InstanceInfo) => void
}

export interface InstanceInfo {
  lists: Record<string, List>
  items: Record<string, ItemInfo>
}

// export type InstanceMap = Record<string, InstanceInfo>

// const _instances: InstanceMap = {}
/*
export interface SortableContext {
  setList: (listType: string, list: List) => void
}
 */
export default function SortableProvider (props: PropsWithChildren<SortableProviderProps>): ReactElement {
  const { children, handleSelector, addMirrorClass, addSourceClass, rootSelector, onClick } = props
  // const [instance] = useState('inst_' + Math.floor(Math.random() * 10000000).toString())
  const instance = useRef<string | null>(null)
  // const instance = useRef('inst_' + Math.floor(Math.random() * 10000000).toString())
  const sortableClass = `sortable-${instance.current}`
  const draggableClass = `draggable-${instance.current}`
  // log.debug('SortableProvider', instance)
  const instanceRef = useRef<InstanceInfo | null>(null)
  const sortableRef = useRef<Sortable | null>(null)
  const firstUpdateRef = useRef(false)

  useEffect(() => {
    // log.debug('SortableProvider useEffect', instance.current)
    if (instance.current == null) {
      instance.current = 'inst_' + Math.floor(Math.random() * 10000000).toString()
      // log.debug('SortableProvider useEffect set to', instance.current)
    }
    return () => {
      // log.debug('SortableProvider useEffect cleanup', instance.current)
      // delete _instances[instance]
    }
    // Empty dependency array ensures this effect runs once on mount and cleanup on unmount
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = (): void => {
    const sortableContainers = document.querySelectorAll(`.${sortableClass}`)
    if (sortableContainers.length === 0) {
      log.error('No sortable containers found')
      return
    } else {
      // log.debug('Found', sortableContainers.length, 'sortable containers')
    }
    const sortable = new Sortable(sortableContainers, {
      // let sortable = new Sortable(ref.current, {
      draggable: `.${draggableClass}`,
      handle: handleSelector,
      mirror: rootSelector != null ? { appendTo: rootSelector } : undefined
    })
    sortable.on('draggable:initialize', (/* evt */) => {
      // log.debug('draggable:initialize', evt)
      sortableRef.current = sortable
    })
    sortable.on('drag:start', (evt) => {
      // log.debug('drag:start', evt)
      if (instanceRef.current == null) throw new Error('instanceRef.current is null')
      const itemInfo = instanceRef.current.items[evt.originalSource.id]
      if (itemInfo == null) {
        log.error('Item not found', evt.originalSource.id, instanceRef.current.items)
        // return
      }
      // evt.source.classList.add('bg-red-200')
      // log.debug('drag:start item', itemInfo.item)
    })
    sortable.on('drag:stop', (evt) => {
      // log.debug('drag:stop', evt)
      if (instanceRef.current == null) throw new Error('instanceRef.current is null')
      const itemInfo = instanceRef.current.items[evt.originalSource.id]
      if (itemInfo == null) {
        log.error('Item not found', evt.originalSource.id, instanceRef.current.items)
        // return
      }
      // log.debug('drag:stop item', itemInfo.item)
    })
    sortable.on('mirror:created', (evt) => {
      if (addMirrorClass != null) addMirrorClass.split(' ').forEach((c) => { evt.mirror.classList.add(c) })
      if (addSourceClass != null) addSourceClass.split(' ').forEach((c) => { evt.source.classList.add(c) })
      // log.debug('mirror:created', evt)
    })
    sortable.on('mirror:move', (_evt) => {
      // log.debug('mirror:move', evt)
      // log.debug('mirror:move transform', evt.mirror.style.transform)
    })
    sortable.on('sortable:stop', (evt): void => {
      // log.debug('sortable:stop', evt)
      if (instanceRef.current == null) throw new Error('instanceRef.current is null')
      const { oldIndex, newIndex, oldContainer, newContainer } = evt
      if (oldContainer === newContainer) {
        // log.debug('sortable:stop same list, move from oldIndex', oldIndex, 'to newIndex', newIndex)
        const oldList = instanceRef.current.lists[oldContainer.id]
        if (oldList == null) {
          log.error('oldList not found', oldContainer.id, instanceRef.current.lists)
          return
        }
        const itemId = oldList.items[oldIndex]?.id
        const oldListCopy = oldList.items.slice()
        // log.debug('sameList before', JSON.stringify(oldListCopy))
        // array_move(oldListCopy, oldIndex, newIndex)
        const items = oldListCopy.splice(oldIndex, 1)
        if (items == null) {
          log.error('ERROR!!! item not found', oldIndex, oldListCopy)
        }
        if (items.length !== 1) {
          log.error('ERROR!!! item entry not found', items)
        }
        if (items[0] == null) {
          log.error('ERROR!!! item entry is null', items)
        }

        if (oldIndex < newIndex) {
          oldListCopy.splice(newIndex, 0, items[0])
        } else {
          oldListCopy.splice(newIndex, 0, items[0])
        }
        // log.debug('sameList after', JSON.stringify(oldListCopy))
        // log.debug('newList', JSON.stringify(oldListCopy))
        oldList.setItems(oldListCopy, itemId, oldContainer.id, oldContainer.id, ListOperation.Reorder, instanceRef.current)
      } else {
        // log.debug('sortable:stop from list', oldContainer?.id, 'oldIndex', oldIndex, 'to list', newContainer?.id, 'newIndex', newIndex)
        const oldList = instanceRef.current.lists[oldContainer.id]
        const newList = instanceRef.current.lists[newContainer.id]
        if (oldList == null) {
          log.error('oldList not found', oldContainer.id, instanceRef.current.lists)
          return
        }
        if (newList == null) {
          log.error('newList not found', newContainer.id, instanceRef.current.lists)
          return
        }
        const oldListCopy = oldList.items.slice()
        const newListCopy = newList.items.slice()
        // log.debug('oldList before', JSON.stringify(oldListCopy))
        // log.debug('newList before', JSON.stringify(newListCopy))
        const itemId = oldListCopy[oldIndex]?.id
        if (itemId == null) {
          log.error('ERROR!!! itemId not found', oldListCopy, oldIndex)
          return
        }
        const itemInfo = instanceRef.current.items[itemId]
        if (itemInfo == null) {
          log.error('ERROR!!! itemInfo not found', itemId, instanceRef.current.items)
          return
        }
        const items = oldListCopy.splice(oldIndex, 1)
        if (items == null) {
          log.error('ERROR!!! item not found', oldIndex, oldListCopy)
        }
        if (items.length !== 1) {
          log.error('ERROR!!! item entry not found', items)
        }
        if (items[0] == null) {
          log.error('ERROR!!! item entry is null', items)
        }
        // log.debug('deleting drag source', evt.dragEvent.source)
        evt.dragEvent.source.parentNode?.removeChild(evt.dragEvent.source)
        evt.dragEvent.cancel()
        // log.debug('oldList after', JSON.stringify(oldListCopy))
        oldList.setItems(oldListCopy, itemId, oldContainer.id, newContainer.id, ListOperation.Remove, instanceRef.current)
        for (const item of oldListCopy) {
          if (item == null) log.warn('ERROR!!! null oldList item', item)
        }
        newListCopy.splice(newIndex, 0, items[0])
        // log.debug('newList after', JSON.stringify(newListCopy))
        newList.setItems(newListCopy, itemId, oldContainer.id, newContainer.id, ListOperation.Add, instanceRef.current)

        for (const item of newListCopy) {
          if (item == null) log.warn('ERROR!!! null newList item', item)
        }
        itemInfo.list = newList
      }
    })
    sortable.on('sortable:sorted', (/* evt */) => {
      // log.debug('sortable:sorted', evt)
    })
    // sortable.on('sortable:stop', (evt) => {
    //   log.debug('sortable:stop', evt)
    // })
    sortableRef.current = sortable
  }

  const [sortableContext] = useState({
    sortableClass: `sortable-${instance.current}`,
    draggableClass: `draggable-${instance.current}`,
    setList: (id: string, list: List): void => {
      if (instance.current == null) {
        instance.current = 'inst_' + Math.floor(Math.random() * 10000000).toString()
        // log.debug('SortableProvider setList set instance to', instance.current)
      }
      if (instanceRef.current == null) {
        // log.debug('Initializing instance', instance)
        instanceRef.current = { lists: {}, items: {} }
        initialize()
      }
      if (instanceRef.current.lists[id] == null) {
        // log.debug('Provider initializing list', id) // , JSON.stringify(list))
        if (firstUpdateRef.current) {
          // log.debug('Sortable has already initialized!, adding container!')
          setTimeout(() => {
            const newListElement = document.getElementById(id)
            if (newListElement == null) {
              log.error('New list element not found', id)
              return
            }
            sortableRef.current?.addContainer(newListElement)
          }, 0)
        }
        instanceRef.current.lists[id] = list
        for (const item of list.items) {
          instanceRef.current.items[item.id] = { list, item }
        }
        // update lists
        for (const list of Object.values(instanceRef.current.lists)) {
          if (list.update != null) list.update(instanceRef.current)
        }
      } else {
        if (!firstUpdateRef.current) {
          firstUpdateRef.current = true
        }
        // log.debug('Provider updating list', id) // , JSON.stringify(list))
        instanceRef.current.lists[id] = list
        for (const item of list.items) {
          instanceRef.current.items[item.id] = { list, item }
        }
        // update lists
        for (const list of Object.values(instanceRef.current.lists)) {
          if (list.update != null) list.update(instanceRef.current)
        }
      }
    },
    onClick: onClick == null
      ? undefined
      : (itemId: string): void => {
          if (instanceRef.current == null) {
            log.warn('Error: onClick() could not find instance')
            return
          }
          const item = instanceRef.current.items[itemId]
          if (item == null) {
            log.warn('Error: onClick() could not find item', itemId)
            return
          }
          if (item.item == null || item.list == null) {
            log.warn('Error: onClick() found invalid item', item)
            return
          }
          onClick(item.list.id, item.item)
        }
  })

  return (
    <SortableContext.Provider value={sortableContext}>
      {children}
    </SortableContext.Provider>
  )
}
