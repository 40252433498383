import { type ReactElement } from 'react'
import { Button, classes } from '@andyneville/tailwind-react'
import Draggable from './Draggable'
import { type ISeasonAthleteWithAthlete } from '../../../api/api'
import unknownUser from '../assets/user.png'
import { Bars2Icon, TrashIcon } from '@heroicons/react/24/outline'
import SwitchIcon from './icons/Switch'
import { ageGroupNamesShort, levelNames } from '../../../api/ageGrids'

export interface AthleteCardProps {
  athlete: ISeasonAthleteWithAthlete
  className?: string
  showAgeGroups?: Record<string, boolean | undefined>
  editable?: boolean
  canCrossover: boolean
  crossover?: boolean
  onAddCrossover?: (athlete: ISeasonAthleteWithAthlete) => void
  onRemoveCrossover?: (athlete: ISeasonAthleteWithAthlete) => void
}

export default function AthleteMiniCard (props: AthleteCardProps): ReactElement {
  const {
    athlete,
    showAgeGroups = {},
    className,
    canCrossover,
    editable = true,
    crossover = false,
    onAddCrossover,
    onRemoveCrossover
  } = props

  return (
    <Draggable
      as='li'
      id={athlete.id}
      className={classes(
        'athlete-mini-card block col-span-1 bg-gray-200 dark:bg-dark-600 divide-y divide-gray-200 rounded-lg shadow',
        crossover ? 'bg-indigo-50 dark:bg-indigo-800/50' : 'bg-green-50 dark:bg-green-800/50',
        className
      )}
    >
      <div className="flex items-center justify-between w-full p-2 space-x-1 md:space-x-2">
        <div className="flex-1 truncate drag-remove">
          <div className="flex items-center space-x-3">
            <h3 className="m-0 p-0 text-sm font-medium text-gray-900 truncate">{athlete.firstName} {athlete.lastName}</h3>
          </div>
          <p className="mt-0 text-xs text-gray-500 truncate">
            <span className='inline-block text-gray-900 w-9 dark:text-white'>{athlete.birthYear}</span>
            {athlete.levels != null && athlete.levels.length > 0 &&
              <span className='inline-block mr-1 text-gray-900 dark:text-white'>
                {athlete.levels != null && athlete.levels.length > 0 ? 'L' : ''}{athlete.levels?.map((sl) => (levelNames[sl] ?? 'L').substring(1)).join('/')}
              </span>
            }
          </p>
          <p className="mt-0 text-xs text-gray-500 truncate">
            {crossover &&
              <span className='inline-block text-white mr-2 px-1 rounded bg-brand-700 dark:text-dark-800 dark:bg-indigo-100'>Crossover</span>
            }
            {/* .filter((id) => showAgeGroups[id] !== false) */}
            { athlete.ageGroups != null && athlete.ageGroups.length > 0 && athlete.ageGroups.map((ageGroup, ageGroupIdx) => (
              <span key={ageGroupIdx} className={classes('inline-block mr-1.5', showAgeGroups[ageGroup] !== false ? 'text-gray-900 dark:text-white' : 'text-gray-300 dark:text-dark-500')} >
                {ageGroupNamesShort[ageGroup] ?? '?'}
              </span>
            )) }
            {/* athlete.position && athlete.position.join(', ') */}
            {/* athlete.ageGroups != null && athlete.ageGroups.length > 0 && athlete.ageGroups.map((ageGroup, ageGroupIdx) => (
              <span key={ageGroupIdx} className={classes('mr-1 inline-flex flex-shrink-0 items-center rounded-full px-1 py-0 text-xs font-medium ring-1 ring-inset dark:bg-dark-600 bg-gray-50 text-gray-700 ring-gray-600/20 dark:ring-dark-500 dark:text-dark-200', showAgeGroups[ageGroup] === false ? 'hidden' : '')} >
                {ageGroupNamesShort[ageGroup] ?? '?'}
              </span>
            )) */}
          </p>
        </div>
        {editable && (
          crossover
            ? <Button Icon={TrashIcon} title='Remove Crossover' className='!px-0.5 !-mr-1 ' transparent onClick={(event) => { event.stopPropagation(); if (onRemoveCrossover != null) { onRemoveCrossover(athlete) } }} />
            : canCrossover
              ? <Button Icon={SwitchIcon} title='Add Crossover' className='!px-0.5 !-mr-1' transparent onClick={(event) => { event.stopPropagation(); if (onAddCrossover != null) { onAddCrossover(athlete) } }} />
              : <></>
        )}
        <div className="flex items-center w-8 h-8 text-white bg-green-600 rounded-full dark:bg-green-600">
          <div className="text-[1.25rem] w-full text-center">{athlete.totalRating ?? '?'}</div>
        </div>

        <img className="flex-shrink-0 object-cover w-12 h-12 bg-gray-300 rounded-full" src={athlete.headshotUrl ?? unknownUser} alt="" />
        {editable &&
          <Bars2Icon className='w-6 h-6 text-gray-400 cursor-pointer drag-target dark:text-gray-500' />
        }
      </div>
    </Draggable>
  )
}
