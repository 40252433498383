// import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks'
import type { ITryout, ITryoutInput } from '../../../api/api'
import { apiSlice } from './apiSlice'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'tryoutsApiSlice' })

export const adminTryoutsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAdminTryouts: builder.query<ITryout[], { seasonId: string, athleteId: string, tenantId: string }>({
      query: (id: { seasonId: string, athleteId: string, tenantId: string }) => `/admin/seasons/${id.seasonId}/athletes/${id.athleteId}/tryouts?tenantId=${id.tenantId}`,

      providesTags: (result = [], _error, _arg) => [
        'AdminTryout',
        ...result.map(({ id }) => { log.debug('getTryouts-providesTags', result, id); return ({ type: 'AdminTryout' as const, id }) })
        // ...result.map(({ id }) => ({ type: 'Tryout' as const, id }))
      ]
    }),
    getAdminTryout: builder.query<ITryout | undefined, { seasonId: string, athleteId: string, tryoutId: string, tenantId: string }>({
      query: (id: { seasonId: string, athleteId: string, tryoutId: string, tenantId: string }) => `/admin/seasons/${id.seasonId}/athletes/${id.athleteId}/tryouts/${id.tryoutId}?tenantId=${id.tenantId}`,
      providesTags: (result, _error, _arg) => {
        log.debug('getTryout-providesTags', result); return [
          { type: 'AdminTryout', id: result?.id }
        // ...(result?.recordings ?? []).map(({ id }) => ({ type: 'Recording' as const, id }))
        ]
      }
    }),
    addAdminTryout: builder.mutation<ITryout, ITryoutInput & { seasonId: string, athleteId: string, tenantId: string }>({
      query: (newTryout: ITryoutInput & { seasonId: string, athleteId: string, tenantId: string }) => ({
        url: `/admin/seasons/${newTryout.seasonId}/athletes/${newTryout.athleteId}/tryouts`,
        method: 'POST',
        body: newTryout
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'AdminTryout', id: result?.id }, 'AdminTryout']
    }),
    editAdminTryout: builder.mutation({
      query: (tryout: Partial<ITryout> & { id: string, seasonId: string, athleteId: string, tenantId: string }) => ({
        url: `/admin/seasons/${tryout.seasonId}/athletes/${tryout.athleteId}/tryouts/${tryout.id}`,
        method: 'PUT',
        body: tryout
      }),
      invalidatesTags: (result, _error, _arg) => [{ type: 'AdminTryout', id: result?.id }, 'AdminTryout']
    })
  })
})

export const {
  useGetAdminTryoutsQuery,
  useGetAdminTryoutQuery,
  useAddAdminTryoutMutation,
  useEditAdminTryoutMutation
} = adminTryoutsApiSlice
