import { type ReactElement } from 'react'
import Rufus404 from '../components/Rufus404'
import { Link } from 'react-router-dom'

export default function Error404 (): ReactElement {
  return (
    <>
      <div className='flex flex-col items-center w-full mt-10'>
        <h2 className='text-2xl font-bold leading-7 text-brand-600 dark:text-brand-400 sm:truncate sm:text-3xl'>
          What was that?
        </h2>
        <p className='max-w-3xl mt-4 text-base text-center text-gray-600 dark:text-gray-300 sm:text-lg sm:max-w-sm'>
          Doggone it, this page doesn't seem to exist. Rufus has logged the error and his team will look into it shortly.
        </p>
        <p className='max-w-3xl mt-4 text-base text-center text-gray-600 dark:text-gray-300 sm:text-lg sm:max-w-sm'>
          <Link className='text-brand-600 hover:text-brand-500 dark:text-brand-600 dark:hover:text-brand-400' to='/'>Click Here</Link> to return to the home page.
        </p>
      </div>
      <div className='flex flex-col items-center w-full mt-10'>
        <Rufus404 />
      </div>
    </>
  )
}
