import { type ReactElement, useContext, useEffect } from 'react'

import { LoadingComponent } from '@andyneville/tailwind-react'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { useCompleteOnboardingMutation } from '../reducers/apiSlice-tenants'
import { useNavigate } from 'react-router'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'PlansSuccess' })

export default function Plans (): ReactElement {
  const { tenant } = useContext(TenantSeasonContext)
  const [completeOnboarding] = useCompleteOnboardingMutation()
  const navigate = useNavigate()

  useEffect(() => {
    if (tenant != null) {
      const update = async (): Promise<void> => {
        log.debug('completing onboarding')
        try {
          await completeOnboarding({ tenantId: tenant.id }).unwrap()
          navigate('/')
        } catch (err) {
          log.error('Failed to complete onboarding', err)
        }
      }
      void update()
    }
  }, [tenant, completeOnboarding, navigate])

  return (
    <>
      <LoadingComponent isLoading={tenant == null}>
        <div className='flex flex-col items-center'>
          <div className='max-w-[600px]'>
            <h1 className='text-3xl text-center md:mt-8'>Finalizing Onboarding...</h1>
          </div>
        </div>
      </LoadingComponent>
    </>
  )
}
