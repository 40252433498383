import { rootLog } from './logging' // keep this first so logging is initialized properly
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
// import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import { createBrowserRouter, RouterProvider, Link } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'
import { initializeAuth } from './reducers/authReducer'
import TenantSeasonProvider from './components/TenantSeasonProvider'
import { GlobalDialogProvider, ThemeProvider, setGlobalLink } from '@andyneville/tailwind-react'
import * as Sentry from '@sentry/react'
import ClientSyncProvider from './components/ClientSyncProvider'

const log = rootLog.child({ module: 'Index' })

Sentry.init({
  dsn: 'https://f1142bb62921dd953b8ac1c0b5cdfa23@o4507224561025024.ingest.us.sentry.io/4507224564826112',
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

log.info('Initializing CheerSync web app...', true, { number: 1, boolean: true, string: 'string', null: null, undef: undefined, date: new Date(), array: [1, 2], obj: { a: 1 } }, 'string', 5, 'null', null, 'undefined', undefined, 'date', new Date(), 'array', [1, 2], 'obj', { a: 1 })
void store.dispatch(initializeAuth())

const root = document.getElementById('root')
if (root === null) {
  throw new Error('root element not found')
}

setGlobalLink(Link)

const router = createBrowserRouter([
  { path: '*', Component: App }
])

createRoot(root).render(
  <React.StrictMode>
    <Provider store={store}>
      <TenantSeasonProvider>
        <ThemeProvider>
          <GlobalDialogProvider>
            <ClientSyncProvider>
              <RouterProvider router={router} />
            </ClientSyncProvider>
          </GlobalDialogProvider>
        </ThemeProvider>
      </TenantSeasonProvider>
    </Provider>
  </React.StrictMode>
)
